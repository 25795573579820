/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 26.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Avatar, Button, Col, Comment, Divider, Drawer, Form, message, Result, Row, Tooltip} from "antd";
import moment from "moment";
import axios from "axios";
import {API} from "../../core/API";
import {Input} from "antd/es";

interface iProps {
    projectID: string;
    versionID: string;
}
interface iStates {
    visible: boolean;
    comments: any[];
}
export class Comments extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);
        this.state = {
            visible: false,
            comments: []
        };
    }

    componentDidMount() {
        this.getComments();
    }

    async getComments() {
        const api = await axios.post(API.URL + '/comment/version', {
            token: localStorage.getItem('token'),
            projectID: this.props.projectID,
            versionID: this.props.versionID
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        this.setState({
            comments: api.data.data
        });
    }

    showDrawer() {
        this.setState({visible: true});
    }

    hideDrawer() {
        this.setState({visible: false});
    }

    async deleteComment(id: string) {
        try {
            const api = await axios.post(API.URL + '/comment/delete', {
                token: localStorage.getItem('token'),
                id: id
            });

            if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannter Fehler');

            message.success('Kommentar gelöscht!');

            this.getComments();
        } catch ( error ) {
            return message.error('Server nicht erreichbar!');
        }
    }

    async onAddComment( values: any ) {
        console.log(values);
        if ( values.text !== undefined && values.text.length !== 0) {
            const api = await axios.post(API.URL + '/comment/create', {
                token: localStorage.getItem('token'),
                projectID: this.props.projectID,
                versionID: this.props.versionID,
                text: values.text
            });

            if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');
            this.getComments();
        }
    }

    render() {
        return <>
            <Button
                type={"link"}
                icon={<i className={"fas fa-comment"}></i>}
                onClick={this.showDrawer.bind(this)}
            />

            <Drawer
                title={"Kommentare"}
                visible={this.state.visible}
                onClose={this.hideDrawer.bind(this)}
                bodyStyle={{paddingTop: 0}}
                footer={
                    <Form
                        className={"comment-form"}
                        layout={"horizontal"}
                        onFinish={this.onAddComment.bind(this)}
                    >
                        <Row
                            justify={"center"}
                            align={"top"}
                        >
                            <Col flex="auto" style={{marginRight: 10}}>
                                <Form.Item name={"text"}>
                                    <Input.TextArea rows={4} showCount />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button
                                    type={"primary"}
                                    shape={"round"}
                                    htmlType={"submit"}
                                    icon={<i className={"fas fa-paper-plane"}></i>}
                                />
                            </Col>
                        </Row>
                    </Form>
                }
            >
                { this.state.comments.length != 0 &&
                    this.state.comments.map( ( comment ) => {
                        if ( comment.user != null ) {
                            return <Comment
                                author={comment.user.firstName + ' ' + comment.user.lastName}
                                avatar={<Avatar shape={"square"}>{comment.user.firstName[0] + comment.user.lastName[0]}</Avatar>}
                                content={
                                    <p>
                                        { comment.text }
                                    </p>
                                }
                                actions={[
                                    <Button size={"small"} type={"link"} icon={<i className={"fas fa-times"}></i>} onClick={() => this.deleteComment(comment.id)}/>
                                ]}
                                datetime={
                                    <Tooltip title={moment.unix(comment.created).format('DD.MM.YYYY HH:mm:ss')}>
                                        <span>{moment.unix(comment.created).fromNow()}</span>
                                    </Tooltip>
                                }
                            />
                        }
                    })
                }
                { this.state.comments.length === 0 &&
                    <Result status={404} title={"Keine Kommentare!"} />
                }
            </Drawer>
        </>;
    }

}