/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Divider, Form, Input, message, Modal, Row, Select, Tooltip} from "antd";
import {Project} from "../../interfaces/project";
import axios from "axios";
import {API} from "../../core/API";
import {Designer} from "../../designer";


interface IProps {
    parent: any;
    designer: Designer;
    project: Project;
    versionID: string | undefined;
    navigation: any;
    form: any;
    copyForm?: any;
    onChange?: any;
    canCreate?: boolean;
}

export function VersionSelect(props: any) {
    const navigate = useNavigate();
    const [versionCreateForm] = Form.useForm();
    const [versionCopyForm] = Form.useForm();

    return <VersionSelectComponent
        parent={props.parent}
        designer={props.designer}
        project={props.project}
        versionID={props.versionID}
        navigation={navigate}
        form={versionCreateForm}
        copyForm={versionCopyForm}
        onChange={props.onChange}
        canCreate={props.canCreate}
    />;
}

interface IStates {
    isLoading: boolean;
    isNewVersion: boolean;
    copyVersionModal: boolean;
    showNewVersionModal: boolean;
    initialVersion: number;
}

class VersionSelectComponent extends React.Component<IProps, IStates> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            isNewVersion: false,
            copyVersionModal: false,
            showNewVersionModal: false,
            initialVersion: 1,
        }
    }

    componentDidMount() {
        if ( this.props.canCreate ) this.getMaxVersionID();
        //console.log(this.props.project.versions);
    }

    async handleChangeVersion( value: any ) {
        //console.log('version Selector changed:', value);

        if ( this.state.isLoading ) return message.error('Bitte warten bis neues Projekt geladen ist');
        if ( this.props.project === undefined ) return message.error('Project nicht richtig geladen');

        this.setState({isLoading: true});

        window.location.href = `/designer/${this.props.project.id}/${value}`;
        this.setState({isLoading: false });
    }

    async versionCreateFormFinish( values: any ) {
        if ( values.name === undefined ) return message.error('Name ist leer');
        if ( values.name.length === 0 ) return message.error('Name ist leer');

        this.setState({isNewVersion: true}, async () => {
            if ( this.props.project === undefined ) return message.error('Projekt nicht mehr im Speicher!');
            //if ( this.designer === undefined ) return message.error('Designer nicht geladen');

            const api = await axios.post(API.URL + '/project/version/create', {
                token: localStorage.getItem("token"),
                projectID: this.props.project.id,
                vorgang: Number(values.id),
                name: values.name,
                data: JSON.stringify(this.props.designer.getProjectData())
            });

            //console.log(api);

            if ( api === undefined ) return message.error('API Fehler');
            if ( api.status !== 200 ) return message.error('Server nicht erreichbar!');
            if ( api.data.errors ) return message.error((api.data.message ? api.data.message : 'Unbekannte API Fehlermeldung!'));

            //this.props.navigation();
            this.props.parent.refreshProjectData(api.data.data);

            this.setState({isNewVersion: false, showNewVersionModal: false});
        });
    }

    openCreateVersionModal() {
        this.setState({showNewVersionModal: true});
    }

    onCreateVersionOk() {
        this.props.form.submit();
    }
    onCreateVersionCancel() {
        this.setState({showNewVersionModal: false});
    }

    getMaxVersionID() {
        const lastVersion = this.props.project.versions[(this.props.project.versions.length - 1)];
        if ( lastVersion === undefined ) return;

        this.setState({
            initialVersion: (lastVersion.version + 1)
        })
    }

    render() {
        return <>
            <Modal
                visible={this.state.showNewVersionModal}
                confirmLoading={this.state.isNewVersion}
                onOk={this.onCreateVersionOk.bind(this)}
                onCancel={this.onCreateVersionCancel.bind(this)}>
                <Form
                    form={this.props.form}
                    layout={"vertical"}
                    onFinish={this.versionCreateFormFinish.bind(this)}
                    initialValues={{
                        id: this.state.initialVersion
                    }}
                >
                    <Form.Item
                        label="ID"
                        name="id"
                        rules={[{ required: true, message: 'Bitte ID eintragen' }]}
                    >
                        <Input name="id" placeholder="ID" type={"number"} />
                    </Form.Item>
                    <Form.Item
                        label="Bezeichnung"
                        name="name"
                        rules={[{ required: true, message: 'Bitte Bezeichnung angeben' }]}
                    >
                        <Input name="name" placeholder="Bezeichnung" />
                    </Form.Item>
                </Form>
            </Modal>

            <Select
                style={{width: 150}}
                defaultValue={this.props.project.defaultVersion}
                onChange={(this.props.onChange !== undefined ? this.props.onChange.bind(this) : this.handleChangeVersion.bind(this))}
                loading={this.state.isLoading}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Button
                            type="link"
                            style={{ whiteSpace: 'nowrap' }}
                            loading={this.state.isNewVersion}
                            htmlType={"submit"}
                            icon={<i className="fa-solid fa-add"></i>}
                            onClick={this.openCreateVersionModal.bind(this)}
                        >
                            Erstellen
                        </Button>
                    </>
                )}
            >
                { this.props.project.versions && this.props.project.versions.map( ( version ) =>
                    <Select.Option key={version.id} value={version.id}>
                        {version.name}
                    </Select.Option>
                )
                }
            </Select>
        </>;
    }
}