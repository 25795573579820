/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 28.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {Result} from "antd";

export function PageNotFound() {
    return <>
        <Result
            status="404"
            title="404"
            subTitle="Diese Seite scheint nicht zu existieren!"
        />
    </>;
}