/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 7.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React, {useEffect} from "react";
import {Breadcrumb, Col, Layout, Menu, MenuProps, message, Row} from 'antd';
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Location} from "history";
import {API} from "../core/API";
import {UserModule} from "./component/user";
import {items} from "./configs/menu";
import {ItemType} from "antd/lib/menu/hooks/useItems";
const { Content, Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export function LayoutSidebar() {
    const location = useLocation();
    const nav = useNavigate();
    const effect = useEffect;


    return <LayoutController location={location} navigation={nav} effect={effect} />
}

interface IProps {
    location: Location;
    navigation: any;
    effect: any;
    projectID?: string;
    versionID?: string;
}

interface IStates {
    collapsed: boolean;
    currentPath: string;
    logout: boolean;
}

class LayoutController extends React.Component<IProps, IStates> {
    peakTimer: any = undefined;


    constructor(props: IProps) {
        super(props);

        this.state = {
            collapsed: false,
            currentPath: '',
            logout: false
        }
    }

    async peakSessionChecker() {
        const state = await API.checkLoginState();
        if ( state === undefined ) {
            localStorage.clear();
            this.setState({
                logout: true
            });
        }

        this.peakTimer = setTimeout(this.peakSessionChecker.bind(this), 20000);
    }

    async componentDidMount() {
        await this.peakSessionChecker();
        if ( this.peakTimer === undefined ) this.peakTimer = setTimeout(this.peakSessionChecker.bind(this), 20000);
    }

    componentWillUnmount() {
        if ( this.peakTimer !== undefined ) clearTimeout(this.peakTimer);
    }

    onMenuClicked( e: any ) {
        if ( !e.key.includes("/user/") ) return this.props.navigation(e.key);
    }

    getMenu(path: string, menu: MenuItem[] = items, parent: MenuItem = null): MenuItem | undefined {
        for ( const item of menu ) {
            if ( !item ) continue;
            if ( item.key === path) return Object.assign(item, {parent: parent});

            // @ts-ignore
            if ( item.children ) {
                // @ts-ignore
                const menu = this.getMenu(path, item.children, item);
                if ( !menu ) continue;
                return menu;
            }
        }
        return undefined;
    }

    getBreadcrumb() {
        const cPath = this.props.location.pathname;
        let paths = cPath.split("/");

        return paths.map( ( path ) => {
            if ( path.length === 0 || path === "" ) return;
            const menu = this.getMenu(path);
            if ( menu ) {
                // @ts-ignore
                return <Breadcrumb.Item key={"bread_" + path}>{menu.label}</Breadcrumb.Item>
            }
        });
    }

    getSidebarItems(): ItemType[] {
        const items: ItemType[] = [];

        const currentPath = this.props.location.pathname;
        const currentMenu = this.getMenu(currentPath);


        console.log('currentPath: ', currentPath);
        console.log('currentMenu: ', currentMenu);

        // @ts-ignore
        if ( currentMenu && currentMenu.parent ) {
            // @ts-ignore
            for ( const item of currentMenu.parent.children ) {
                items.push({
                    key: item.key,
                    icon: item.icon,
                    label: item.label
                });
            }
        }

        return items;
    }

    render() {
        return <>
            { localStorage.getItem('token') === undefined || this.state.logout &&
                <Navigate to={"/login"} />
            }
            <Layout style={{ minHeight: '100vh'}}>
                <Layout.Header className={"site-page-header"} style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div className="logo" />
                    <Row justify={"space-between"} align={"middle"}>
                        <Col flex={"auto"}>
                            <Menu
                                onClick={this.onMenuClicked.bind(this)} selectedKeys={[this.props.location.pathname]}
                                mode="horizontal"
                                items={items}
                            />
                        </Col>
                        <Col><UserModule /></Col>
                    </Row>
                </Layout.Header>
                <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item key={"bread_start"}>
                            <a>Startseite</a>
                        </Breadcrumb.Item>
                        {this.getBreadcrumb()}
                    </Breadcrumb>
                    <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
                        <Layout.Sider className="site-layout-background" width={200}>
                            <Menu
                                mode="inline"
                                selectedKeys={[this.props.location.pathname]}
                                style={{ height: '100%' }}
                                onClick={(item) => this.props.navigation(item.key)}
                                items={this.getSidebarItems()}
                            />
                        </Layout.Sider>
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>

                            <Outlet />
                        </Content>
                    </Layout>
                </Content>
                <Footer style={{ textAlign: 'center' }}>MiMann.net ©2022</Footer>
            </Layout>
        </>
    }
}