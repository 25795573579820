/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 6.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import axios from "axios";
import {Button, Col, Form, FormInstance, Input, message, Modal, Row, Space, Table, TableColumnsType} from "antd";
import {useForm} from "antd/es/form/Form";
import {ArticleCategory} from "../../../interfaces/articles";
import {API} from "../../../core/API";


export function ArticleCategories() {

    const [ category ] = useForm();
    const [ article ] = useForm();
    return <Component categoryForm={category} articleForm={article} />;
}

interface iProps {
    categoryForm: FormInstance;
    articleForm: FormInstance;
}

interface iStates {
    categories: ArticleCategory[];

    isCategoryModalOpen: boolean;
    isCategoryModalCreating: boolean;
}

class Component extends React.Component<iProps, iStates> {
    constructor(props: iProps) {
        super(props);

        this.state = {
            categories: [],
            isCategoryModalOpen: false,
            isCategoryModalCreating: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const categoriesAPI = await axios.post(API.URL + '/category', {
            token: localStorage.getItem('token')
        });

        if ( categoriesAPI.data.errors ) return message.error(categoriesAPI.data.message ? categoriesAPI.data.message : 'Unbekannte Fehlermeldung');

        this.setState({
            categories: categoriesAPI.data.data,
        });
    }

    categoryColumn: TableColumnsType<ArticleCategory> = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Aktion',
            dataIndex: 'operation',
            key: 'operation',
            render: (value, record, index) => (
                <Space size="middle">
                    <Button type={"link"} onClick={() => this.onDeleteCategory(record.id)}>Löschen</Button>
                </Space>
            )
        },
    ];


    async onCreateCategory(values: any) {
        console.log(values);

        const api = await axios.post(API.URL + '/category/add', {
            token: localStorage.getItem("token"),
            ...values
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        this.setState({isCategoryModalOpen: false});
        this.getData();
    }

    async onDeleteCategory(id: string) {
        const api = await axios.post(API.URL + '/category/delete', {
            token: localStorage.getItem("token"),
            id: id
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        this.getData();
    }

    render() {
        return <>
            <div className="site-layout-background" style={{marginTop: 20}}>
                <Row justify={"space-between"} align={"middle"} style={{padding: "20px 20px"}}>
                    <Col>
                        <strong>Kategorien</strong>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            shape={"round"}
                            onClick={() => this.setState({isCategoryModalOpen: true})}
                        >
                            Hinzufügen
                        </Button>
                    </Col>
                </Row>
                <Table
                    columns={this.categoryColumn}
                    dataSource={this.state.categories}
                    size="small"
                />
            </div>
            <Modal
                title="Erstelle Kategorie"
                visible={this.state.isCategoryModalOpen}
                onOk={() => this.props.categoryForm.submit()}
                onCancel={() => this.setState({isCategoryModalOpen: false})}
            >
                <Form
                    layout={"vertical"}
                    form={this.props.categoryForm}
                    onFinish={this.onCreateCategory.bind(this)}
                >
                    <Form.Item label="Bezeichnung / Name" name={"name"} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}