/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 18.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import $ from "jquery";
import {Designer} from "../designer";
import {ConfigController} from "../core/configController";
import {Rect, WindowMain} from "../window/main";
import {Utils} from "../utils/utils";
import {Glass} from "../glass/main";
import {ContainerController} from "./containerController";
import {PresetController} from "./presetController";
import {PlaceholderRect} from "../window/placeholder";
import {AddController} from "../window/addController";
import {WindowOptions} from "../window/windowOptions";
import {Reihe, Spalte} from "../models/splitTypes";
import {FrameOuterOffset} from "../models/frameOuterOffset";


interface Position {
    from: number;
    until: number;
}

export class DragDrop {
    containerController: ContainerController;

    dragElement: any = undefined;

    dragType: number = 0;
    dragID: number = 0;
    dragX: number = 0;
    dragY: number = 0;

    lastHovered: any = undefined;
    lastHoveredGlass: any = undefined;

    _hError: boolean = false;
    _hErrorMSG: string = "";
    _hErrorObject: Glass | Rect | undefined = undefined;

    replace: boolean = false;

    constructor(
        public designer: Designer
    ) {
        this.containerController = new ContainerController(this);
        if ( this.designer.stage === undefined ) return;

        $(document).on('mouseup touchend', 'canvas', this.onPointerUp.bind(this));
        $(document).on('mousemove touchmove', this.onPointerMove.bind(this));
        $(document).on('mousedown touchstart', '.draggable-item', this.onDragElementClicked.bind(this));

        this.designer.onReact('onDragReplacementChanged', (state: boolean) => {
            this.replace = state;
        });
    }

    onDragElementClicked(element: any) {
        if (this.dragElement !== undefined) return;

        const target = $(element.currentTarget);
        if (target.hasClass('drag')) return;


        this.dragElement = target.clone().prependTo('body')[0];
        $(this.dragElement).addClass('drag');

        this.dragType = Number($(this.dragElement).attr("data-type"));
        this.dragID = Number($(this.dragElement).attr("data-id"));

        if ( this.dragType === 0 ) {
            const glassSplit = ConfigController.splitTypes[this.dragID];
            new PresetController(glassSplit, `preview_${this.dragID}`, Number(this.dragID));
        }

        this.dragX = element.clientX;
        this.dragY = element.clientY;

        this.designer.emit('topbarClose');

        for ( const window of this.designer.windows ) {
            if ( this.dragType === 0 || this.dragType === 2 ) {
                if ( window.placeholderController !== undefined )
                    window.placeholderController.show();
            }

            for ( const glass of window.glass ) glass.highlight();
        }

        this.dragElement.style.left = `${this.dragX}px`;
        this.dragElement.style.top = `${this.dragY}px`;

        console.log('Start Dragging Element:', this.dragID, this.dragType);
    }

    onDrag(event: any) {
        this.highlightDragElement(false);
        if ( this.dragElement === undefined ) return;
        if ( this.designer.stage === undefined ) return;

        if ( this.lastHovered ) {
            this.lastHovered = undefined;
        }

        if ( this.lastHoveredGlass ) {
            this.lastHoveredGlass.highlight();
            this.lastHoveredGlass = undefined;
        }
        this.unHighlightAll();

        this.designer.stage.setPointersPositions(event);
        const object = this.getPointerObject();
        if ( object === undefined ) {
            //console.log('cant find object!');
            return this.highlightDragElement(false);
        }

        //Fixme: make it beautiful!
        if ( object instanceof Rect ) {
            if ( object.placeholderController === undefined ) return;
            if ( !object.id.includes('placeholder') ) return;
            this.highlightDragElement();
        } else if ( object instanceof Glass ) {
            if ( object.options.empty ) return this.highlightError("object is empty", object);
            if ( object.options.isTrenner ) return this.highlightError("object is trenner", object);
            if ( !this.highlightGlassAllowed() ) return this.highlightError("object cant be highlighted!", object);

            if ( this.dragType === 0 ) {
                const config = ConfigController.splitTypes[this.dragID];
                if ( !config ) return this.highlightError("config not found for dragged type!", object);

                if ( this.replace ) {
                    this.highlightDragElement();
                    this.lastHoveredGlass = object;
                    return object.hover();
                }

                const rowPosition: Position = {from: this.getRowIndexFromGlass(object, object.window), until: config.reihen.length};
                const colPosition: Position = {from: this.getColIndexFromGlass(object, object.window), until: 0};
                //const rowPosition: Position = {from: 1, until: 2};
                //const colPosition: Position = {from: 1, until: 1};

                for (const row of config.reihen ) {
                    colPosition.until = Math.max(colPosition.until, row.spalten.length);
                }


                if ( !this.squareExists(object.window, rowPosition, colPosition))
                    return this.highlightError("Passt hier nicht rein!", object);

                this.squareHighlight(object.window, rowPosition, colPosition);
            }

            //object.hover();

            this.highlightDragElement();
            this.lastHoveredGlass = object;
        } else if ( object instanceof PlaceholderRect ) {
            //Todo: highlighting
            this.highlightDragElement();
        }
    }

    onPointerUp(element: any) {
        console.log('onPointerUp');

        this.unHighlightAll();

        let wasDragged: boolean = false;
        for ( const window of this.designer.windows ) {
            window.refreshColors();
            if ( window.placeholderController !== undefined ) window.placeholderController.hide();
            for ( const glass of window.glass ) glass.refreshColors();
        }

        if ( this.designer.stage === undefined ) return console.log('stage not found!');
        if ( this.dragElement ) {
            wasDragged = true;
            this.dragElement.remove();
            this.dragElement = undefined;
        }

        const target = this.getPointerObject();
        if ( target === undefined ) {
            console.log('cant find pointer object!');
            return this.designer.emit('onSelected', undefined);
        }

        if ( wasDragged ) {
            if ( target instanceof Glass ) {
                if ( this.dragType === 0 ) {
                    const window = target.window;

                    const config = ConfigController.splitTypes[this.dragID];
                    if ( !config ) return this.highlightError("config not found for dragged type!", window);

                    console.log('=== pointerUp windowOptions:', window.options.splitCustomConfig);


                    if ( this.replace ) {
                        window.options.splitCustomConfig = config;
                        this.fastFixFrames(window.options.splitCustomConfig.reihen);
                        window.update();
                        this.designer.updateZoom();
                        return;
                    }


                    const rowPosition: Position = {from: this.getRowIndexFromGlass(target, window), until: config.reihen.length};
                    const colPosition: Position = {from: this.getColIndexFromGlass(target, window), until: 0};
                    for (const row of config.reihen ) {
                        colPosition.until = Math.max(colPosition.until, row.spalten.length);
                    }


                    if ( !this.squareExists(target.window, rowPosition, colPosition))
                        return console.log("doesnt fit in here!", target);

                    if ( !window.options.splitCustomConfig ) return;

                    const rowLength = rowPosition.from + rowPosition.until;
                    const colLength = colPosition.from + colPosition.until;


                    let configRow = 0;
                    for ( let rr = rowPosition.from; rr <= (rowLength - 1); rr ++ ) {
                        const row = window.options.splitCustomConfig.reihen[rr];
                        if ( !row ) continue;

                        let configCol = 0;
                        for ( let cc = colPosition.from; cc <= (colLength - 1); cc++ ) {
                            if ( !row.spalten[cc] ) continue;

                            if ( config.reihen[configRow].spalten.length !== 0 ) {
                                window.options.splitCustomConfig.reihen[rr].spalten[cc] = config.reihen[configRow].spalten[configCol];
                            } else {
                                window.options.splitCustomConfig.reihen[rr] = config.reihen[configRow];
                            }

                            console.log('rr:', rr, 'cc', cc, 'configRow:', configRow, 'configCol:', configCol);

                            configCol++;

                        }
                        configRow++;
                    }

                    /*for ( let rowIndex = from.x; rowIndex <= slots.x; rowIndex++) {
                        for ( let colIndex = from.y; colIndex <= slots.y; colIndex++) {
                            console.log('row', rowIndex, 'col', colIndex, 'configRow', configRow, 'configCol', configCol);

                            if ( config.reihen[configRow].spalten.length !== 0 ) {
                                const cfg = config.reihen[configRow].spalten[configCol];
                                window.options.splitCustomConfig.reihen[rowIndex].spalten[colIndex] = cfg;
                            } else {
                                const rowCfg = config.reihen[configRow];
                                window.options.splitCustomConfig.reihen[rowIndex] = rowCfg;
                            }
                            configCol++;
                        }
                        configRow++;
                    }*/

                    /*
                    for ( const rIndex in rows ) {
                        const row = rows[rIndex];
                        if (!row ) continue;

                        for ( const cIndex in row.spalten ) {
                            let col = row.spalten[cIndex];
                            if (!col ) continue;

                            console.log('r:', rIndex, 'c:', cIndex, 'config:', config.reihen[rIndex].spalten[cIndex]);
                            const configCol = config.reihen[rIndex].spalten[cIndex];
                            if ( !configCol ) {
                                console.log('config dont have any cols in row', rIndex);
                                continue;
                            }

                            col = {...config.reihen[rIndex].spalten[cIndex]}

                            /*
                            col.frameOuterOffset = new FrameOuterOffset(74);
                            col.frameInnerOffset = new FrameOuterOffset(21);
                            col.frameType = config.reihen[rIndex].spalten[cIndex].frameType;
                            col.glassType = config.reihen[rIndex].spalten[cIndex].glassType;
                            col.isTrenner = config.reihen[rIndex].spalten[cIndex].isTrenner;
                            col.width = config.reihen[rIndex].spalten[cIndex].width * 3;
                            col.empty = config.reihen[rIndex].spalten[cIndex].empty;
                        }
                    }

                    */

                    console.log('======= pointerUp Glass ending', window.options.splitCustomConfig);
                    window.options.splitCustomConfig.label = "CUSTOM";
                    //window.options.splitCustomConfig = config;
                    this.fastFixFrames(window.options.splitCustomConfig.reihen);
                    window.update();
                    this.designer.updateZoom();


                } else if (this.dragType === 1) {
                    if ( target.window.options.splitCustomConfig ) {
                        this.fastFixFrames(target.window.options.splitCustomConfig.reihen);
                    }

                    target.setGlassType(this.dragID);
                } else if ( this.dragType === 99 ) {
                    this.deleteWindow(target.window.id);
                }
            } else if ( target instanceof WindowMain ) {
                if (this.dragType === 99) {
                    return this.deleteWindow(target.id);
                }
            } else if ( target instanceof PlaceholderRect ) {
                const direction = target.direction;
                const anchor = target.anchor;

                const config = ConfigController.splitTypes[this.dragID];
                if ( !config ) return console.groupCollapsed('bla', 'bla');

                const nWindow = new AddController(this.designer);
                nWindow.parent = target.window;
                nWindow.options = new WindowOptions();
                nWindow.options.splitCustomConfig = config;
                this.fastFixFrames(nWindow.options.splitCustomConfig.reihen);
                nWindow.options.direction = direction;
                nWindow.options.anchor = anchor;
                nWindow.options.width = config.windowWidth;
                nWindow.options.height = config.windowHeight;
                nWindow.apply();

                this.designer.updateZoom();
            }
        } else {
            this.designer.emit('onSelected', target);
        }
    }

    onPointerMove(event: any) {
        if ( event === null ) return;
        if ( this.dragElement === undefined ) return;


        if ( event.touches !== undefined ) {
            console.log(event.touches);
            this.dragX = event.touches[0].pageX;
            this.dragY = event.touches[0].pageY;
        } else {
            this.dragX = event.clientX;
            this.dragY = event.clientY;
        }

        this.dragElement.style.left = `${this.dragX}px`;
        this.dragElement.style.top = `${this.dragY}px`;

        this.onDrag(event);
    }

    getPointerObject(): Rect | Glass | PlaceholderRect | undefined {
        if ( this.designer.stage === undefined ) return undefined;

        const pos = this.designer.stage.getRelativePointerPosition();
        if ( pos == null ) return undefined;

        const sPos = this.designer.stage.absolutePosition();

        const object = this.designer.stage.getIntersection({
            x: pos.x * this.designer.currentScale + sPos.x,
            y: pos.y * this.designer.currentScale + sPos.y
        });
        //Todo: debug current position!
        if (object === null ) return undefined;

        // @ts-ignore weil getId einfach nicht in den Types drin ist...
        const objectID = object.getId();
        //console.log('getPointerObject:', objectID);

        if ( objectID.includes("window-")) {
            const window = this.designer.getWindow(objectID);
            if ( window === undefined ) return undefined;

            if ( objectID.includes("_placeholder_")) {
                console.log('pointerObject is placeholder!');
                if ( window.placeholderController === undefined ) return undefined;

                const directionAnchor = objectID.replace(window.id, '').replace('_placeholder_', '').split("_");
                const direction = directionAnchor[0];
                const anchor = directionAnchor[1];
                console.log('DIRECTION:', direction, 'ANCHOR:', anchor);

                /*const placeholder = window.placeholderController as any;
                if ( !placeholder ) return undefined;
                if ( !placeholder[direction] ) return undefined;
                if ( !placeholder[direction][anchor] ) return undefined;*/
                // @ts-ignore
                if ( !window.placeholderController[direction] ) return undefined;
                // @ts-ignore
                if ( !window.placeholderController[direction][anchor] ) return undefined;

                console.log('detected from id placeholder:', direction, anchor);
                // @ts-ignore
                return window.placeholderController[direction][anchor];
                /*
                if ( objectID.includes("_placeholder_top")) {
                    if ( window.placeholderController.top === undefined ) return undefined;

                    if ( objectID.includes("placeholder_top_top")) {
                        return window.placeholderController.top.top;
                    } else if ( objectID.includes("placeholder_top_middle")) {
                        return window.placeholderController.top.middle;
                    } else if ( objectID.includes("placeholder_top_bottom")) {
                        return window.placeholderController.top.bottom;
                    }
                }
                return undefined;*/
            }
            return window;
        } else if ( objectID.includes('glass-')) {
            const glass = this.designer.getGlass(objectID);
            if ( glass === undefined ) {
                return undefined;
            }
            return glass;
        }

        return undefined;
    }

    highlightDragElement(active: boolean = true) {
        if ( this.dragElement === undefined ) return;

        if ( active ) {
            if ( !$(this.dragElement).hasClass("ok") ) {
                $(this.dragElement).addClass('ok');
            }
        } else {
            if ( $(this.dragElement).hasClass("ok") ) {
                $(this.dragElement).removeClass('ok');
            }
        }
    }

    highlightGlassAllowed(): boolean {
        return this.dragType === 0 ||
            this.dragType === 1 ||
            this.dragType === 3 ||
            this.dragType === 99
        ;
    }

    deleteWindow(id: string) {
        console.log('delete window');

        let targetWindow: Rect | undefined = undefined;
        if ( id.includes('glass-')) {
            const glass = this.designer.getGlass(id);
            if ( glass === undefined ) return Utils.toast('Fenster nicht gefunden!');
            targetWindow = glass.window;
        } else if ( id.includes('window-')) {
            targetWindow = this.designer.getWindow(id);
        }

        if ( targetWindow !== undefined ) {

            //Todo: if index 9 then check the childs from index 9 and make the parent to index 8
            // if no other index exists, is this now the master parent

            const wIndex = this.designer.windows.findIndex( ( window ) => targetWindow !== undefined && window.id === targetWindow.id);

            const childs = this.designer.getWindowChilds(targetWindow);
            if ( childs[0] !== undefined ) {
                console.log('UPDATE CHILD WINDOW');
                console.log(childs[0].x, targetWindow.x);
                childs[0].x = targetWindow.x;
                childs[0].y = targetWindow.y;

                if ( targetWindow.parent ) {
                    childs[0].parent = targetWindow.parent
                }
            }

            targetWindow.destroy();
            this.designer.windows.splice(wIndex, 1);

            for ( const window of this.designer.windows ) {
                window.update();
            }
            if ( this.designer.sumMetric ) this.designer.sumMetric.update();

            return this.designer.updateZoom();
        }

        Utils.toast("Fenster nicht gefunden!");
    }

    getColIndexFromGlass(glass: Glass, window: WindowMain): number {
        if ( !window.options.splitCustomConfig ) return -1;

        for ( const row of window.options.splitCustomConfig.reihen ) {
            const find = row.spalten.findIndex((col) => `glass-${col.id}` === glass.id);
            if ( find != -1 ) return find;
        }
        return -1;
    }

    getRowIndexFromGlass(glass: Glass, window: WindowMain): number {
        if ( !window.options.splitCustomConfig ) return -1;
        const rows = window.options.splitCustomConfig.reihen;

        for ( let rI = 0; rI < rows.length; rI++) {
            const row = rows[rI];
            if ( !row ) continue;

            const find = row.spalten.findIndex((col) => `glass-${col.id}` === glass.id);
            if ( find != -1 ) return rI;
        }
        return -1;
    }

    highlightError(message: string, object: Rect | Glass) {
        this._hError = true;
        this._hErrorMSG = message;
        this._hErrorObject = object;

        const elBefore = document.getElementById("hErrorMessage");
        if ( elBefore ) {
            elBefore.remove();
        }

        const element = document.createElement("div");
        element.id = `hErrorMessage`;
        element.className = "hErrorMessage";
        element.style.top = this.dragY + 'px';
        element.style.left = this.dragX + 'px';
        element.innerHTML = message;
        document.body.appendChild(element);

        if ( object instanceof Glass ) {
            object.hover("red");
        } else {
            object.highlightFrame("red");
        }
    }

    unHighlightAll() {
        this._hError = false;
        this._hErrorMSG = "";

        const elBefore = document.getElementById("hErrorMessage");
        if ( elBefore ) {
            elBefore.remove();
        }

        if ( this._hErrorObject !== undefined ) {
            if ( this._hErrorObject instanceof Glass ) {
                this._hErrorObject.refreshColors()
            } else {
                this._hErrorObject.refreshColors()
            }
        }

        for ( const window of this.designer.windows) {
            for ( const glass of window.glass ) {
                glass.refreshColors();
            }
        }
    }

    highlightGlass(id: string) {
        for ( const window of this.designer.windows) {
            for ( const glass of window.glass ) {
                if ( glass.id === `glass-${id}`) {
                    glass.hover();
                }
            }
        }
    }

    squareExists(window: WindowMain, rows: Position, cols: Position): boolean {
        if ( !window.options.splitCustomConfig ) return false;


        const rowLength = rows.from + rows.until;
        const colLength = cols.from + cols.until;


        for ( let rr = rows.from; rr <= (rowLength - 1); rr ++ ) {
            const row = window.options.splitCustomConfig.reihen[rr];
            if ( !row ) return false;

            for ( let cc = cols.from; cc <= (colLength - 1); cc++ ) {
                const col = row.spalten[cc];
                if ( !col ) return false;
                //if (col.empty ) return false;
            }
        }

        return true;
    }

    squareHighlight(window: WindowMain, rows: Position, cols: Position) {
        if ( !window.options.splitCustomConfig ) return;
        const glassIds: string[] = [];


        const rowLength = rows.from + rows.until;
        const colLength = cols.from + cols.until;


        for ( let rr = rows.from; rr <= (rowLength - 1); rr ++ ) {
            const row = window.options.splitCustomConfig.reihen[rr];
            if ( !row ) continue;
            if ( row.isTrenner ) continue;

            for ( let cc = cols.from; cc <= (colLength - 1); cc++ ) {
                const col = row.spalten[cc];
                if ( !col ) continue;
                if (col.empty ) continue;

                //console.log(rr, cc);

                glassIds.push(col.id);
            }
        }

        for ( const glassId of glassIds ) {
            this.highlightGlass(glassId);
        }
    }

    //Todo: maybe dont need anymore!
    getSquare(window: WindowMain, from: {x: number, y: number}, until: {x: number, y: number}): Reihe[] {
        const rows: Reihe[] = [];

        if ( !window.options.splitCustomConfig ) return rows;

        let beforeRowColLength = 0;
        for ( let rr = from.x; rr < from.x + until.x; rr++) {
            const row = window.options.splitCustomConfig.reihen[rr];
            if ( !row ) continue;
            beforeRowColLength = row.spalten.length;
            const spalten: Spalte[] = [];

            if ( row.spalten.length != beforeRowColLength ) continue;
            for ( let cc = from.y; cc < from.y + until.y; cc++) {
                const col = row.spalten[cc];
                if ( !col ) continue;
                if (col.empty ) continue;

                spalten.push(col);
            }

            rows.push(new Reihe(spalten));
        }

        return rows;
    }

    fastFixFrames(rows: Reihe[]) {
        for ( const row of rows ) {
            for ( const col of row.spalten ) {
                col.frameInnerOffset = new FrameOuterOffset(21);
                col.frameOuterOffset = new FrameOuterOffset();
            }
        }
    }
}
