/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {Button, Form, Input, message} from "antd";
import {Kunde} from "../../interfaces/kunde";
import axios from "axios";
import {API} from "../../core/API";
import {ClientForm} from "../component/forms/client";


export function ClientPage() {
    const nav = useNavigate();
    const { id } = useParams();
    return <Client navigation={nav} clientID={id}/>
}

interface iProps {
    navigation: NavigateFunction;
    clientID: string | undefined;
}

interface iStates {
    client: Kunde | undefined;
}

class Client extends React.Component<iProps, iStates> {
    constructor(props: iProps) {
        super(props);

        this.state = {
            client: undefined
        }
    }

    async componentDidMount() {
        try {
            const api = await axios.post(API.URL + '/kunden/get', {
                token: localStorage.getItem('token'),
                id: this.props.clientID
            });

            if ( api.data.errors ) return message.error(api.data.message);

            this.setState({
                client: api.data.data
            });
        } catch ( error ) {
            message.error(`${error}`);
        }
    }

    async onSave( values: any ) {
        //console.log(values);
        try {
            const api = await axios.post(API.URL + '/kunden/save', {
                token: localStorage.getItem('token'),
                id: this.props.clientID,
                ...values
            });

            if ( api.data.errors ) return message.error(api.data.message);

            message.success('Änderungen gespeichert!');
        } catch ( error ) {
            message.error(`${error}`);
        }
    }

    render() {
        return <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            { this.state.client !== undefined &&

                <ClientForm
                    finish={this.onSave.bind(this)}
                    initial={this.state.client}
                    drawButton={true}
                />
            }
        </div>;
    }
}