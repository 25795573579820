/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 25.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React, {createRef, Ref, RefObject, useRef} from "react";
import {API} from "../../core/API";
import {useNavigate, useParams} from "react-router-dom";
import {Designer} from "../../designer";
import {Alert, Button, Col, Empty, Form, Layout, message, Popover, Row, Space, Switch} from "antd";
import {Project} from "../../interfaces/project";
import axios from "axios";
import {Versions} from "../../interfaces/versions";
import {VersionSelect} from "../component/versionSelect";
import {StreifToolbar} from "../component/toolbar";
import {Export} from "../component/export";
import {SettingsModule} from "../component/settings";
import {PriceShow} from "../component/priceShow";
import {SecondaryMenu} from "../component/secondaryMenu";
import {VersionCopy} from "../component/versionCopy";
import {Comments} from "../component/comments";
import {WindowSettings} from "../component/windowSettings";
import {AddController} from "../../window/addController";
import {WindowOptions} from "../../window/windowOptions";
import {GlassSplitType, Reihe} from "../../models/splitTypes";
import {ToolbarTabs} from "../component/toolbar/tabs";
import {WindowCreator} from "../component/windowCreator";

const { Content } = Layout;

export function DesignerPage() {
	const { project, version, history } = useParams();
	const navigate = useNavigate();

	const containers = {
		top: useRef<HTMLDivElement>(null),
		left: useRef<HTMLDivElement>(null),
		right: useRef<HTMLDivElement>(null),
		bottom: useRef<HTMLDivElement>(null)
	}

	const [versionCreateForm] = Form.useForm();
	return <DesignerController
		key="DesignerController"
		projectID={project}
		versionID={version}
		navigation={navigate}
		versionCreateForm={versionCreateForm}
		historyID={history}
		containers={containers}
	/>
}

interface RouterParams  {
	projectID: string | undefined;
	versionID: string | undefined;
	historyID?: string | undefined;
	navigation: any;
	versionCreateForm: any;
	containers: {
		top: Ref<HTMLDivElement>,
		left: Ref<HTMLDivElement>,
		right: Ref<HTMLDivElement>,
		bottom: Ref<HTMLDivElement>
	};
}

type DesignerStates = {
	show: boolean;
	token: string;
	expire: string;
	expireFormat: string;
	
	designer: Designer | undefined;

	project: Project | undefined; // projekt daten

	sidebarLeft: boolean;
	serverError: boolean;
	//sidebarRight: boolean;
	showTopbar: boolean;
	isSaving: boolean;
	isVersionLoading: boolean;
	isLogout: boolean;

	apiStarted: string;

	newVersionName: string;
	isNewVersion: boolean;
	designerReady: boolean;

	windowSettings: boolean;
}


export class DesignerController extends React.Component<RouterParams, DesignerStates> {
	timer: any = undefined;
	timerInterval = 20;

	constructor(props: any) {
		super(props);
		this.state = {
			show: false,
			token: "loading",
			expire: "loading",
			expireFormat: '--wird geladen--',
			sidebarLeft: false,
			serverError: false,
			project: undefined,
			showTopbar: false,
			isSaving: false,
			isVersionLoading: false,
			isLogout: false,
			designer: undefined,

			apiStarted: "unknown",

			newVersionName: "",
			isNewVersion: false,
			designerReady: false,

			windowSettings: false
		}
	}

	async peakSessionChecker() {
		const state = await API.checkLoginState();
		if ( state === undefined ) {
			localStorage.clear();
			message.error('Session abgelaufen');
			return this.props.navigation("/login");
		}

		this.timer = setTimeout(this.peakSessionChecker.bind(this), (this.timerInterval * 1000));
	}

	async getProject() {
		if ( this.props.projectID === undefined ) return;
		console.log('get Project:', this.props.projectID, this.props.versionID, this.props.historyID);

		const api =  await axios.post(
			API.URL + "/project", {
				token: localStorage.getItem("token"),
				projectID: this.props.projectID
			}
		);

		if ( api === undefined ) return undefined;
		if ( api.data.errors ) {
			if ( api.data.message.includes("server nicht erreichbar")) {
				this.setState({
					show: true,
					serverError: true
				});
			} else {
				this.setState({
					show: true,
					serverError: false,
					project: undefined
				});
				message.error('Projekt kann nicht geladen werden');
			}
			return;
		}

		const defaultVersion = api.data.data.versions.find( ( ver: Versions) => ver.id === this.props.versionID);
		if ( defaultVersion === undefined ) return;
		api.data.data.defaultVersion = defaultVersion.name;

		this.setState({
			show: true,
			serverError: false,
			project: api.data.data
		});

		setTimeout( () => {
			if ( this.state.designer === undefined ) {
				this.setState({
					designer: new Designer('container', api.data.data, this.props.versionID, this.props.historyID)
				}, () => {
					if ( this.state.designer == undefined ) return;
					
					this.state.designer.on('save', () => {
						if ( this.state.designer === undefined ) return;
						if ( !this.state.designerReady ) return;

						message.info('Autospeicher aufgerufen');
						this.saveProject(true);
					});

					this.state.designer.on('ready', () => {
						if ( this.state.designer === undefined ) return;
						this.setState({ designerReady: true });
					});

					this.state.designer.on('topbarClose', () => {
						this.setState({
							showTopbar: false
						});
					});
				});
			}
		}, 250);

	}

    async componentDidMount() {
		const state = await API.checkLoginState();
		if ( state === undefined ) {
			localStorage.clear();
			message.error('Session abgelaufen');
			return this.props.navigation("/login");
		}


		await this.getProject();

		if ( this.timer === undefined )
			this.timer = setTimeout(this.peakSessionChecker.bind(this), (this.timerInterval * 1000));
    }

	componentWillUnmount() {
		if ( this.timer !== undefined ) clearTimeout(this.timer);
	}

	onSaveProjectClick(event: any) {
		this.saveProject();
	}

	async saveProject(autoSave: boolean = false) {
		//console.log('onSaveProject', autoSave);

		if ( this.state.isSaving ) return message.error('Bitte Warten bis vorherige Speicherung beendet ist');
		if ( this.state.designer === undefined ) return message.error("Kann nicht Speichern!");
		if ( this.state.project === undefined ) return message.error("Projekt nicht mehr im Browser-Speicher");

		this.setState({ isSaving: true });

		const projectData = this.state.designer.getProjectData();

		try {
			const api = await axios.post(API.URL + "/project/version/save", {
				token: localStorage.getItem("token"),
				projectID: this.state.project.id,
				versionID: this.props.versionID,
				width: projectData.width,
				height: projectData.height,
				data: JSON.stringify(projectData.windows)
			});


			if ( api === undefined ) {
				this.setState({ isSaving: false });
				return message.error('API Error');
			}
			if ( api.data.result === false ) {
				this.setState({ isSaving: false });
				return message.error(( api.data.message ? api.data.message : 'Unbekannte Fehlermeldung'));
			}

			message.success('Erfolgreich gespeichert!');

			//console.log(api.data.data);
			this.setState({ project: api.data.data });
			if ( this.props.historyID != undefined ) {
				window.location.href = `/designer/${this.props.projectID}/${this.props.versionID}/`;
			}

			setTimeout( () => {
				this.setState({ isSaving: false });
			}, 1000);
		} catch  ( error ) {
			//console.log(error);
			//console.log(projectData);
			message.error('Programmfehler entdeckt');
		}
	}

	getVersionName(): string {
		if ( this.state.project === undefined ) return "null";
		const version = this.state.project.versions.find( (ver) => ver.id === this.props.versionID );
		if ( version === undefined ) return "not found";
		return version.name;
	}

	getDebugPopoverContent() {
		return (
			<>
				API Startzeit: {this.state.apiStarted}<br />
				Letzte Anfrage: --<br />
				IP: --<br />
				Letzte Speicherung: ---<br />
			</>
		);
	}

	getSaveInfo() {
		return (
			<div>
				<p>Um die Aktuelle Version zu speichern</p>
			</div>
		);
	}

	getReloadInfo() {
		return (
			<div>
				Um die Aktuelle Version neuzeichnen zu lassen.
				<Alert message="nicht gespeicherte elemente gehen verloren." type="warning" showIcon />
			</div>
		);
	}

	getDefaultWindowInfo() {
		return (
			<div>
				<p>Um ein Standard Fenster hinzuzufügen</p>
			</div>
		);
	}

	getPointerModeInfo() {
		return (
			<div>
				<p>Um den Pointer Modus zu ändern: <br/>- Drag und Drop<br/>- Fenster größe Ziehen</p>
			</div>
		);
	}

	refreshProjectData(data: Project) {
		this.setState({project: data});
	}

	setReload() {
		window.location.reload();
	}

	onSwitchChanged(value: boolean) {
		if ( !this.state.designer ) return;
		this.state.designer.emitReact('onDragReplacementChanged', value);
	}

    render() {
        return <>
			{
				!this.state.show &&
				<div>
					<div className="saving-overlay">
						<div className="centered">
							<div className="loader"></div>
						</div>
					</div>
				</div>
			}

			{ this.state.show && this.state.project !== undefined &&
				<>
					{ this.state.designer &&
						<WindowSettings designer={this.state.designer}/>
					}
					<Row justify={"space-between"} align={"middle"} style={{backgroundColor: 'white', padding: '5px 5px'}}>
						<Row justify={"start"} align={"middle"}>
							<Space>
								<Button
									type={"link"}
									icon={<i className="fas fa-home"></i>}
									onClick={() => this.props.navigation("/")} />

								{ this.state.designer !== undefined && this.state.project !== undefined && this.props.versionID !== undefined &&
									<SecondaryMenu parent={this} designer={this.state.designer} project={this.state.project} versionID={this.props.versionID} key={"SecondaryMenuComponentTier1"} />
								}
								{ this.state.project &&
									<div className="d-flex flex-column align-items-stretch d-none d-md-block">
										<span className="fw-bold">{ this.state.project.name }</span>
									</div>
								}
								{ this.state.show && this.state.project && this.state.project.versionID && this.state.designer !== undefined &&
									<VersionSelect
										parent={this}
										designer={this.state.designer}
										project={this.state.project}
										versionID={this.props.versionID}
									/>
								}
								<Popover content={this.getSaveInfo()} title={"Speichern"}>
									<Button type={"link"} icon={<i className="fa-solid fa-save mr-2"></i>} onClick={this.onSaveProjectClick.bind(this)} loading={this.state.isSaving}></Button>
								</Popover>
								<VersionCopy parent={this} designer={this.state.designer} projectID={this.props.projectID}/>
								<Popover content={this.getReloadInfo()} title={"Neuzeichnen"}>
									<Button type={"link"} icon={<i className="fa-solid fa-rotate"></i>} onClick={this.setReload.bind(this)}></Button>
								</Popover>
								<Button
									type={"link"}
									className={"draggable-item"}
									data-id={99} data-type={99}
									icon={<i className={"fas fa-trash"}></i>}
								/>
								<Switch onChange={this.onSwitchChanged.bind(this)} />
							</Space>
						</Row>
						<div>
							{ this.state.designer &&
								<WindowCreator designer={this.state.designer} />
							}

							<Popover content={this.getDefaultWindowInfo()} title={"Standard Fenster"}>
								<a
									className="btn btn-sm btn-link mx-2"
									onClick={() => {
										if ( this.state.designer === undefined ) return;

										const nWindow = new AddController(this.state.designer);
										nWindow.apply();

										if (this.state.designer.sumMetric ) this.state.designer.sumMetric.update();
										this.state.designer.updateZoom();
									}}
								>
									<i className="fa-solid fa-vector-square"></i>
								</a>
							</Popover>
						</div>
						<div>
							{ this.state.designer !== undefined &&
								<PriceShow designer={this.state.designer} projectID={this.props.projectID} versionID={this.props.versionID} />
							}
							<Popover content={this.getPointerModeInfo()} title={"Pointermode"}>
								<a id="pointerMode" className="mx-2"></a>
							</Popover>
							{ this.props.projectID !== undefined && this.props.versionID !== undefined &&
								<Comments projectID={this.props.projectID} versionID={this.props.versionID} />
							}
							{ this.state.designer !== undefined &&
								<SettingsModule parent={this} debug={this.getDebugPopoverContent.bind(this)} designer={this.state.designer} />
							}
							<Export designer={this.state.designer} projectID={this.props.projectID} versionID={this.props.versionID} project={this.state.project}/>
						</div>
					</Row>
					{ this.state.designer !== undefined &&
						<ToolbarTabs designer={this.state.designer} />
					}
					<div className="preview-container">
						<div className="preview-container-inner">
							<div className="containerTop" >
								<div className="containerTopInner" id="container-top">
								</div>
							</div>
							<div className="containerMiddle">
								<div className="containerMiddleInner">
									<div className="containerLeft">
										<div className="containerLeftInner" id="container-left">
										</div>
									</div>
									<div className="containerKonva">
										<div className="preview-zoomer">
											<ul className="list-unstyled list-inline">
												{ this.state.designer &&
													<>
														<li className="list-inline-item">
															<a
																className="btn btn-link"
																onClick={() => this.state.designer && this.state.designer.updateZoom(this.state.designer.currentScale += 0.02) }
															>
																<i className="fa-solid fa-plus"></i>
															</a>
														</li>
														<li className="list-inline-item">
															<a
																className="btn btn-link"
																onClick={() => this.state.designer && this.state.designer.updateZoom(this.state.designer.currentScale -= 0.01) }
															>
																<i className="fa-solid fa-minus"></i>
															</a>
														</li>
														<li className="list-inline-item">
															<a
																className="btn btn-link"
																onClick={() => this.state.designer && this.state.designer.updateZoom()}
															>
																<i className="fa-solid fa-compress"></i>
															</a>
														</li>
													</>
												}
											</ul>
										</div>
										<div id="container" style={{width: "100%", height: "100%", padding: 10}}></div>
									</div>
									<div className="containerRight">
										<div className="containerRightInner" id="container-right">
										</div>
									</div>
								</div>
							</div>
							<div className="containerBottom">
								<div className="containerBottomInner" id="container-bottom">
								</div>
							</div>
						</div>
					</div>
				</>
			}

			{
				this.state.project === undefined &&
				<Content style={{ padding: '0 50px' }}>
					<div className="site-layout-content">
						{
							this.state.show && this.state.project === undefined && !this.state.serverError &&
							<Empty
								description = {
									<span>
										Projekt wurde nicht gefunden
									</span>
								}
							>
								<Button type="primary" href={"/projects"}>Zurück zur Projektauswahl</Button>
							</Empty>
						}
						{
							this.state.show && this.state.project === undefined && this.state.serverError &&
							<Empty
								description = {
									<span>
										Server nicht erreichbar, bitte bei Projekt Entwickler melden.
									</span>
								}
							>
								<Button type="primary">Neuladen</Button>
							</Empty>
						}
					</div>
				</Content>
			}
		</>;
    }
}
