/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, message, Modal, Pagination, Progress, Result, Spin} from "antd";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import {API} from "../../core/API";
import axios from "axios";
import {Designer} from "../../designer";
import {Project} from "../../interfaces/project";
import {NavigateFunction, useNavigate} from "react-router-dom";

export function Export(props: any) {
    const nav = useNavigate();

    return <ExportComponent
        designer={props.designer}
        nav={nav}
        projectID={props.projectID}
        versionID={props.versionID}
        project={props.project}
    />
}


interface iProps {
    designer: Designer | undefined;
    nav: NavigateFunction;
    projectID: string | undefined;
    versionID: string | undefined;
    project: Project | undefined;
}
interface iStates {
    isExporting: boolean;
    exportModal: boolean;
    exportShowPDF: boolean;
    exportActionFileProgress: number;
    exportPDFPages: number;
    exportPDFPage: number;
    exportPDFPagination: any[];
    exportingActionText: string;
    exportIframe: string;
    exportPDFError: boolean;
    exportPDFErrorText: string;
}


class ExportComponent extends React.Component<iProps, iStates> {


    constructor(props: iProps) {
        super(props);
        this.state = {
            isExporting: false,
            exportModal: false,
            exportingActionText: "Exportieren",
            exportActionFileProgress: 0,
            exportIframe: "",
            exportPDFPages: 0,
            exportPDFPage: 1,
            exportShowPDF: false,
            exportPDFError: false,
            exportPDFPagination: [],
            exportPDFErrorText: ""
        }
    }


    exportError(text: string) {
        this.setState({
            isExporting: false,
            exportActionFileProgress: 0,
            exportingActionText: ""
        }, () => {
            message.error(text);
        });
    }

    exportUploadProgress( event: ProgressEvent ) {
        this.setState({
            exportActionFileProgress: (event.loaded * 100 / event.total)
        });
    }

    setExportError(error: string) {
        this.setState({
            exportActionFileProgress: 0,
            isExporting: false,
            exportModal: false
        }, () => {
            message.error(error);
        });
    }

    onPDFSuccess( docInfo: any ) {
        //console.log(docInfo.numPages, docInfo);
        this.setState({
            exportPDFPage: 1,
            exportPDFPages: docInfo.numPages,
            exportShowPDF: true
        });
    }

    onPaginationChange(page: number, pageSize: number) {
        //console.log(page, pageSize);
        this.setState({
            exportPDFPage: page
        });
    }

    onPDFError( error: any ) {
        //console.log(`${error}`);
    }

    exportModalNextPage() {
        let next = 0;
        if ( this.state.exportPDFPage >= this.state.exportPDFPages ) {
            next = this.state.exportPDFPages;
        } else { next = this.state.exportPDFPage + 1; }

        //console.log('NEXT PAGE:', next);

        this.setState({
            exportPDFPage: next
        });
    }

    exportModalPrevPage() {
        let next = this.state.exportPDFPage;
        if ( next < 1 ) {
            next = 1;
        } else { next = this.state.exportPDFPage - 1; }

        this.setState({
            exportPDFPage: next
        });
    }

    onStartExport( e: any ) {

        this.props.nav(`/export/${this.props.projectID}/${this.props.versionID}`);


        /*
        if ( this.state.isExporting ) return message.error("Bitte Warten");
        if ( this.props.designer === undefined ) return this.exportError("Designer nicht aktiv.");
        if ( this.props.designer.stage === undefined ) return this.exportError("Designer Stage nicht gefunden.");
        if ( this.props.designer.layer === undefined ) return this.exportError("Designer Layer nicht gefunden.");
        if ( this.props.project === undefined ) return this.exportError("Projekt nicht gefunden.");

        this.setState({
            exportActionFileProgress: 0,
            isExporting: true,
            exportModal: true,
            exportingActionText: 'Vorbereitung'
        }, async () => {
            if ( this.props.designer === undefined ) return message.error('Kann aktuellen Designer nicht finden!');
            if ( this.props.project === undefined ) return message.error('Kann aktuellen Projekt-Daten nicht finden!');


            try {
                const api = await axios.post(API.URL + "/project/export", {
                    token: localStorage.getItem('token'),
                    projectID: this.props.projectID
                });

                if ( api === undefined ) return this.setExportError("Unbekannter API-Fehler");
                if ( api.status != 200 ) return this.setExportError("Server nicht erreichbar!");

                if ( api.data.result === false ) return this.setExportError(api.data.message);
                if ( api.data.errors ) return this.setExportError(api.data.message);
                if ( api.data.data.url === undefined || api.data.data.url.length === 0 )
                    return this.setExportError("Fehler beim anzeigen der PDF");

                //console.log(API.URL + '/' + api.data.data.url);
                this.setState({
                    exportIframe: api.data.data.url,
                    isExporting: false
                });
            } catch ( error ) {
                //console.log(error);
                message.error('Project Export error');
            }
        });*/
    }

    onExportCancel() {
        this.setState({
            exportModal: false
        });
    }

    render() {
        return <>
            { this.props.designer != undefined &&
                <Button type={"primary"} onClick={this.onStartExport.bind(this)} loading={this.state.isExporting}>
                    { this.state.isExporting &&
                        <span>{ this.state.exportingActionText }</span>
                    }
                    { !this.state.isExporting &&
                        <span>Exportieren</span>
                    }
                </Button>
            }

            <Modal
                title={ this.state.isExporting ? 'PDF wird erstellt....' : 'PDF Ansicht'}
                centered
                footer={[
                    <Pagination current={this.state.exportPDFPage} defaultCurrent={1} total={this.state.exportPDFPages} disabled={this.state.isExporting} onChange={this.onPaginationChange.bind(this)}/>
                ]}
                onCancel={this.onExportCancel.bind(this)}
                visible={this.state.exportModal}
                width={1000}
            >
                <div style={{position: "relative", height: 500}}>
                    {
                        this.state.isExporting &&
                        <div className="loader-centered">
                            <Spin tip={this.state.exportingActionText} size={"large"} />
                            <Progress percent={this.state.exportActionFileProgress} status={"active"} />
                        </div>
                    }

                    { !this.state.exportPDFError && !this.state.isExporting &&
                        <div className={"pdf_viewer"}>
                            <Document
                                file={API.URL + '/' + this.state.exportIframe}
                                onSourceError={this.onPDFError.bind(this)}
                                onLoadError={this.onPDFError.bind(this)}
                                onLoadSuccess={this.onPDFSuccess.bind(this)}>

                                <Page pageNumber={this.state.exportPDFPage} />
                            </Document>
                        </div>
                    }
                    {
                        this.state.exportPDFError &&
                        <Result
                            status={"error"}
                            title={"Fehler beim Laden"}
                            subTitle={this.state.exportPDFErrorText}
                        />
                    }
                </div>
            </Modal>
        </>
    }
}