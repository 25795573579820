/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 14.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Designer} from "../designer";
import {WindowFrame} from "../frame/windowFrame";
import {Utils} from "../utils/utils";
import {Glass} from "../glass/main";
import {GlassOptions} from "../glass/glassOptions";
import {PlaceholderController} from "./placeholder";
import {ConfigController} from "../core/configController";
import {WindowOptions, WindowOptionsConfig} from "./windowOptions";
import {GlassConfig} from "../core/data";
import {Directions} from "../interfaces/directions";
import {Dir} from "fs";

export class WindowMain {
	x: number = 50;
	y: number = 50;
	id: string = "---nichts---";
	lastParent: WindowMain | undefined;

	rect: Konva.Rect | undefined;
	highlightRect: Konva.Rect | undefined;

	group: Konva.Group | undefined;

	glass: Glass[] = new Array<Glass>();
	placeholderController: PlaceholderController | undefined; // <- das noch besser!

	frame: WindowFrame | undefined;

	_options: WindowOptions = new WindowOptions();
	_parent: Rect | undefined = undefined;
	_metrics: boolean = true;
	_isPreset: boolean = false;
	_glassConfig: GlassConfig[] | undefined = new Array<GlassConfig>;

	constructor(
		public designer: Designer | undefined
	) {
		if ( this.isPreset ) {
			this.x = 0;
			this.y = 0;
		}
	}

	get parent(): Rect | undefined { return this._parent; }
	set parent(parent: Rect | undefined) {
		this._parent = parent;
	}

	get options(): WindowOptions { return this._options; }
	set options(options: WindowOptions | WindowOptionsConfig) {
		if (options instanceof WindowOptionsConfig) {
			this._options = new WindowOptions();
			for ( const key of Object.keys(options)) {
				// @ts-ignore
				this._options[key] = options[key];
			}
		} else {
			this._options = options;
		}
	}

	get metrics(): boolean { return this._metrics }
	set metrics(value: boolean) {
		this._metrics = value;
	}

	get isPreset(): boolean { return this._isPreset }
	set isPreset(value: boolean) {
		this._isPreset = value;
	}

	get glassConfig(): GlassConfig[] | undefined { return this._glassConfig }
	set glassConfig(value: GlassConfig[] | undefined) {
		this._glassConfig = value;
	}

	public create() {
		this.group = new Konva.Group({
			//opacity: 0.3
		});

		if ( this.options.id === undefined || this.options.id.length === 0) {
			const genUUID = Utils.generateID();
			this.id = `window-${genUUID}`;
			this.options.id = `window-${genUUID}`;
		} else {
			this.id = this.options.id;
		}

		if ( this.parent !== undefined ) this.options.parentID = this.parent.id;

		if ( this.parent !== undefined ) {
			if ( this.parent.rect === undefined ) return;

			if ( this.options.direction === Directions.RIGHT ) { // to the right
				this.x = this.parent.x + this.parent.options.width + this.parent.options.outerFrameOffset.left + this.options.outerFrameOffset.left;
				this.y = this.parent.y;

				if ( this.parent.options.isKopplung && !this.options.isKopplung  ) this.y += this.options.outerFrameOffset.left;
				if ( this.parent.options.isKopplung && !this.options.isKopplung  ) this.x -= this.options.outerFrameOffset.left;

			} else if ( this.options.direction === Directions.BOTTOM ) { // to bottom
				this.x = this.parent.rect.x();
				this.y = this.parent.rect.y() + this.parent.rect.height() + this.parent.options.outerFrameOffset.left + this.options.outerFrameOffset.left;

				if ( this.parent.options.isKopplung && !this.options.isKopplung  ) this.x += this.options.outerFrameOffset.left;

			} else if ( this.options.direction === Directions.TOP ) { // to top
				this.x = this.parent.rect.x();
				this.y = this.parent.rect.y() - this.options.height - this.parent.options.outerFrameOffset.left - this.options.outerFrameOffset.left;

				if ( this.parent.options.isKopplung && !this.options.isKopplung ) this.x += this.options.outerFrameOffset.left;
			}

			if ( this.options.anchor === 1 ) { // middle anker
				if ( this.options.direction === Directions.RIGHT ) { // RIGHT
					this.y = (this.parent.rect.height() + this.parent.options.outerFrameOffset.bottom) / 2;
				} else if ( this.options.direction === Directions.TOP ) { // TOP
					this.x = (this.parent.rect.width() + this.parent.options.outerFrameOffset.right) / 2;
				}
			} else if ( this.options.anchor === 2 ) { //
				if ( this.options.direction === Directions.RIGHT ) { // RIGHT
					this.y = this.parent.rect.height() + this.parent.options.outerFrameOffset.bottom - this.options.height;
				} else if ( this.options.direction === Directions.TOP ) { // TOP
					this.x = this.parent.rect.width() + this.parent.options.outerFrameOffset.right - this.options.width;
				}
			}
		}
		
		/**
		 * Wenn das objekt eine Kopplung ist
		 */
		if ( this.options.isKopplung ) {
			if ( this.parent != undefined ) {
				if ( this.parent.rect === undefined ) return;
				if ( this.options.direction === Directions.RIGHT ) { // RIGHT
					if ( !this.parent.options.isKopplung ) {
						this.y -= this.parent.options.outerFrameOffset.left;
						this.x -= this.parent.options.outerFrameOffset.left;
						this.options.height = this.parent.rect.height() + this.parent.options.outerFrameOffset.left + this.parent.options.outerFrameOffset.left;
					} else {
						this.x -= this.parent.options.outerFrameOffset.left;
						this.x -= this.parent.options.outerFrameOffset.left;
						this.options.height = this.parent.rect.height();
					}
				} else if ( this.options.direction === Directions.BOTTOM ) { // BOTTOM
					if ( !this.parent.options.isKopplung ) {
						this.y -= this.parent.options.outerFrameOffset.left;
						this.x -= this.parent.options.outerFrameOffset.left;
						this.options.width = this.parent.rect.width() + this.parent.options.outerFrameOffset.left + this.parent.options.outerFrameOffset.left;
					} else {
						this.x = this.parent.rect.x();
						this.y = this.parent.rect.y() + this.parent.rect.height();
						this.options.width = this.parent.rect.width();
					}
				} else if ( this.options.direction === Directions.TOP ) { // TOP
					if ( !this.parent.options.isKopplung ) {
						this.y = this.parent.rect.y() - this.parent.options.outerFrameOffset.left - this.options.height;
						this.x -= this.parent.options.outerFrameOffset.left;
						this.options.width = this.parent.rect.width() + this.parent.options.outerFrameOffset.left + this.parent.options.outerFrameOffset.left;
					} else {
						this.y = this.parent.rect.y() - this.options.height;
						this.x = this.parent.rect.x();
						this.options.width = this.parent.rect.width();
					}
				}
			}
		}

		if ( this.isPreset ) {
			this.x = 0;
			this.y = 0;
		}

		this.rect = new Konva.Rect({
			x: this.x,
			y: this.y,
			width: this.options.width,
			height: this.options.height,
			fill: this.getBackgroundColor(),
			id: this.id,
			listening: !this.isPreset
		});


		if ( this.options.isKopplung ) {
			this.rect.stroke('black');
			this.rect.strokeWidth(1);
		}

		if ( this.group === undefined ) return;

		this.group.add(this.rect);

		if ( this.designer === undefined ) return;
		if ( this.designer.layer === undefined ) return;

		this.designer.layer.add(this.group);
		if ( this.designer.sumMetric ) this.designer.sumMetric.update()
	}

	destroy() {
		if ( this.rect === undefined ) return;
		this.rect.destroy();

		for ( const glass of this.glass ) {
			if ( glass.sprossenController !== undefined )
				glass.sprossenController.destroy();
			glass.destroy();
		}
		this.glass = [];

		if ( this.group !== undefined ) {
			this.group.destroy();
			this.group = undefined;
		}
	}

	update(noZoom: boolean = false) {
		if ( this.designer === undefined ) return;
		if ( this.designer.stage === undefined ) return;

		this.designer.updateZoom(1);
		this.designer.stage.absolutePosition({x: 0, y: 0});

		this.destroy();
		this.create();

		if ( this.designer.sumMetric !== undefined )
			this.designer.sumMetric.update();
	}

	addGroup(object: any) {
		if ( this.group === undefined ) return;

		this.group.add(object);
	}

	getBackgroundColor() {
		return this.options.frameColor;
	}

	highlight() {
		if ( this.rect === undefined ) return;
		if ( this.group === undefined ) return;


		if ( this.highlightRect === undefined ) {
			this.highlightRect = new Konva.Rect({
				x: this.rect.x(),
				y: this.rect.y(),
				width: this.rect.width() ,
				height: this.rect.height(),
				fill: 'green',
				opacity: 0
			});

			this.group.add(this.highlightRect);
		}

		this.highlightRect.opacity(1);
		setTimeout(this.animationHideHighlight.bind(this), 50);
	}

	animationHideHighlight() {
		if ( this.highlightRect === undefined ) return;

		if ( this.highlightRect.opacity() <= 0.1 ) {
			this.highlightRect.destroy();
			this.highlightRect = undefined;
			return;
		}

		this.highlightRect.opacity(
			this.highlightRect.opacity() - 0.05
		);
		setTimeout(this.animationHideHighlight.bind(this), 50);
	}
}

export class Rect extends WindowMain {

	create() {
		super.create();

		if ( this.options.isKopplung ) return;

		const glassSplitType = ( this.options.splitCustomConfig ? this.options.splitCustomConfig : ConfigController.splitTypes[this.options.splitType]);
		if ( glassSplitType === undefined ) return console.log('[Rect] cant find glassSplitType!');

		let nextY = 0;
		if ( this.rect === undefined ) return;


		let glassIndex: number = 0;
		for ( let i = 0; i < glassSplitType.reihen.length; i++ ) {
			const reihe = glassSplitType.reihen[i];

			let height = ( reihe.height !== 0 ? reihe.height : this.rect.height() / glassSplitType.reihen.length);

			const nextReihe = glassSplitType.reihen[(i + 1)];
			if ( nextReihe == null ) {
				let grundHoehe = this.rect.height();

				for (let checkI = i; checkI >= 0; checkI-- ) {
					const checkReihe = glassSplitType.reihen[checkI];
					grundHoehe -= (checkReihe.height !== 0 ? checkReihe.height : height);
				}

				if ( grundHoehe !== 0 ) {
					height = (height + grundHoehe);
				}
			}

			let nextX = 0;
			for ( let i = 0; i < reihe.spalten.length; i++ ) {
				const column = reihe.spalten[i];
				const beforeColumn = reihe.spalten[(i-1)];

				let durchOhneTrenner = 0;
				for ( const column of reihe.spalten) {
					if ( !column.isTrenner ) durchOhneTrenner++;
				}
				let width = ( column.width !== 0 ? column.width : this.rect.width() / durchOhneTrenner);

				const nextColumn = reihe.spalten[(i + 1)];
				if ( nextColumn == null ) {
					let groundWidth = this.rect.width();
					for ( let checkI = i; checkI >= 0; checkI--) {
						const checkColumn = reihe.spalten[checkI];
						groundWidth -= (checkColumn.width !== 0 ? checkColumn.width : width);
					}

					if ( groundWidth !== 0 ) {
						width = (width + groundWidth);
					}

				} else {
					if ( column.frameType === 2 && nextColumn.frameType === 14 ) {
						column.frameOuterOffset.right = 0;
						column.frameInnerOffset.right = 0;
					}
				}

				if ( beforeColumn != null ) {
					if ( column.frameType === 7 && beforeColumn.frameType === 6 ) {
						if ( this.isPreset ) {
							nextX -= 5;
							width += 5;
						} else {
							nextX -= 20;
							width += 20;
						}
					}

					if ( column.frameType === 2 && beforeColumn.frameType === 13 ) {
						column.frameOuterOffset.left = 0;
						column.frameInnerOffset.left = 0;
					}

					if ( column.frameType === 14 && beforeColumn.frameType === 13 ) {
						column.frameOuterOffset.left = 0;
						column.frameInnerOffset.left = 0;
					}
				}


				const glassOption = new GlassOptions();
				glassOption.id = column.id;
				glassOption.x = nextX;
				glassOption.y = nextY;
				glassOption.width = width;
				glassOption.height = height;
				glassOption.empty = column.empty;
				glassOption.isTrenner = column.isTrenner;
				glassOption.frameType = column.frameType;
				glassOption.frameOuterOffset = column.frameOuterOffset;
				glassOption.frameInnerOffset = column.frameInnerOffset;
				glassOption.glassType = column.glassType;

				const glass = new Glass(this, glassOption);
				column.glass = glass;
				this.glass.push(glass);
				glassIndex++;
				nextX += width;
			}
			nextY += height;
		}

		if ( this.frame !== undefined ) this.frame.destroy();
		if ( this.designer === undefined ) return;

		this.frame = new WindowFrame(this, this.designer);

		if ( this.placeholderController !== undefined ) this.placeholderController.destroy();
		this.placeholderController = new PlaceholderController(this);
	}

	highlightFrame(color: string = 'gray') {
		if ( this.frame === undefined ) return;
		if ( this.frame.frameTop === undefined ) return;
		if ( this.frame.frameRight === undefined ) return;
		if ( this.frame.frameLeft === undefined ) return;
		if ( this.frame.frameBottom === undefined ) return;

		this.frame.frameTop.fill(color);
		this.frame.frameRight.fill(color);
		this.frame.frameLeft.fill(color);
		this.frame.frameBottom.fill(color);
	}

	highlightRight() {
		if ( this.frame === undefined ) return;
		if ( this.frame.frameRight === undefined ) return;
		this.frame.frameRight.fill("gray");
	}

	highlightBottom() {
		if ( this.frame === undefined ) return;
		if ( this.frame.frameBottom === undefined ) return;
		this.frame.frameBottom.fill("gray");
	}

	refreshColors() {
		if ( this.rect === undefined ) return;
		if ( this.frame === undefined ) return;

		if ( this.options.isKopplung )
			this.rect.fill("white");

		if ( this.frame === null ) return;

		if ( this.frame.frameTop === undefined ) return;
		if ( this.frame.frameRight === undefined ) return;
		if ( this.frame.frameLeft === undefined ) return;
		if ( this.frame.frameBottom === undefined ) return;
		const frameColor = this.frame.getFrameColor();
		this.frame.frameTop.fill(frameColor);
		this.frame.frameLeft.fill(frameColor);
		this.frame.frameRight.fill(frameColor);
		this.frame.frameBottom.fill(frameColor);
	}

	update(noZoom: boolean = false) {
		if ( this.options.splitCustomConfig ) {
			//console.log(this.options.id, 'has splitConfig:', this.options.splitCustomConfig);
			if ( this.options.splitCustomConfig.windowOptions) {
				console.log(this.options.id, 'splitConfig has windowOptions');
				for ( const key of Object.keys(this.options.splitCustomConfig.windowOptions)) {
					console.log('apply key:', key, 'to window options');
					// @ts-ignore
					this.options[key] = this.options.splitCustomConfig.windowOptions[key];
				}
			}
		}

		super.update(noZoom);
		if ( this.placeholderController != null ) this.placeholderController.update();
		if ( this.designer === undefined ) return;



		if ( this.designer.sumMetric ) this.designer.sumMetric.update();
	}

	destroy() {
		super.destroy();
		if ( this.placeholderController !== undefined ) this.placeholderController.destroy();

		if ( this.options.isKopplung ) return;
		if ( this.frame !== undefined ) this.frame.destroy();
	}

	setGlassType(glassID: string, typeID: number) {
		console.log('setGlassType', glassID, typeID);
		if ( this.designer === undefined ) return;
		if ( this.options.splitCustomConfig === undefined ) return;

		for ( const row of this.options.splitCustomConfig.reihen ) {
			for ( const col of row.spalten ) {
				if ( col.id === glassID.replace('glass-', '')) {
					col.frameType = typeID;
				}
			}
		}


		this.update();
		this.designer.updateZoom();
	}
}
