/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 7.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import axios from "axios";
import {
    Button,
    Col,
    Form,
    FormInstance,
    Input,
    message,
    Modal, Popconfirm,
    Row,
    Select,
    Space,
    Table,
    TableColumnsType
} from "antd";
import {useForm} from "antd/es/form/Form";
import {Article} from "../../../interfaces/articles";
import {ArticleTypes, articleTypes} from "../../../enums/articleTypes";
import {API} from "../../../core/API";


export function ArticlesTypes() {

    const [ article ] = useForm();
    const [ editArticle ] = useForm();
    return <Component articleForm={article} articleEditForm={editArticle} />;
}

interface DataType {
    key: React.Key;
    name: string;
    type: number;
    expandedCols?: any;
}

interface ExpandedDataType {
    key: React.Key;
    date: string;
    name: string;
    upgradeNum: string;
}

interface iProps {
    articleForm: FormInstance;
    articleEditForm: FormInstance;
}

interface iStates {
    data: DataType[];
    articles: Article[];

    isArticleModalOpen: boolean;
    isArticleModalCreating: boolean;

    editArticle: Article | undefined;
    deleteArticle: Article | undefined;

    articleCreateType: number | undefined;
}

class Component extends React.Component<iProps, iStates> {
    constructor(props: iProps) {
        super(props);

        this.state = {
            data: [],
            articles: [],

            editArticle: undefined,
            deleteArticle: undefined,

            isArticleModalOpen: false,
            isArticleModalCreating: false,
            articleCreateType: undefined,
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const articlesAPI = await axios.post(API.URL + '/articles', {
            token: localStorage.getItem('token')
        });

        console.log(articlesAPI.data);

        if ( articlesAPI.data.errors ) return message.error(articlesAPI.data.message ? articlesAPI.data.message : 'Unbekannte Fehlermeldung');

        this.setState({
            articles: articlesAPI.data.data,
            data: articleTypes.map( (article, index) => {
                return {
                    key: 'article_' + index,
                    type: index,
                    name: article
                }
            })
        });
    }

    expandedRowRender = (articleType: ArticleTypes) => {
        const article = this.state.articles.find( (article ) => article.type === articleType);
        console.log(article);
        if ( !article ) return <div>Keine Artikel vorhanden</div>
        // @ts-ignore
        const data = [];
        for ( const dat of this.state.articles) {
            if ( dat.type === articleType ) {

                data.push({
                    // @ts-ignore
                    ...article.typeData,
                    name: article.name,
                });
            }
        }

        // @ts-ignore
        const keys = Object.keys(data);
        const columns: TableColumnsType<any> = [];

        keys.map( ( key ) => {
            if ( key !== 'id' && key !== 'targetID' && key !== 'type' ) {
                columns.push({
                    title: key.toUpperCase(),
                    dataIndex: key,
                    key: key
                });
            }
        });
        columns.push({
            title: 'AKTION',
            dataIndex: 'operation',
            key: 'operation',
            render: () => (
                <Space size="middle">
                    <Popconfirm
                        title={article.name + ' wirklich löschen?'}
                        onConfirm={() => this.onPopConfirmed(article.id)}
                        okText="Löschen"
                        cancelText="Abbrechen"
                    >
                        <Button
                            type={"link"}
                        >
                            Löschen
                        </Button>
                    </Popconfirm>
                    <Button
                        type={"link"}
                        onClick={() => {
                            console.log('clicked on edit:', article);
                            this.setState({editArticle: article})
                        }}
                    >
                        Bearbeiten
                    </Button>
                </Space>
            ),
        });

        return <Table columns={columns} dataSource={data} pagination={false} />;
    }

    onPopConfirmed(id: string) {
        this.deleteArticle(id);
    }

    articlesColumn: TableColumnsType<DataType> = [
        { title: 'Typname', dataIndex: 'name', key: 'name' },
    ];

    async onCreateArticle(values: any) {
        //console.log(values);

        const api = await axios.post(API.URL + '/article/add', {
            token: localStorage.getItem("token"),
            ...values
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');


        this.setState({isArticleModalOpen: false});
        this.getData();
    }

    async onEditArticle(values: any) {
        if ( !this.state.editArticle ) return;

        const api = await axios.post(API.URL + '/article/edit', {
            token: localStorage.getItem("token"),
            id: this.state.editArticle.id,
            ...values
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        this.setState({editArticle: undefined});
        this.getData();
    }

    async deleteArticle(id: string) {
        const api = await axios.post(API.URL + '/article/delete', {
            token: localStorage.getItem('token'),
            id: id
        });

        if ( api.data.errors )
            return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung')

        this.getData();

    }

    render() {
        return <>
            <div>
                <Row justify={"space-between"} align={"middle"} style={{padding: "20px 20px"}}>
                    <Col>
                        <strong>Artikel</strong>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            shape={"round"}
                            onClick={() => this.setState({isArticleModalOpen: true})}
                        >
                            Hinzufügen
                        </Button>
                    </Col>
                </Row>
                <Table
                    columns={this.articlesColumn}
                    expandable={{
                        expandedRowRender: (record, index, indent, expanded) => {
                            console.log('expandedRowRender', record);
                            return this.expandedRowRender(record.type)
                        },
                        defaultExpandAllRows: true,
                        showExpandColumn: true

                    }}
                    dataSource={this.state.data}
                    size="small"
                />
            </div>

            <Modal
                title="Artikel Erstellen"
                visible={this.state.isArticleModalOpen}
                onOk={() => this.props.articleForm.submit()}
                onCancel={() => this.setState({isArticleModalOpen: false})}
            >
                <Form
                    layout={"vertical"}
                    form={this.props.articleForm}
                    onFinish={this.onCreateArticle.bind(this)}
                >
                    <Form.Item label="Bezeichnung / Name" name={"name"} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Type" name={"type"} rules={[{ required: true }]}>
                        <Select
                            onChange={(value, option) => this.setState({articleCreateType: Number(value)})}
                        >
                            {
                                articleTypes.map( (articleType, index) => {
                                    return <Select.Option key={index}>{articleType}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    {this.state.articleCreateType !== undefined && this.state.articleCreateType === ArticleTypes.GLAS &&
                        <>
                            <Form.Item label={"Dezibel"} name={"dezibel"}>
                                <Input addonAfter={"db"} type={"number"} />
                            </Form.Item>
                            <Form.Item label={"Preis"} name={"price"}>
                                <Input addonAfter={"€"} type={"number"} />
                            </Form.Item>
                            <Form.Item label={"DIN"} name={"drin"}>
                                <Input addonAfter={"din"} type={"number"} />
                            </Form.Item>
                            <Form.Item label={"Dicke"} name={"thickness"}>
                                <Input addonAfter={"mm"} type={"number"} />
                            </Form.Item>
                        </>
                    }
                    {this.state.articleCreateType !== undefined && this.state.articleCreateType === ArticleTypes.GUSS &&
                        <>
                            <Form.Item label={"Dicke"} name={"thickness"}>
                                <Input addonAfter={"mm"} />
                            </Form.Item>
                            <Form.Item label={"Preis"} name={"price"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                        </>
                    }
                    {this.state.articleCreateType !== undefined && this.state.articleCreateType === ArticleTypes.SPROSSE &&
                        <>
                            <Form.Item label={"Helima 18mm"} name={"helima18"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                            <Form.Item label={"Helima 26mm"} name={"helima26"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                            <Form.Item label={"Helima 45mm"} name={"helima45"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                            <Form.Item label={"Ziersprosse 10mm"} name={"zier10"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                            <Form.Item label={"Style-Sprosse 10mm"} name={"style10"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                            <Form.Item label={"Nostalgie-/Stecksprosse 8mm"} name={"nostalgie8"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                        </>
                    }
                    {this.state.articleCreateType !== undefined && this.state.articleCreateType === ArticleTypes.RABATTE &&
                        <>
                            <Form.Item label={"Dicke"} name={"thickness"}>
                                <Input addonAfter={"mm"} />
                            </Form.Item>
                            <Form.Item label={"Preis"} name={"price"}>
                                <Input addonAfter={"€"} />
                            </Form.Item>
                        </>
                    }
                </Form>
            </Modal>

            { this.state.editArticle !== undefined &&
                <Modal
                    title={this.state.editArticle.name + ' Bearbeiten'}
                    visible={this.state.editArticle !== undefined}
                    onOk={() => this.props.articleEditForm.submit()}
                    onCancel={() => this.setState({editArticle: undefined})}
                >
                    <Form
                        layout={"vertical"}
                        form={this.props.articleEditForm}
                        onFinish={this.onEditArticle.bind(this)}
                        initialValues={this.state.editArticle}
                    >
                        <Form.Item label="Bezeichnung / Name" name={"name"} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Type:">
                            <span className="ant-form-text">{articleTypes[this.state.editArticle.type]}</span>
                        </Form.Item>

                        {this.state.editArticle !== undefined && this.state.editArticle.type === ArticleTypes.GLAS &&
                            <>
                                <Form.Item label={"Dezibel"} name={"dezibel"}>
                                    <Input addonAfter={"db"} type={"number"} />
                                </Form.Item>
                                <Form.Item label={"Preis"} name={"price"}>
                                    <Input addonAfter={"€"} type={"number"} />
                                </Form.Item>
                                <Form.Item label={"DIN"} name={"drin"}>
                                    <Input addonAfter={"din"} type={"number"} />
                                </Form.Item>
                                <Form.Item label={"Dicke"} name={"thickness"}>
                                    <Input addonAfter={"mm"} type={"number"} />
                                </Form.Item>
                            </>
                        }
                        {this.state.editArticle !== undefined && this.state.editArticle.type === ArticleTypes.GUSS &&
                            <>
                                <Form.Item label={"Dicke"} name={"thickness"}>
                                    <Input addonAfter={"mm"} />
                                </Form.Item>
                                <Form.Item label={"Preis"} name={"price"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                            </>
                        }
                        {this.state.editArticle !== undefined && this.state.editArticle.type === ArticleTypes.SPROSSE &&
                            <>
                                <Form.Item label={"Helima 18mm"} name={"helima18"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                                <Form.Item label={"Helima 26mm"} name={"helima26"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                                <Form.Item label={"Helima 45mm"} name={"helima45"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                                <Form.Item label={"Ziersprosse 10mm"} name={"zier10"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                                <Form.Item label={"Style-Sprosse 10mm"} name={"style10"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                                <Form.Item label={"Nostalgie-/Stecksprosse 8mm"} name={"nostalgie8"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                            </>
                        }
                        {this.state.editArticle !== undefined && this.state.editArticle.type === ArticleTypes.RABATTE &&
                            <>
                                <Form.Item label={"Dicke"} name={"thickness"}>
                                    <Input addonAfter={"mm"} />
                                </Form.Item>
                                <Form.Item label={"Preis"} name={"price"}>
                                    <Input addonAfter={"€"} />
                                </Form.Item>
                            </>
                        }
                    </Form>
                </Modal>
            }
        </>;
    }
}