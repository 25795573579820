/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 14.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from 'konva';
import {InnerFrame} from "../frame/innerFrame";
import {SprossenController} from "./sprossenController";
import {Colors} from "../config/default";
import {OuterFrame} from "../frame/outerFrame";
import {Utils} from "../utils/utils";
import {GlassOptions} from "./glassOptions";
import {Rect} from "../window/main";
import {ConfigController} from "../core/configController";
import {Handle} from "../frame/handle";
import {Lines} from "../frame/lines";
import {SprossenConfig} from "../core/data";
import {Spalte} from "../models/splitTypes";

export class Glass {
	id: string = '--nichts--';
	rect: Konva.Rect | undefined;

	outerFrame: OuterFrame | undefined;
	innerFrame: InnerFrame | undefined;
	handle: Handle | undefined;
	lines: Lines | undefined;

	sprossenController: SprossenController | undefined;

	constructor(
		public window: Rect,
		public options: GlassOptions = new GlassOptions(),
		public sprossenConfig: SprossenConfig | undefined = undefined
	) {
		this.create();
	}

	create() {
		this.id = `glass-${this.options.id}`;

		let startColor = this.options.isTrenner ? Colors.trennerFillStart : Colors.glassFillStart;
		let endColor = this.options.isTrenner ? Colors.trennerFillEnd : Colors.glassFillEnd;
		if ( this.options.glassType === 1 ) {
			startColor = Colors.glassOrnament;
			endColor = Colors.glassOrnament;
		}

		if ( this.window.rect === undefined ) return;
		this.rect = new Konva.Rect({
			x: this.window.rect.x() + this.options.x,
			y: this.window.rect.y() + this.options.y,
			width: this.options.width,
			height: this.options.height,
			fillLinearGradientStartPoint: { x: 0, y: 0 },
			fillLinearGradientEndPoint: { x: this.options.width, y: this.options.height },
			fillLinearGradientColorStops: [0, startColor, 1, endColor],
			id: this.id,
			listening: !this.window.isPreset
		});

		if ( this.window.group === undefined ) return;

		this.window.group.add(this.rect);

		if ( this.options.frameType === 0 ) { // Reperaturglass
			this.options.frameOuterOffset.top = 0;
			this.options.frameOuterOffset.bottom = 0;
			this.options.frameOuterOffset.left = 0;
			this.options.frameOuterOffset.right = 0;
			this.options.frameInnerOffset.top = 0;
			this.options.frameInnerOffset.bottom = 0;
			this.options.frameInnerOffset.left = 0;
			this.options.frameInnerOffset.right = 0;
		} else if ( this.options.frameType === 1 ) { // Festverglasung ohne Rahmen
			this.options.frameOuterOffset.top = 0;
			this.options.frameOuterOffset.bottom = 0;
			this.options.frameOuterOffset.left = 0;
			this.options.frameOuterOffset.right = 0;
			if ( !this.window.isPreset ) {
				this.options.frameInnerOffset.top = 21;
				this.options.frameInnerOffset.bottom = 21;
				this.options.frameInnerOffset.left = 21;
				this.options.frameInnerOffset.right = 21;
			}
		}

		if ( !this.options.isTrenner ) {
			this.outerFrame = new OuterFrame(this);
			this.innerFrame = new InnerFrame(this);
			this.handle = new Handle(this);
			this.lines = new Lines(this);
		}

		/*
		this.rect.on('mouseover', () => {
			if ( this.window.designer === undefined ) return;
			if ( this.window.designer.editor === undefined ) return;
			if ( !this.window.designer.editor.onDrag ) return;
			if ( this.window.designer.editor.dragType === -1 ) return;
			if ( this.window.designer.editor.dragType === 2 ) return;

			$(this.window.designer.editor.dragElement).addClass('ok');

			if ( this.window.designer.editor.dragType === 3 || this.window.designer.editor.dragType === 4 ) {
				this.window.designer.editor.dragSprossenGlass = this;
				const data = ConfigController.sprossen[this.window.designer.editor.dragID];

				const nSprosse = new Sprosse(this, 0, 0, 0, data);
				if ( nSprosse.rect !== undefined )
					nSprosse.rect.listening(false);

				nSprosse.isDragging = true;
				if ( this.window.designer.editor.dragType === 3 ) nSprosse.direction = 0;
				if ( this.window.designer.editor.dragType === 4 ) nSprosse.direction = 1;


				this.window.designer.editor.onSprossenNewAdd = nSprosse;
			}
			this.hover();
		});

		this.rect.on('mouseleave', () => {
			if ( this.window.designer === undefined ) return;
			if ( this.window.designer.editor === undefined ) return;
			if ( !this.window.designer.editor.onDrag ) return;
			if ( this.window.designer.editor.dragType === -1 ) return;
			if ( this.window.designer.editor.dragType === 2 ) return;


			$(this.window.designer.editor.dragElement).removeClass('ok');

			if ( this.window.designer.editor.dragType === 3 || this.window.designer.editor.dragType === 4 ) {
				if ( this.window.designer.editor.onSprossenNewAdd != null )
					this.window.designer.editor.onSprossenNewAdd.destroy();
			}

			this.highlight();
		});
		*/

		this.rect.on('mouseup touchend', () => {
			if ( this.window.designer === undefined ) return;
			if ( this.window.designer.editor === undefined ) return;
			if ( !this.window.designer.editor.onDrag ) return;
			if ( this.window.designer.editor.dragType === -1 ) return;
			if ( this.window.designer.editor.dragType === 2 ) return;

			const id = this.window.designer.editor.dragID;

			/**
			 * frage ab, ob hier ein bestimmter typ mehrere gläser benötigt!
			 */
			if ( this.window.designer.editor.dragType === 1) {
				if ( id === 13 || id === 14 ) {
					const modal = Utils.modal("Fehler");
					modal.setMessage("Dieser Beschlag braucht mind. 1 weiteres Glass um diesen hier Anwenden zu können.");
					modal.setType("success");
					modal.show();
					return;
				}
			}


			if ( !this.window.options.splitCustom ) {
				this.window.options.splitCustom = true;
				this.window.options.splitCustomConfig = ConfigController.splitTypes[id];
			}


			this.window.designer.editor.onSaving = true;
			if ( this.window.designer.editor.dragType === 1 ) { // Glass Frame Types
				if ( this.options.frameType <= 1 ) {
					if ( id > 1 ) {
						this.options.frameOuterOffset.top = 74;
						this.options.frameOuterOffset.bottom = 74;
						this.options.frameOuterOffset.left = 74;
						this.options.frameOuterOffset.right = 74;

						this.options.frameInnerOffset.top = 21;
						this.options.frameInnerOffset.bottom = 21;
						this.options.frameInnerOffset.left = 21;
						this.options.frameInnerOffset.right = 21;
					}
				}

				this.options.frameType = id; // unwirksam durch die neue änderungen.

				if ( this.window.options.splitCustomConfig !== undefined ) {
					for ( const row of this.window.options.splitCustomConfig.reihen ) {
						for ( const col of row.spalten ) {
							if ( col.glass ) {
								if ( col.glass.id === this.id ) {
									col.frameType = id;
								}
							}
						}
					}
				}
			} else if (this.window.designer.editor.dragType === 0) { // Presets
				this.window.options.splitType = id;
				this.window.options.splitCustomConfig = ConfigController.splitTypes[id];
			} else if ( this.window.designer.editor.dragType === 3 || this.window.designer.editor.dragType === 4 ) { //Sprossen / Placeholder
				if ( this.window.designer.editor.onSprossenNewAdd != null ) {
					if ( this.sprossenController === undefined ) return;
					this.sprossenController.sprossen.push(this.window.designer.editor.onSprossenNewAdd);
					this.window.designer.editor.onSprossenNewAdd = null;
					//this.window.designer.editor.onSprossenNewAdd.destroy();
				}
			}

			this.window.designer.updateZoom(1);
			if ( this.window.designer.stage === undefined ) return;
			this.window.designer.stage.absolutePosition({x: 0, y: 0});

			if ( this.window.designer.editor.dragType === 0) {
				this.window.glassConfig = undefined;
				for ( const window of this.window.designer.windows ) window.update();

			} else if ( this.window.designer.editor.dragType === 1 ) {
				for ( const window of this.window.designer.windows )
					for ( const glass of window.glass ) glass.update();

			} else if ( this.window.designer.editor.dragType === 3 || this.window.designer.editor.dragType === 4 ) {
				if ( this.sprossenController === undefined ) return;
				this.sprossenController.update();
			}

			setTimeout( () => {
				if ( this.window.designer === undefined ) return;
				if ( this.window.designer.editor === undefined ) return;
				this.window.designer.updateZoom();
				this.window.designer.editor.onSaving = false;
			}, 250);
		});

		this.sprossenController = new SprossenController(this);
	}

	destroy() {
		if ( this.rect === undefined ) return;
		this.rect.destroy();

		if ( this.outerFrame != null ) this.outerFrame.destroy();
		if ( this.innerFrame != null ) this.innerFrame.destroy();
		if ( this.handle != null ) this.handle.destroy();
		if ( this.lines != null ) this.lines.destroy();
		if ( this.sprossenController != null ) this.sprossenController.destroy();
	}

	update() {
		this.destroy();
		this.create();

		if ( this.sprossenController != null )
			this.sprossenController.update();
	}

	addGroup(object: any) {
		if ( this.window.group === undefined ) return;
		this.window.group.add(object);
	}

	refreshColors() {
		if ( this.rect === undefined ) return;

		let startColor = this.options.isTrenner ? Colors.trennerFillStart : Colors.glassFillStart;
		let endColor = this.options.isTrenner ? Colors.trennerFillEnd : Colors.glassFillEnd;

		if ( this.options.glassType === 1 ) {
			startColor = Colors.glassOrnament;
			endColor = Colors.glassOrnament;
		}

		this.rect.fillLinearGradientStartPoint({ y: 0, x: 0 });
		this.rect.fillLinearGradientEndPoint({ x: this.options.width, y: this.options.height });
		this.rect.fillLinearGradientColorStops([0, startColor, 1, endColor]);

		if ( this.outerFrame !== undefined ) {
			if ( this.outerFrame.frameTop === undefined ) return;
			if ( this.outerFrame.frameLeft === undefined ) return;
			if ( this.outerFrame.frameRight === undefined ) return;
			if ( this.outerFrame.frameBottom === undefined ) return;

			const frameColor = this.outerFrame.getFrameColor();
			this.outerFrame.frameTop.fill(frameColor);
			this.outerFrame.frameLeft.fill(frameColor);
			this.outerFrame.frameRight.fill(frameColor);
			this.outerFrame.frameBottom.fill(frameColor);
		}
		if ( this.innerFrame !== undefined ) {
			if ( this.innerFrame.frameInnerTop === undefined ) return;
			if ( this.innerFrame.frameInnerLeft === undefined ) return;
			if ( this.innerFrame.frameInnerRight === undefined ) return;
			if ( this.innerFrame.frameInnerBottom === undefined ) return;

			const frameColor = this.innerFrame.getFrameColor();
			this.innerFrame.frameInnerTop.fill(frameColor);
			this.innerFrame.frameInnerLeft.fill(frameColor);
			this.innerFrame.frameInnerRight.fill(frameColor);
			this.innerFrame.frameInnerBottom.fill(frameColor);
		}

		if ( this.sprossenController === undefined ) return;
		for ( const sprosse of this.sprossenController.sprossen ) {
			if ( sprosse.rect === undefined ) continue;
			sprosse.rect.fill("white");
		}
	}

	highlight() {
		if ( this.rect === undefined ) return;
		this.rect.fillLinearGradientColorStops([0, "grey", 1, "grey"]);
	}

	hover(color: string = "lightgreen") {
		if ( this.rect === undefined ) return;
		this.rect.fillLinearGradientColorStops([0, color, 1, color]);
	}

	getColumn(): Spalte | undefined {
		if ( this.window.designer === undefined ) return undefined;

		for ( const window of this.window.designer.windows ) {
			if ( window.options.splitCustomConfig === undefined ) continue;

			for ( const row of window.options.splitCustomConfig.reihen ) {
				for ( const col of row.spalten ) {
					if ( col.glass === undefined ) continue;
					if ( col.glass.id === this.id ) return col;
				}
			}
		}

		return undefined;
	}

	setGlassType(typeID: number) {
		this.window.setGlassType(this.id, typeID);
	}
}
