/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import axios from "axios";
import {API} from "../../core/API";
import {Button, message} from "antd";
import {Designer} from "../../designer";
import {WindowConfig} from "../../core/data";

interface iProps {
    designer: Designer;
    projectID: string | undefined;
    versionID: string | undefined;
}
interface iStates {
    price: number;
    checking: boolean;
}

export class PriceShow extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);
        this.state = {
            price: 0,
            checking: false
        }
    }

    onButton() {
        this.checkPrice();
    }

    componentDidMount() {
        this.props.designer.on('ready', () => {
            this.checkPrice();
        });
    }


    async checkPrice() {
        if ( this.state.checking ) return message.error('Warte bis Prüfung abgeschlossen ist!');

        const data = this.props.designer.getProjectData();
        console.log('price data: ', data);
        if ( data === undefined ) return;

        this.setState({ checking: true }, async () => {
            let price: number = 0;
            for ( const window of data.windows as WindowConfig[] ) {
                const api = await axios.post(API.URL + '/project/price', {
                    token: localStorage.getItem('token'),
                    width: window.option.width,
                    height: window.option.height,
                    type: 1,
                });

                if (api === undefined ) return message.error('Kann preis nicht berechnen!');
                if (api.data === undefined ) return message.error('Kann preis nicht berechnen!');
                if (api.data.errors ) return message.error('[PreisShow] ' + api.data.message);

                price += api.data.data;
            }

            setTimeout(() => {
                this.setState({price: price, checking: false});
            }, 1000);

        });
    }

    render() {
        return <Button
            type={"link"}
            loading={this.state.checking}
            onClick={this.onButton.bind(this)}
        >
            {this.state.price} €
        </Button>;
    }
}