/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 22.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import './config/glassSplitTypes';
import './config/frameTypes';
import './config/frameFlips';

import './core/data';
import './core/API';
import './core/configController';
import $ from "jquery";
import Konva from "konva";
import {Glass} from "./glass/main";
import {Rect, WindowMain} from "./window/main";
import {Editor} from "./editor/editor";
import {Debug} from "./utils/debug";
import {Tooltip} from 'bootstrap';
import {Project} from "./interfaces/project";
import {GlassConfig, SprossenConfig, WindowConfig} from "./core/data";
import {SumMetric} from "./metric/sumMetric";
import {MetricController} from "./metric/metricController";
import {DragDrop} from "./editor/dragDrop";
import {AddController} from "./window/addController";
import {IEvents, IEventsReact} from "./interfaces/events";
import {WindowOptions} from "./window/windowOptions";
import {GlassSplitType, Reihe, Spalte} from "./models/splitTypes";
import {ConfigController} from "./core/configController";


export class Designer {
	width = window.innerWidth;
	height = window.innerHeight;

    stage: Konva.Stage | undefined;
    layer: Konva.Layer | undefined;
    editor: Editor | undefined;
    dragDrop: DragDrop | undefined;
	currentScale = 1;

	//btns: Button[] = new Array<Button>(); // Todo: wofür brauch ich das? völlig unnötig

	//config = new ConfigController(this);
	windows: Rect[] = new Array<Rect>();

	debug: Debug | null | undefined;

	//_tempWindow: any = null;
	//modalCreateWindow: Modal | undefined;
	//modalCreateWindowCancel: JQuery<HTMLElement> | null | undefined;
	//modalCreateWindowCreate: JQuery<HTMLElement> | null | undefined;

	konvaContainer: HTMLElement | null | undefined;

	events: any[] = [];

	onStarting: boolean = true;

	sumMetric: SumMetric | undefined;
	metricController = new MetricController(this);
	config = new ConfigController(this);

	constructor(
		public containerID: string,
		public project: Project | undefined = undefined,
		public version: string | undefined = undefined,
		public historyID: string | undefined = undefined,
		public isExportContainer: boolean = false
	) {
		setTimeout(() => {
			this.start();
		}, 250);
	}

	on<K extends keyof IEvents>(eventName: K, listener: (...args: any) => void) {
		this.events.push({ name: eventName, func: listener});
	}

	emit<K extends keyof IEvents>(eventName: K, ...args: any[]): void {
		for ( const event of this.events ) {
			if ( event.name !== eventName ) continue;
			event.func(...args);
			console.log('emitEvent: ', event.name);
		}
	}

	onReact<K extends keyof IEventsReact>(eventName: K, listener: (...args: any) => void) {
		this.events.push({ name: eventName, func: listener});
	}

	emitReact<K extends keyof IEventsReact>(eventName: K, ...args: any[]): void {
		for ( const event of this.events ) {
			if ( event.name !== eventName ) continue;
			event.func(...args);
			console.log('emitEvent: ', event.name);
		}
	}

	async start() {
		this.konvaContainer = document.getElementById(this.containerID);
		if ( this.konvaContainer == null ) return console.log('cannot find konva container!');

		//console.log('designer width', this.konvaContainer.offsetWidth, 'height', this.konvaContainer.offsetHeight)

		console.log('Container width', this.konvaContainer.offsetWidth, 'height', this.konvaContainer.offsetHeight);

		this.stage = new Konva.Stage({
			container: this.containerID,
			width: this.konvaContainer.offsetWidth,
			height: this.konvaContainer.offsetHeight - 170,
			draggable: true,
		});
		this.layer = new Konva.Layer({scale: {x : this.currentScale, y: this.currentScale}});
		this.stage.add(this.layer);

		this.updateZoom(0);
		if ( !this.isExportContainer ) {
			this.dragDrop = new DragDrop(this);
			//this.editor = new Editor(this);
		}
		//console.log('[Designer] created');

		const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new Tooltip(tooltipTriggerEl)
		});

		this.debug = new Debug(this);

		$(window).on('resize', this.checkWindowSize.bind(this));



		//TODO: wenn project nicht null ist, dann bitte daten direkt laden.
		if ( this.project !== undefined ) {
			//console.log('start designer with project:');

			const version = this.project.versions.find( ( ver ) => this.project !== undefined && ver.id === this.version);
			if ( version === undefined ) return //console.log('kann nicht geladen werden: version nicht gefunden!');

			const projectData = version.histories.find( (history) => history.id === (!this.historyID ? version.activeHistoryID : this.historyID));
			if ( projectData === undefined ) return //console.log('kann projekt daten von version nicht finden');


			//console.log(projectData);
			try {
				if ( typeof(projectData.data) === "string" ) {
					projectData.data = JSON.parse(projectData.data);
					const wConfigs = projectData.data as Array<WindowConfig>;
					if ( wConfigs.length !== 0 ) {
						for ( const wConfig of wConfigs ) {
							let parent = undefined;
							if ( wConfig.option.parentID.length !== 0 ) parent = this.getWindowForConfig(wConfig.option.parentID);

							const nWindow = new AddController(this);
							nWindow.glassConfig = wConfig.glass;
							nWindow.options = wConfig.option;
							nWindow.parent = parent;

							//const window: WindowMain | boolean | undefined = await this.addWindow(parent, wConfig.option.direction, 0, wConfig.option.splitType, wConfig.option.isKopplung, wConfig.option, wConfig.glass);
							/*
							if ( window instanceof Rect ) {
								for ( const wGlassIndex in window.glass ) {
									const wGlass = window.glass[wGlassIndex];
									if ( wGlass !== undefined ) {
										if ( wGlass.sprossenController ) {
											const glassConfig = wConfig.glass[wGlassIndex];
											if ( glassConfig !== undefined && glassConfig.sprossen !== undefined ) {
												for ( const sprosseConfig of glassConfig.sprossen ) {
													const nSprosse = new Sprosse(
														wGlass,
														sprosseConfig.x,
														sprosseConfig.y,
														sprosseConfig.direction,
														sprosseConfig.thickness
													);
													wGlass.sprossenController.sprossen.push(nSprosse);
												}
											}
											window.update();
											//console.log('window updated because new sprosseConfig detected');
										}
									}
								}
							} else {
								//console.log('Window ist keine Instanceof WindowMain');
								//console.log(window);
							}*/

							nWindow.apply();
						}
					} else {
						// config = 0 ...
						const nWindow = new AddController(this);
						nWindow.apply();
					}
				}
			} catch ( error ) {
				// möglicherweiße ein Fehler in der JSON oder keine Daten vorhanden
				// daher muss dies ein neues Projekt sein
				const nWindow = new AddController(this);
				nWindow.apply();
			}
		}


		this.onStarting = false;

		if ( this.metricController !== undefined ) {
			//console.log('[METRIC CONTROLLER] update called from designer');
			this.metricController.update();
		}

		if ( this.sumMetric === undefined ) {
			this.sumMetric = new SumMetric(this);
			this.sumMetric.update();
		}

		this.updateZoom();


		if ( this.isExportContainer ) {
			for ( const label of this.sumMetric.labels ) {
				if ( label.id() !== "maximum" ) label.destroy();
			}
			for ( const metric of this.sumMetric.metrics ) {
				if ( metric.id() !== "maximum" ) metric.destroy();
			}
		}

		if ( this.isExportContainer ) {
			setTimeout( () => {
				if ( this.stage === undefined ) return;
				//this.stage.width(this.sumMetric.realMaxWidth);
				//this.stage.height(this.sumMetric.realMaxHeight);
				this.emit('ready');
			}, 500);
		} else {
			this.emit('ready');
		}
	}

	/*
	async addWindow(
		parent: WindowMain | undefined,
		direction: number = 0,
		windowType: number = 0,
		splitType: number = 0,
		isKopplung: boolean = false,
		windowOptions: WindowOptions | undefined = undefined,
		glassConfig: GlassConfig[] | undefined = undefined
	): Promise< boolean | WindowMain | undefined> {

		if ( this.stage === undefined ) return false;
		this.stage.absolutePosition({x: 0, y: 0});
		this.updateZoom(Default.zoom);

		if ( isNaN(windowType) ) windowType = 0;
		if ( isNaN(direction) ) direction = 0;

		if ( parent !== undefined ) {
			// Kurzfristig deaktiviert, weil es blockt.
			const children = this.getWindowChilds(parent);
			for ( const child of children ) {
				if ( child.direction === direction ) {
					Utils.toast('kann dort nicht platziert werden.');
					return;
				}
			}
		} else {
			if ( this.windows.length !== 0 ) {
				parent = this.windows[this.windows.length - 1];
			}
		}

		let targetObject: any = undefined;

		if ( windowOptions === undefined ) {
			windowOptions = new WindowOptions();
			windowOptions.splitType = splitType;
			windowOptions.direction = direction;
			windowOptions.splitCustom = true;
			windowOptions.splitCustomConfig = new GlassSplitType();
		}

		if ( windowType === 0 || windowType === 2 ) { //für normale rects und kopplungen
			if ( isKopplung ) {
				windowOptions.isKopplung = true;
				windowOptions.width = ConfigController.kopplung[splitType];
				windowOptions.height = ConfigController.kopplung[splitType];
			} else {
				/*if ( !windowOptions.customWidthHeight ) {
					windowOptions.width = ConfigController.splitTypes[splitType].windowWidth;
					windowOptions.height = ConfigController.splitTypes[splitType].windowHeight;
				}
			}

			if ( this.editor !== undefined )
				this.editor.onSaving = true;

			targetObject = new Rect(this, parent, direction, this.windows.length, windowOptions, false, false, glassConfig);
			this.windows.push(targetObject);

			if ( !this.onStarting ) this.emitEvent('save');

			setTimeout( () => {
				this.updateZoom(1);
				if ( parent !== undefined ) parent.update();
				this.updateZoom();
				if ( this.editor != null ) this.editor.onSaving = false;
			}, 100);
			return targetObject;
		}
		//if ( windowType === 1 ) targetObject = new Circle(this, parent, direction, this.windows.length, null);
		//if ( windowType === 1 ) targetObject = new Polygon(this, parent, windowDirection, this.windows.length);
	}
*/

	updateZoom(zoomScale = 0) {
		if ( !this.stage ) return;

		if ( zoomScale !== 0) {
			this.currentScale = zoomScale;
			return this.stage.scale({ x: zoomScale, y: zoomScale });
		}

		let posX = 0;
		let posY = 0;
		let width = 0;
		let height = 0;
		for ( const window of this.windows ) {
			if ( window === null ) continue;
			if ( window.parent != null ) {
				if ( window.options.direction === 0) width += (window.options.width + 100);
				if ( window.options.direction === 1) height += window.options.height;
				if ( window.options.direction === 2) {
					if ( window.rect !== undefined ) posY += window.rect.height();
					height += window.options.height;
				}
				continue;
			}

			height += window.options.height;
			width += window.options.width;
		}

		let widthProp = 1 / ( width / (window.innerWidth - ( !this.isExportContainer ? 900 - 200*this.windows.length : 100) ) );
		let heightProp = 1 / ( height / (window.innerHeight - ( !this.isExportContainer ? 350 : 100)) );

		let scaler = ( widthProp > heightProp ? heightProp- 0.2 : widthProp - 0.2);
		let posYProp = ( posY !== 0 ? (Number(posY) * Number(scaler)) : 0);

		this.currentScale = scaler;
		if ( this.stage !== undefined && !this.isExportContainer ) {
			this.stage.scale({x: scaler, y: scaler});
			this.stage.absolutePosition({x: posX, y: posYProp});
			//console.log(`[ZOOM] x: ${posX} y: ${posYProp} scale: ${scaler}`);
		}

		//console.log(widthProp, heightProp);

		if ( this.isExportContainer ) {
			if ( this.stage !== undefined ) {
				this.stage.absolutePosition({x: posX, y: posY + 200});
				this.stage.width(width + 600);
				this.stage.height(height + 1000);
			}
		}
	}

	getGlass(id: string): Glass | undefined {
		for ( const window of this.windows ) {
			for ( const glass of window.glass ) {
				if ( glass.rect === null ) continue;
				// @ts-ignore
				const idGlass = glass.rect.getId() as string;
				if ( id.includes(idGlass) ) {
					return glass;
				}
			}
		}
		return undefined;
	}

	getWindow(id: string): Rect | undefined {
		for ( const window of this.windows ) {
			if ( window.rect === null ) continue;
			// @ts-ignore
			const idWindow = window.rect.getId() as string;
			if ( id.includes(idWindow) ) return window;
		}
		return undefined;
	}

	getWindowForConfig(id: string): Rect | undefined {
		//console.log('DEBUG: lenght', this.windows.length);
		for ( const window of this.windows ) {
			if ( window.id.includes(id) ) return window;
		}
		return undefined;
	}

	getWindowChilds(targetWindow: WindowMain): WindowMain[] {
		const windows = [];
		for ( const window of this.windows ) {
			if ( window === null ) continue;

			if ( window.parent != null )
				if ( window.parent.id === targetWindow.id)
					windows.push(window);
		}
		return windows;
	}

	checkWindowSize() {
		if ( this.stage === undefined ) return;
		if ( this.konvaContainer === undefined || this.konvaContainer === null ) return;

		this.stage.width(this.konvaContainer.offsetWidth);
		this.stage.height(this.konvaContainer.offsetHeight);

		this.stage.absolutePosition({ x: 0, y: 0 });
	}

	getProjectData(): any {
		const data: any = {};
		if ( this.sumMetric === undefined ) return;

		data.width = this.sumMetric.realMaxWidth;
		data.height = this.sumMetric.realMaxHeight;
		data.windows = new Array<WindowConfig>();

		for ( const window of this.windows ) {
			const sData = new WindowConfig();

			sData.option = window.options;
			sData.glass = new Array<GlassConfig>();

			for ( const glass of window.glass ) {
				const nGlassData = new GlassConfig();
				nGlassData.option = glass.options;
				nGlassData.sprossen = [];

				if ( glass.sprossenController !== undefined ) {
					for ( const sprosse of glass.sprossenController.sprossen ) {
						const nSprossenConfig = new SprossenConfig();
						nSprossenConfig.x = sprosse.x;
						nSprossenConfig.y = sprosse.y;
						nSprossenConfig.thickness = sprosse.thickness;
						nSprossenConfig.direction = sprosse.direction;
						nGlassData.sprossen.push(nSprossenConfig);
					}
				}
				sData.glass.push(nGlassData);
			}

			// fix JSON Error:
			if ( sData.option.splitCustomConfig ) {
				for ( const row of sData.option.splitCustomConfig.reihen) {
					for ( const column of row.spalten ) {
						if ( column.glass !== undefined ) column.glass = undefined; // Veursacht den JSON Error "Circular"
					}
				}
			}

			data.windows.push(sData);
		}
		return data;
	}

	highlightWindow(wID: string) {
		if ( wID === undefined ) return;
		if ( this.stage === undefined ) return;
		if ( this.windows.length === 0 ) return;

		for ( const window of this.windows ) {
			if ( window.id === wID ) {
				window.highlight();
			}
		}

	}
}


