/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 17.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {GlassSplitType, Reihe, Spalte} from "../models/splitTypes";
import {GlassFrameType} from "../models/frameTypes";
import {WindowOptions} from "../window/windowOptions";
import {Rect} from "../window/main";
import {AddController} from "../window/addController";
import {FrameOuterOffset} from "../models/frameOuterOffset";


export class PresetController {
	width: number = 100;
	height: number = 100;
	stage: Konva.Stage | undefined;
	layer: Konva.Layer | undefined;

	preview: Rect | undefined;

	options: WindowOptions = new WindowOptions();

	constructor(
		public glassData: GlassSplitType | GlassFrameType | WindowOptions,
		public containerID: string,
		public splitTypeIndex: number,
		public noZoom: boolean = false
	) {

		if (this.glassData instanceof WindowOptions) {
			this.options = this.glassData;
		} else {
			this.options = new WindowOptions();
			this.options.width = this.width;
			this.options.height = this.height;
			this.options.splitType = this.splitTypeIndex;

			if (this.glassData instanceof GlassFrameType) {
				this.options.splitType = 0;
				this.options.splitCustom = true;
				this.options.splitCustomConfig = new GlassSplitType(this.glassData.label,
					[
						new Reihe([
							new Spalte(0, this.splitTypeIndex)
						])
					]
				)
			}

			if (this.glassData instanceof GlassSplitType) {
				this.options.splitCustomConfig = this.glassData;
				//this.options.width = this.glassData.windowWidth;
			}
		}

		this.options.outerFrameOffset = new FrameOuterOffset(5);
		if ( this.options.splitCustomConfig ) {
			for ( const row of this.options.splitCustomConfig.reihen ) {
				this.height+= 10;
				row.height /= 4;
				for ( const col of row.spalten ) {
					this.width += 10;
					col.frameOuterOffset = new FrameOuterOffset(5);
					col.frameInnerOffset = new FrameOuterOffset(3);
					if ( col.isTrenner ) {
						col.width = col.width / 3;
					}
				}
			}
		}

		this.stage = new Konva.Stage({
			width: this.width,
			height: this.height,
			container: this.containerID,
			listening: false
		});
		this.layer = new Konva.Layer({
			listening: false
		});
		this.stage.add(this.layer);
		//console.log('container created width:', this.stage.width(), 'height:', this.stage.height());


		this.create();
	}

	create() {
		//console.log('PresetController::create called');
		if ( this.stage === undefined ) return;

		const nWindow = new AddController(undefined);
		nWindow.options = this.options;
		nWindow.metrics = false;
		nWindow.isPreset = true;
		this.preview = nWindow.apply();
		if ( this.preview === undefined ) return;

		if ( this.layer === undefined ) return;
		if ( this.preview.group !== undefined ) this.layer.add(this.preview.group);
	}
}