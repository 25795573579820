/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import axios from "axios";
import {API} from "../../core/API";
import {
    Button,
    Col,
    Divider,
    Form,
    FormInstance,
    Input,
    MenuItemProps,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table
} from "antd";
import {useForm} from "antd/es/form/Form";
import MenuItem from "antd/lib/menu/MenuItem";
import {ColumnsType} from "antd/lib/table";
import {ColumnType} from "antd/lib/table/interface";
import {ModalSettingsVerglasung} from "../component/forms/modalSettingsVerglasung";
import {ModalSettingsTypes} from "../component/forms/modalSettingsTypes";


export function PagePrices() {

    const [ cSystemForm ] = useForm();
    const [ cVerglasungForm ] = useForm();
    const [ cTypesForm ] = useForm();
    return <PriceManager createSystemForm={cSystemForm} createTypesForm={cTypesForm} createVerglasungForm={cVerglasungForm}/>;
}


interface iProps {
    createSystemForm: FormInstance;
    createVerglasungForm: FormInstance;
    createTypesForm: FormInstance;
}

interface iStates {
    systeme: any[];
    verglasungen: any[];
    preise: any[];

    createSystem: boolean;
    creatingSystem: boolean;

    createVerglasung: boolean;
    creatingVerglasung: boolean;

    createTypes: boolean;
    creatingTypes: boolean;
}

export class PriceManager extends React.Component<iProps, iStates> {
    constructor(props: iProps) {
        super(props);

        this.state = {
            systeme: [],
            verglasungen: [],
            preise: [],

            createSystem: false,
            creatingSystem: false,

            createVerglasung: false,
            creatingVerglasung: false,

            createTypes: false,
            creatingTypes: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const api = await axios.post(API.URL + '/settings/preis', {
            token: localStorage.getItem('token')
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        console.log(api.data.data);
        this.setState({
            systeme: api.data.data.system,
            verglasungen: api.data.data.verglasung,
            preise: api.data.data.preise,
        });
    }

    colsSystem: ColumnType<any>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'system.name',
        },
        {
            title: 'Preisbildung',
            key: 'system.amount',
            render: (value, record) => (
                <>
                    { record.amount != 0 &&
                        <span>P: {record.amount}</span>
                    }
                    { record.percent != 0 &&
                        <span>{record.percent}%</span>
                    }
                    { record.amount == 0 && record.percent == 0 &&
                        <span>Nur Rasterpreis</span>
                    }
                </>
            ),
        },
        {
            title: 'Verglasung',
            render: (value, record) => (
                <>
                    <Select style={{width: '100%'}} defaultValue={record.verglasungID} onChange={(value) => {
                        this.onSelectionSystemChange(record.id, value)
                    }

                    }>
                        <Select.Option key={"0"}>Keine Angabe</Select.Option>
                        { this.state.verglasungen.map( ( verglasung ) => {
                            return <Select.Option key={verglasung.id}>
                                { verglasung.name } { verglasung.times } Fach Glas {verglasung.glas1}/{verglasung.glas2}/{verglasung.glas3}
                            </Select.Option>
                        })
                        }

                    </Select>
                </>
            )
        },
        {
            title: 'Aktionen',
            render: (value, record) => (
                <Space size={"small"}>
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-edit"></i>}
                    />
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-remove"></i>}
                    />
                </Space>
            )
        }
    ];

    colsVerglasung: ColumnType<any>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'verglasung.name',
        },
        {
            title: 'Fach',
            dataIndex: 'times',
            key: 'verglasung.times',
        },
        {
            title: 'Glas 1',
            dataIndex: 'glas1',
            key: 'verglasung.glas1',
        },
        {
            title: 'Glas 2',
            dataIndex: 'glas2',
            key: 'verglasung.glas2',
        },
        {
            title: 'Glas 3',
            dataIndex: 'glas3',
            key: 'verglasung.glas3',
        },
        {
            title: 'Aktionen',
            render: (value, record) => (
                <Space size={"small"}>
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-edit"></i>}
                    />
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-remove"></i>}
                    />
                </Space>
            )
        }
    ];

    getColumnVerglasung(systemID: string) {
        const system = this.state.systeme.find((sys) => sys.id === systemID);
        const verglasung = this.state.verglasungen.find( ( verglasung ) => verglasung.id === system.verglasungID);
        if ( verglasung !== undefined )
            return <span>{verglasung.name} {verglasung.times}-fach Glas {verglasung.glas1}/{verglasung.glas2}/{verglasung.glas3}</span>;
        return <span>---</span>;
    }

    getVerglasungInfo(systemID: string) {
        const system = this.state.systeme.find((sys) => sys.id === systemID);
        if ( system == undefined ) return 'Nichts';
        const verglasung = this.state.verglasungen.find( ( verglasung ) => verglasung.id === system.verglasungID);
        if ( verglasung !== undefined ) {
            return `${verglasung.name} ${verglasung.times} Glas ${verglasung.glas1}/${verglasung.glas2}/${verglasung.glas3}`
        }
        return 'nichts..';
    }

    getColumnSystem(systemID: string) {
        const system = this.state.systeme.find((sys) => sys.id === systemID);
        return <span>{system.name} </span>
    }

    colPreise: ColumnType<any>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Typ',
            dataIndex: 'typeID',
        },
        {
            title: 'System',
            render: (value, record) => (
                <Space size={"small"}>
                    {this.getColumnSystem(record.systemID) }
                </Space>
            )
        },
        {
            title: 'Verglasung',
            render: (value, record) => (
                <Space size={"small"}>
                    {this.getColumnVerglasung(record.systemID) }
                </Space>
            )
        },
        {
            title: 'Aktionen',
            render: (value, record) => (
                <Space size={"small"}>
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-edit"></i>}
                    />
                    <Button
                        type={"link"}
                        icon={<i className="fas fa-remove"></i>}
                    />
                </Space>
            )
        }
    ]

    showSystem() {
        this.setState({
            createSystem: true
        });
    }

    hideSystem() {
        this.setState({
            createSystem: false
        });
    }

    createSystem() {
        this.props.createSystemForm.submit();
    }

    onCreateSystem( values: any ) {
        if ( this.state.creatingSystem ) return;

        this.setState({creatingSystem: true}, async () => {

            const api = await axios.post(API.URL + '/settings/preis/system/add', {
                token: localStorage.getItem('token'),
                ...values
            });

            if ( api.data.errors ) {
                this.setState({creatingSystem: false});
                return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');
            }

            this.setState({creatingSystem: false });
        });
    }

    async onSelectionSystemChange( id: string, newValue: string ) {
        const api = await axios.post(API.URL + '/settings/preis/system/edit', {
            token: localStorage.getItem('token'),
            id: id,
            verglasungID: newValue
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');

        this.getData();
    }

    render() {
        return <>
            <Row justify={"end"} align={"middle"} style={{padding: 10}}>
                <Col>
                    Preis Testen:
                </Col>
                <Col>
                    <Input type="number" placeholder="Typ angeben"/>
                </Col>
                <Col>
                    <Input type="number" placeholder="Breite"/>
                </Col>
                <Col>
                    <Input type="number" placeholder="Höhe"/>
                </Col>
                <Col span={4} style={{marginLeft: 10}}>
                    <Select style={{width: '100%'}}>
                        {
                            this.state.systeme.map( ( system ) => {
                                return <Select.Option key={system.id}>
                                    { system.name } { this.getVerglasungInfo(system.verglasungID) }
                                </Select.Option>
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <Button>Testen</Button>
                </Col>
            </Row>

            <Row justify={"space-between"}>
                <Col flex={"auto"}>
                    <div className="site-layout-background">
                        <Row justify={"space-between"} align={"middle"} style={{padding: "20px 20px"}}>
                            <Col>
                                <strong>Systeme</strong>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    shape={"round"}
                                    onClick={this.showSystem.bind(this)}
                                >
                                    Hinzufügen
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            dataSource={this.state.systeme}
                            columns={this.colsSystem}
                            size={"small"}
                        />
                    </div>
                </Col>
                <Col flex={"auto"} style={{marginLeft: 10}}>
                    <div className="site-layout-background">
                        <Row justify={"space-between"} align={"middle"} style={{padding: "20px 20px"}}>
                            <Col>
                                <strong>Verglasungen</strong>
                            </Col>
                            <Col>
                                <ModalSettingsVerglasung parent={this} />
                            </Col>
                        </Row>
                        <Table
                            dataSource={this.state.verglasungen}
                            columns={this.colsVerglasung}
                            size={"small"}
                        />
                    </div>
                </Col>
            </Row>
            <div className="site-layout-background" style={{marginTop: 20}}>
                <Row justify={"space-between"} align={"middle"} style={{padding: "20px 20px"}}>
                    <Col>
                        <strong>Typenzuweisungen</strong>
                    </Col>
                    <Col>
                        <ModalSettingsTypes parent={this} />
                    </Col>
                </Row>
                <Table
                    dataSource={this.state.preise}
                    columns={this.colPreise}
                    size={"small"}
                />
            </div>

            <Modal
                title={"System Erstellen"}
                centered
                confirmLoading={this.state.creatingSystem}
                visible={this.state.createSystem}
                onOk={this.createSystem.bind(this)}
                onCancel={this.hideSystem.bind(this)}
            >
                <Form
                    layout={"vertical"}
                    form={this.props.createSystemForm}
                    onFinish={this.onCreateSystem.bind(this)}
                >
                    <Form.Item label={"Name"} name={"name"}>
                        <Input />
                    </Form.Item>
                    <div><strong>Preisbildung:</strong></div>
                    <Row justify={"space-between"} align={"middle"}>
                        <Col flex={"auto"} style={{marginRight: 10}}>
                            <Form.Item label={"Prozent"} name={"percent"}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col flex={"auto"} style={{marginLeft: 10}}>
                            <Form.Item label={"Pauschale"} name={"amount"}>
                                <Input type="float" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label={"Verglasung"} name={"verglasungID"}>
                        <Select style={{width: '100%'}}>
                            <Select.Option key={"0"}>Keine Angabe</Select.Option>
                            {
                                this.state.verglasungen.map( ( verglasung ) => {
                                    return <Select.Option key={verglasung.id}>
                                        { verglasung.name } { verglasung.times } Fach Glas {verglasung.glas1}/{verglasung.glas2}/{verglasung.glas3}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}