/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 22.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Designer} from "../../designer";
import {Rect} from "../../window/main";
import {Glass} from "../../glass/main";
import {SplitConfigure} from "./splitConfigure";
import {Button, Checkbox, Col, Divider, Drawer, Input, message, Row, Select, Space, Tabs} from "antd";
import {Sprosse} from "../../glass/sprosse";
import {glassTypesList} from "../../config/default";
import {options} from "pdfkit";
import {ConfigController} from "../../core/configController";


interface iProps {
    designer: Designer;
}
interface iStates {
    selected: Rect | undefined;
    selectedGlass: Glass | undefined;
    selectedSprosse: Sprosse | undefined;
    tabKey: string;

    widthError: string | undefined;
    frameError: string | undefined;
}

export class WindowSettings extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            selected: undefined,
            selectedGlass: undefined,
            selectedSprosse: undefined,
            tabKey: 'window',

            widthError: undefined,
            frameError: undefined,
        }
    }

    componentDidMount() {
        this.props.designer.on('onSelected', (selected: Rect | Glass | Sprosse | undefined) => {
            if ( selected === undefined ) {
                if ( this.state.selected !== undefined ) {
                    this.state.selected.refreshColors();
                }
                return this.setState({
                    selected: undefined,
                    selectedGlass: undefined,
                    selectedSprosse: undefined
                });
            }


            if ( this.state.selected !== undefined ) {
                this.state.selected.refreshColors();
            }


            if ( selected instanceof Glass ) {
                selected.window.highlightFrame();
                this.setState({
                    selected: selected.window,
                    selectedGlass: selected
                });
            } else if ( selected instanceof Rect ) {
                selected.highlightFrame();
                this.setState({
                    selected: selected,
                    selectedGlass: undefined
                });
            } else if ( selected instanceof Sprosse ) {
                this.setState({
                    selected: selected.glass.window,
                    selectedGlass: selected.glass,
                    selectedSprosse: selected
                });
            } else {
                this.setState({
                    selected: undefined,
                    selectedGlass: undefined,
                    selectedSprosse: undefined
                });
            }
        });

        if ( this.props.designer.editor === undefined ) return;
        if ( this.props.designer.editor.selected === undefined ) return;

        if ( this.props.designer.editor.selected instanceof Rect ) {
            this.setState({selected: this.props.designer.editor.selected});
        }
    }

    onWindowWidth( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selected === undefined ) return;

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        //console.log(value.target.value);
        let valNum = Number(value.target.value);


        let minWidth = window.options.outerFrameOffset.left + window.options.outerFrameOffset.right;
        let minHeight = window.options.outerFrameOffset.top + window.options.outerFrameOffset.bottom;
        if ( window.options.splitCustomConfig ) {
            for ( const row of window.options.splitCustomConfig.reihen ) {
                for ( const col of row.spalten ) {
                    minWidth+= col.frameOuterOffset.left;
                    minWidth+= col.frameOuterOffset.right;
                }
            }
        }

        if ( valNum <= minWidth ) {
            this.setState({
                widthError: 'zu klein'
            });
            valNum = minWidth;
        } else {
            if ( typeof(this.state.widthError) === 'string') {
                this.setState({
                    widthError: undefined
                });
            }
        }

        window.options.width = Number(valNum);
        //window.update();


        for ( const window of this.props.designer.windows ) {
            window.update();
        }

        this.props.designer.updateZoom();
    }

    onWindowHeight( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selected === undefined ) return;

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        window.options.height = Number(value.target.value);
        window.update();
        this.props.designer.updateZoom();
    }

    setFrameColor( value: string ) {
        if ( this.state.selected === undefined ) return;

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        window.options.frameColor = value;
        window.refreshColors();

        for ( const glass of window.glass ) {
            glass.refreshColors();
        }
        this.props.designer.updateZoom();
    }
    onFrameColor( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selected === undefined ) return;

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        window.options.frameColor = value.target.value;
        window.refreshColors();

        for ( const glass of window.glass ) {
            glass.refreshColors();
        }
        this.props.designer.updateZoom();
    }

    onFrameWidth( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selected === undefined ) return;

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        let valNum = Number(value.target.value);
        if ( valNum <= 0 ) valNum = 0;


        window.options.outerFrameOffset.top = valNum;
        window.options.outerFrameOffset.left = valNum;
        window.options.outerFrameOffset.right = valNum;
        window.options.outerFrameOffset.bottom = valNum;

        for ( const window of this.props.designer.windows ) {
            window.update();
        }
        this.props.designer.updateZoom();
    }

    getSprossePosition(): string {
        if ( this.state.selectedSprosse === undefined ) return "0";
        return ( this.state.selectedSprosse.direction === 0 ? this.state.selectedSprosse.x.toString() : this.state.selectedSprosse.y.toString() );
    }

    getSprosseThickness(): string {
        if ( this.state.selectedSprosse === undefined ) return "0";
        return this.state.selectedSprosse.thickness.toString();
    }

    setSprossePosition( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selectedGlass === undefined ) return;
        if ( this.state.selectedSprosse === undefined ) return;

        const glass = this.props.designer.getGlass(this.state.selectedGlass.id);
        if ( glass === undefined ) return;
        if ( glass.sprossenController === undefined ) return;

        const sprosse = glass.sprossenController.sprossen.find(
            (sprosse) =>
                this.state.selectedSprosse !== undefined &&
                sprosse.id === this.state.selectedSprosse.id
        );
        if ( sprosse === undefined ) return;

        if ( this.state.selectedSprosse.direction === 0 ) {
            this.state.selectedSprosse.y  = Number(value.target.value);
        } else {
            this.state.selectedSprosse.x  = Number(value.target.value);
        }

        this.state.selectedSprosse.update();
        this.props.designer.updateZoom();
    }

    setSprosseThickness( value: any ) {
        if ( value.target === undefined ) return;
        if ( this.state.selectedSprosse === undefined ) return;

        console.log(Number(value.target.value));
        //Todo:


        this.props.designer.updateZoom();
    }

    onCheckboxChange(type: string, value: boolean) {
        console.log(type, value);

        //Todo:
        if ( this.state.selected === undefined ) return;

        if ( type === 'hasLowerRamp') {
            this.state.selected.options.hasLowerRamp = value;
        } else if ( type === 'isConnectionProfile') {
            if ( !this.state.selected.options.isKopplung ) {
                return message.error('Objekt ist keine Kopplung!');
            }

            this.state.selected.options.isConnectionProfile = value;
        }

        const window = this.props.designer.getWindow(this.state.selected.id);
        if ( window === undefined ) return;

        window.options = this.state.selected.options;
        window.update();
        this.props.designer.updateZoom();
    }

    getGlassType(glassID: string) {
        if ( this.state.selected === undefined ) return "";
        if ( this.state.selectedGlass === undefined ) return "";
        if ( this.state.selected.options.splitCustomConfig === undefined ) return "";

        for ( const row of this.state.selected.options.splitCustomConfig.reihen ) {
            for ( const col of row.spalten ) {
                if ( col.glass === undefined ) continue;
                if ( col.glass.id === glassID ) {
                    return glassTypesList[col.glassType];
                }
            }
        }
    }

    onGlassTypeChange( event: any ) {
        console.log(event);

        if ( this.state.selected === undefined ) return;
        if ( this.state.selectedGlass === undefined ) return;
        if ( this.state.selected.options.splitCustomConfig === undefined ) return;

        for ( const row of this.state.selected.options.splitCustomConfig.reihen ) {
            for ( const col of row.spalten ) {
                if ( col.glass === undefined ) continue;
                if ( col.glass.id === this.state.selectedGlass.id ) {
                    col.glassType = Number(event);

                    this.state.selected.update();

                    if ( this.state.selected.designer === undefined ) return;
                    this.state.selected.designer.updateZoom();
                    return;
                }
            }
        }
    }

    getWidthErrorState() {
        if (this.state.widthError !== undefined) return "error"
        return undefined
    }

    getFrameErrorState() {
        if (this.state.widthError !== undefined) return "error"
        return undefined
    }

    render() {
        return <>
            <Drawer
                width={700}
                visible={!!this.state.selected}
                mask={false}
                onClose={() => this.props.designer.emit('onSelected', undefined)}
                title={"Objekt Einstellungen"}
                bodyStyle={{paddingTop: 0}}
            >
                { this.state.selected !== undefined &&
                    <>
                        <Tabs defaultActiveKey={this.state.tabKey}>
                            <Tabs.TabPane tab="Fenster" tabKey={"window"}>
                                <Row justify={"space-between"}>
                                    <Col span={11}>
                                        <Input
                                            type={"number"}
                                            addonBefore="Breite:"
                                            addonAfter={"mm"}
                                            status={this.getWidthErrorState()}
                                            defaultValue={this.state.selected.options.width}
                                            onChange={this.onWindowWidth.bind(this)}
                                        />
                                    </Col>
                                    <Col span={11}>
                                        <Input
                                            type={"number"}
                                            addonBefore="Höhe:"
                                            addonAfter={"mm"}
                                            defaultValue={this.state.selected.options.height}
                                            onChange={this.onWindowHeight.bind(this)}
                                        />
                                    </Col>
                                </Row>
                                <Row justify={"space-between"} style={{marginTop: 10}}>
                                    <Col span={11}>
                                        <Select
                                            defaultValue={this.state.selected.options.frameColor}
                                            onChange={(val) => this.setFrameColor(val)}
                                            style={{width: '100%'}}
                                        >
                                            {
                                                ConfigController.colorsWAL.map((color, index) => {
                                                    return <Select.Option key={color.HEX}>{color.Label}</Select.Option>
                                                })
                                            }
                                        </Select>
                                        <Input
                                            type={"color"}
                                            addonBefore="Rahmenfarbe:"
                                            defaultValue={this.state.selected.options.frameColor}
                                            onChange={this.onFrameColor.bind(this)}
                                        />
                                    </Col>
                                    <Col span={11}>
                                        <Input
                                            type={"number"}
                                            addonBefore="Rahmenbreite:"
                                            addonAfter={"mm"}
                                            defaultValue={this.state.selected.options.outerFrameOffset.top}
                                            onChange={this.onFrameWidth.bind(this)}
                                        />
                                    </Col>
                                </Row>

                                <SplitConfigure designer={this.props.designer} splitConfig={this.state.selected.options}/>
                                <Divider />
                                <div style={{padding: 10}}>
                                    <div>Sonstiges:</div>
                                    <Checkbox
                                        onChange={(val) => this.onCheckboxChange('isConnectionProfile', val.target.checked)}
                                        value={this.state.selected.options.isConnectionProfile}
                                        disabled={!this.state.selected.options.isKopplung}
                                    /> Verbundsprofil
                                    <Checkbox
                                        onChange={(val) => this.onCheckboxChange('hasLowerRamp', val.target.checked)}
                                        value={this.state.selected.options.hasLowerRamp}/> Niedrige schwelle
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Glass" key="glass" disabled={!this.state.selectedGlass}>
                                { this.state.selectedGlass !== undefined &&
                                    <>
                                        <Row justify={"space-between"}>
                                            <Col span={11}>
                                                <Input
                                                    disabled
                                                    addonBefore="ID:"
                                                    defaultValue={this.state.selectedGlass.id}
                                                    onChange={this.onWindowWidth.bind(this)}
                                                />
                                            </Col>
                                            <Col span={11}>
                                                <Select
                                                    style={{width: '100%' }}
                                                    defaultValue={this.getGlassType(this.state.selectedGlass.id)}
                                                    onChange={this.onGlassTypeChange.bind(this)}
                                                    value={this.getGlassType(this.state.selectedGlass.id)}
                                                >
                                                    {
                                                        glassTypesList.map((type, index) => {
                                                            return <Select.Option key={index}>{type}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Sprosse" key="sprosse" disabled={!this.state.selectedSprosse}>
                                <Row justify={"space-between"}>
                                    <Col span={11}>
                                        <Input
                                            addonBefore="Position:"
                                            type={"number"}
                                            addonAfter={"mm"}
                                            defaultValue={this.getSprossePosition()}
                                            onChange={this.setSprossePosition.bind(this)}
                                        />
                                    </Col>
                                    <Col span={11}>
                                        <Input
                                            addonBefore="Dicke:"
                                            addonAfter={"mm"}
                                            type={"number"}
                                            defaultValue={this.getSprosseThickness()}
                                            onChange={this.setSprosseThickness.bind(this)}
                                        />
                                    </Col>
                                </Row>
                                <Divider />
                            </Tabs.TabPane>
                        </Tabs>
                    </>
                }
            </Drawer>

        </>;
    }

}