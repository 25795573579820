/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, Form, FormInstance, Input, message, Modal, Popover} from "antd";
import {Designer} from "../../designer";
import {useForm} from "antd/es/form/Form";
import axios from "axios";
import {API} from "../../core/API";
import {DesignerController} from "../designer/designer";


export function VersionCopy(props: any) {
    const [ createCopy ] = useForm();
    return <VersionCopyComponent parent={props.parent} designer={props.designer} form={createCopy} projectID={props.projectID}/>
}


interface iProps {
    parent: DesignerController;
    form: FormInstance;
    designer: Designer;
    projectID: string;
}

interface iStates {
    open: boolean;
}

class VersionCopyComponent extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            open: false
        }
    }

    getCopyInfo() {
        return (
            <div>
                <p>Um die Aktuelle Version in eine neue ID & Bezeichnung zu speichern.</p>
            </div>
        );
    }

    async onFormFinish( values: any ) {
        //console.log(values);

        try {
            const api = await axios.post(API.URL + '/project/version/create', {
                token: localStorage.getItem('token'),
                projectID: this.props.projectID,
                data: this.props.designer.getProjectData().windows,
                ...values
            });

            if ( api === undefined ) return message.error('API-Fehler 2');
            if ( api.data === undefined ) return message.error('API-Fehler 3');
            if ( api.data.errors ) return message.error((api.data.message ? api.data.message : 'Unbekannter API Fehler'));


            message.success('Project erfolgreich erstellt');

            this.setState({open: false});

            this.props.parent.refreshProjectData(api.data.data);
        } catch ( error ) {
            //console.log('API Error:', error);
            message.error('API-Fehler!');
        }

    }

    onModalCancel() {
        this.setState({open: false});
    }

    onModalOk() {
        this.props.form.submit();
    }

    modalOpen() {
        this.setState({open: true});
    }

    render() {
        return <>
            <Popover content={this.getCopyInfo()} title={"Versionskopierer"}>
                <Button type={"link"} icon={<i className="fa-solid fa-copy"></i>} onClick={this.modalOpen.bind(this)}></Button>
            </Popover>
            <Modal
                title={"Version kopieren"}
                visible={this.state.open}
                onCancel={this.onModalCancel.bind(this)}
                onOk={this.onModalOk.bind(this)}
            >
                <Form form={this.props.form} onFinish={this.onFormFinish.bind(this)}>
                    <Form.Item name={"vorgang"} label={"ID"}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={"name"} label={"Bezeichnung"} rules={[{ required: true, message: 'Bezeichnung muss eingetragen werden.' }]}>
                        <Input />
                    </Form.Item>
                </Form>

            </Modal>
        </>;
    }
}