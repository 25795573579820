/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import $ from "jquery";
import {Glass} from "./main";


export class Sprosse {
	id: string | undefined;
	rect: Konva.Rect | undefined;

	arrow: Konva.Arrow | undefined;
	arrowLabel: Konva.Text | undefined;

	isDragging: boolean = false;
	leavedArea: boolean = false;


	constructor(
		public glass: Glass,
		public x: number = 0,
		public y: number = 0,
		public direction: number = 0,
		public thickness: number = 21
	) {
		if ( this.glass.sprossenController === undefined ) return;
		this.id = this.glass.id + "_sprosse_" + this.glass.sprossenController.sprossen.length;
		this.create();
	}

	create() {
		this.rect = new Konva.Rect({
			x: this.x,
			y: this.y,
			width: 0,
			height: 0,
			fill: 'white',
			stroke: 'black',
			strokeWidth: 1,
			id: this.id
		});

		if ( this.glass.rect === undefined ) return;

		if ( this.direction === 0 ) {
			this.rect.x(this.x);
			this.rect.height(this.thickness);
			this.rect.width(
				this.glass.rect.width() -
				this.glass.options.frameOuterOffset.right -
				this.glass.options.frameInnerOffset.right -
				this.glass.options.frameOuterOffset.left -
				this.glass.options.frameInnerOffset.left
			);

		} else if ( this.direction === 1 ) {
			this.rect.y(this.y);
			this.rect.width(this.thickness);
			this.rect.height(
				this.glass.rect.height() -
				this.glass.options.frameOuterOffset.bottom -
				this.glass.options.frameInnerOffset.bottom -
				this.glass.options.frameOuterOffset.top -
				this.glass.options.frameInnerOffset.top
			);
		}

		if ( this.glass.window === undefined ) return;
		if ( this.glass.window.group === undefined ) return;

		this.glass.window.group.add(this.rect);

		this.rect.on('dblclick', this.onDoubleClick.bind(this));
		this.glass.rect.on('mouseleave', this.onLeaveGlass.bind(this));
		this.glass.rect.on('mouseenter', this.onEnterGlass.bind(this));

		// @ts-ignore
		$(document).on('mousemove', this.onMove.bind(this));
		$(document).on('mouseup', this.onMouseUp.bind(this));
	}

	destroy() {
		if ( this.rect != null ) this.rect.destroy();

		if ( this.arrow != null ) this.arrow.destroy();
		if ( this.arrowLabel != null ) this.arrowLabel.destroy();
	}

	update() {
		this.destroy();
		this.create();
	}

	onMove(event: MouseEvent) {
		if ( !this.isDragging ) return;
		if ( this.leavedArea ) return;

		if ( this.glass.window === undefined ) return;
		if ( this.glass.window.group === undefined ) return;
		if ( this.glass.rect === undefined ) return;

		const pos = this.glass.window.group.getRelativePointerPosition();

		if ( this.arrow == null ) {
			////console.log('[Sprosse.ts] create arrowLabel!');
			this.arrow = new Konva.Arrow({
				x: 0,
				y: 0,
				points: [0, 0, 0, 0],
				fill: 'black',
				stroke: 'black',
				pointerLength: 20,
				pointerWidth: 20,
				strokeWidth: 4,
				listening: false,
			});
			this.glass.window.addGroup(this.arrow);
		}
		if ( this.arrowLabel == null ) {
			this.arrowLabel = new Konva.Text({
				x: 0,
				y: 0,
				fontSize: 100,
				fontFamily: 'Calibri',
				fill: 'green',
				text: `0 mm`,
				listening: false,
			});
			this.glass.window.addGroup(this.arrowLabel);
		}

		if ( this.direction === 0 ) {
			if ( this.rect === undefined ) return;

			this.y = Number(pos.y.toFixed(0));
			this.rect.y(Number(pos.y.toFixed(0)));
			this.rect.x(
				this.glass.rect.x() +
				this.glass.options.frameOuterOffset.left +
				this.glass.options.frameInnerOffset.left
			);
			this.arrow.points([pos.x, 0, pos.x, pos.y]);
			this.arrowLabel.x(pos.x + 10);
			this.arrowLabel.y(pos.y / 2);
			const distance = (
				pos.y -
				this.glass.window.options.outerFrameOffset.left -
				this.glass.options.frameOuterOffset.left -
				this.glass.options.frameInnerOffset.left
			);
			this.x = this.rect.x();
			this.rect.height(this.thickness);
			this.rect.width(
				this.glass.rect.width() -
				this.glass.options.frameOuterOffset.right -
				this.glass.options.frameInnerOffset.right -
				this.glass.options.frameOuterOffset.left -
				this.glass.options.frameInnerOffset.left
			);
			this.arrowLabel.text(`${distance.toFixed(0)} mm`);
		} else if ( this.direction === 1 ) {
			if ( this.rect === undefined ) return;
			this.x = Number(pos.x.toFixed(0));
			this.rect.x(Number(pos.x.toFixed(0)));
			this.rect.y(
				this.glass.rect.y() +
				this.glass.options.frameOuterOffset.top +
				this.glass.options.frameInnerOffset.top
			);

			this.arrow.points([0, pos.y, pos.x, pos.y]);
			this.arrowLabel.x(pos.x / 2);
			this.arrowLabel.y(pos.y + 10);
			const distance = (
				pos.x -
				this.glass.window.options.outerFrameOffset.left -
				this.glass.options.frameOuterOffset.left -
				this.glass.options.frameInnerOffset.left
			);
			this.y = this.rect.y();
			this.rect.width(this.thickness);
			this.rect.height(
				this.glass.rect.height() -
				this.glass.options.frameOuterOffset.bottom -
				this.glass.options.frameInnerOffset.bottom -
				this.glass.options.frameOuterOffset.top -
				this.glass.options.frameInnerOffset.top
			);
			this.arrowLabel.text(`${distance.toFixed(0)} mm`);
		}
	}

	onDoubleClick() {
		if ( this.isDragging ) return;

		console.log('double tapped on:', this);
		if ( this.rect === undefined ) return;
		this.rect.listening(false);
		setTimeout( () => {
			this.isDragging = true;
		}, 150);
	}

	onLeaveGlass() {
		if ( !this.isDragging ) return;

		this.leavedArea = true;
	}

	onEnterGlass() {
		if ( !this.isDragging ) return;

		this.leavedArea = false;
	}

	onMouseUp() {
		if ( !this.isDragging ) return;

		if ( this.arrow !== undefined ) {
			this.arrow.destroy();
			this.arrow = undefined;
		}

		if ( this.arrowLabel !== undefined ) {
			this.arrowLabel.destroy();
			this.arrowLabel = undefined;
		}

		this.isDragging = false;

		if ( this.rect === undefined ) return;

		this.rect.listening(true);
		this.x = this.rect.x();
		this.y = this.rect.y();
	}

	refreshColors() {
		if ( this.rect === undefined ) return;
		this.rect.fill("white");
	}
}