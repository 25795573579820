/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import { TestDesigner } from "./testDesigner";
import { Utils } from "./utils/utils";

export class Point {
    circle: Konva.Circle | undefined;

    constructor(
        public x: number,
        public y: number,
        public color: string = "black"
    ) {}
}

export class Divider {
    object: Konva.Line | undefined;

    constructor(
        public point1: Point,
        public point2: Point,
        public isVertical: boolean = true
    ) {
    }
}

export class WindowObject {
    id: string;
    points: Point[] = [];

    rect: Konva.Line | undefined;
    frameRect: Konva.Line[] = [];

    circles: Konva.Circle[] = [];

    group: Konva.Group = new Konva.Group();

    glasses: Konva.Line[] = [];
    divider: Divider[] = [];
    debugDivider: Konva.Text[] = [];

    constructor(
        public designer: TestDesigner,
        public type: string,
        public x: number,
        public y: number
    ) {
        this.id = Utils.generateID();
    }

    create() {
        this.rect = new Konva.Line({
            id: this.id,
            x: this.x,
            y: this.y,
            points: this.pointsGenerate(),
            stroke: 'black',
            strokeWidth: 5
        });

        this.group.add(this.rect);
        this.designer.group.add(this.group);

        this.generateCircles();
        this.generateFrames();
    }

    generateCircles() {
        for ( const point of this.points ) {
            if ( point.circle !== undefined ) {
                point.circle.x(this.x + point.x);
                point.circle.y(this.y + point.y);
            } else {
                point.circle = new Konva.Circle({
                    id: this.id + '_circle_' + this.circles.length,
                    x: this.x + point.x,
                    y: this.y + point.y,
                    radius: 20,
                    fill: 'red'
                });
                this.circles.push(point.circle);
                this.group.add(point.circle);
            }
        }
    }

    generateGlasses() {
        //Todo: prüfe auf divider dann trennung und neues glass

        for ( const point of this.points ) {

        }
    }

    generateFrames() {
        if ( this.rect === undefined ) return;


        const points: Point[] = [];
        for ( const pIndex in this.points ) {
            if ( Number(pIndex) === this.points.length - 1 ) {
                //is last one ...
            }


            const point = this.points[pIndex];
            let nextPoint = this.points[Number(pIndex) + 1];
            let beforePoint = this.points[Number(pIndex) - 1];

            if ( beforePoint === undefined ) beforePoint = this.points[this.points.length - 1]; // wenn undefined: wird letzter punkt genommen.
            if ( nextPoint == undefined ) nextPoint = this.points[0]; // wenn undefined: wird erster punkt genommen.


            let fromY: number = point.y;
            let fromX: number = point.x;
            let toY: number = nextPoint.y;
            let toX: number = nextPoint.x;

            let beforePointRight: boolean = false;
            let beforePointAbove: boolean = false;
            let pointIsRight: boolean = false;
            let pointIsAbove: boolean = false;

            let pointIsAboveSame: boolean = false;
            let pointIsRightSame: boolean = false;

            // prüfe ganze booleans:
            if ( beforePoint.x > point.x ) {
                beforePointRight = true;
            }
            if ( beforePoint.y > point.y ) {
                beforePointAbove = true;
            }

            if ( point.y > nextPoint.y ) {
                pointIsAbove = true;
            } else if ( point.y < nextPoint.y ) {
                pointIsAbove = false;
            } else {
                pointIsAboveSame = true;
            }
            if ( point.x > nextPoint.x ) {
                pointIsRight = true;
            } else if ( point.x < nextPoint.x ) {
                pointIsRight = false;
            } else {
                pointIsRightSame = true;
            }


            let targetColor: string = "green";
            if ( pointIsRightSame && pointIsAboveSame ) {
                fromX += 20;
                fromY += 20;
                toX += 20;
                toY += 20;
            } else if (!pointIsRightSame && pointIsAboveSame) {
                if ( pointIsRight && pointIsAbove ) {
                    targetColor = "lightgreen";
                } else if ( pointIsRight && !pointIsAbove) {
                    fromX += 40;
                    fromY -= 20;
                    toX -= 40;
                    toY -= 20;
                    targetColor = "darkgreen";
                } else if ( !pointIsRight && pointIsAbove) {
                    targetColor = "yellow";
                    fromX -= 40;
                    fromY += 40;
                    toX += 40;
                    toY += 40;
                } else if ( !pointIsRight && !pointIsAbove) {
                    fromX -= 40;
                    fromY += 40;
                    toX += 40;
                    toY += 40;
                    targetColor = "lightyellow";
                }
            } else if (!pointIsRightSame && !pointIsAboveSame) {
                if ( pointIsRight && pointIsAbove ) {
                    targetColor = "darkyellow";

                } else if ( pointIsRight && !pointIsAbove) {
                    targetColor = "blue";

                } else if ( !pointIsRight && pointIsAbove) {
                    targetColor = "lightblue";

                } else if ( !pointIsRight && !pointIsAbove) {
                    targetColor = "darkblue";
                    fromX += 40;
                    fromY -= 20;
                    toX -= 40;
                    toY -= 20;
                }
            } else if (pointIsRightSame && !pointIsAboveSame) {
                if ( pointIsRight && pointIsAbove ) {
                    targetColor = "red";

                } else if ( pointIsRight && !pointIsAbove) {
                    targetColor = "darkred";

                } else if ( !pointIsRight && pointIsAbove) {
                    targetColor = "pink";
                    fromX += 20;
                    fromY -= 20;
                    toX += 20;
                    toY += 20;
                } else if ( !pointIsRight && !pointIsAbove) {
                    fromX -= 40;
                    fromY -= 20;
                    toX -= 40;
                    toY += 40;
                    targetColor = "pink";
                }
            } else {
                targetColor = "gray";
            }

            points.push(new Point(fromX, fromY, targetColor));
            points.push(new Point(toX, toY, targetColor));
        }

        if ( this.frameRect !== undefined ) {
            for ( const frame of this.frameRect ) {
                frame.destroy();
            }
        }

        for ( let i = 0; i < points.length; i++ ) {
            let current = points[i];
            let next = points[(i+1)];

            if ( next === undefined ) continue;

            const rectLine = new Konva.Line({
                x: this.rect.x(),
                y: this.rect.y(),
                points: [current.x, current.y, next.x, next.y],
                stroke: current.color,
                strokeWidth: 5
            });

            console.log('create rect line', i, current, next);


            this.frameRect.push(rectLine);
            this.designer.group.add(rectLine);
        }

    }

    destroy() {

    }

    update() {
        if ( this.rect === undefined ) return;

        this.generateCircles();
        this.rect.points(this.pointsGenerate());

        //console.log(this.points);

        for ( const dIndex in this.divider ) {
            const divid = this.divider[dIndex];

            if ( divid.object !== undefined ) divid.object.id("currentObject");


            const detection = this.detactionTest(divid.point1, divid.point2);
            //console.log('detection return:', detection);


            if ( divid.object === undefined ) {
                divid.object = new Konva.Line({
                    id: 'divider',
                    x: 0,
                    y: 0,
                    points: [divid.point1.x, detection[0], divid.point1.x, detection[1]],
                    stroke: 'black',
                    strokeWidth: 5
                });

                this.group.add(divid.object);
            } else {
                divid.object.id("divider");
                divid.object.x(0);
                divid.object.y(0);
                divid.object.points([divid.point1.x, detection[0], divid.point1.x, detection[1]]);
            }
        }

        this.generateFrames();
    }

    pointsGenerate(): number[] {
        let points: number[] = [];

        for ( const coord of this.points ) {
            points.push(coord.x);
            points.push(coord.y);
        }
        return points;
    }

    pointsSet( points: Point[]): this {
        this.points = points;
        return this;
    }

    pointSet( index: number, point: Point ) {
        this.points[index] = point;
        this.update();
    }

    pointAdd( point: Point ) {

    }

    pointUpdate( point: Point ) {

    }

    detactionTest(start: Point, direction: Point): number[] {
        let foundEnd = 0;
        let foundStart = 0;
        const distance = start.x - direction.x;
        if ( distance >= 100 || distance <= -100 ) {
            foundStart = this.startWhile(new Point(start.x, start.y + 10), false, false, true).y;
            //console.log(foundStart + 1);
            foundEnd = this.startWhile(new Point(start.x, (foundStart + 3)), true, false, true).y;
            //console.log(foundStart, foundEnd);
            if (
                foundStart < 999 && foundStart > -999 &&
                foundEnd < 999 && foundEnd > -999
            ) {
                start.y = foundStart;
            }
        } else {
            foundStart = this.startWhile(start, false, true, false).x;
            foundEnd = this.startWhile(start, true, true, false).x;
        }

        return [foundStart, foundEnd];
    }

    startWhile(current: Point, isAdd: boolean = true, useX: boolean = true, useY: boolean = false ): Point {
        const stepPointer: Point = new Point(current.x, current.y);
        const finished: boolean = false;

        if ( useX ) stepPointer.y += 5;
        if ( useY ) stepPointer.x += 5;

        while (!finished) {
            if ( this.designer === undefined ) break;
            if ( this.designer.stage === undefined ) break;

            if ( isAdd ) {
                if ( useX ) stepPointer.x += 1;
                if ( useY ) stepPointer.y += 1;
            } else {
                if ( useX ) stepPointer.x -= 1;
                if ( useY ) stepPointer.y -= 1;
            }


            const object = this.designer.stage.getIntersection({
                x: stepPointer.x,
                y: stepPointer.y
            });

            ////console.log(stepPointer, object);

            if ( object !== null ) {
                if ( object.id() !== "currentObject" ) {
                    break;
                }
            }

            if ( stepPointer.x > 1000 || stepPointer.x < -1000 ) break;
            if ( stepPointer.y > 1000 || stepPointer.y < -1000 ) break;
        }


        return stepPointer;
    }

    isCrossing(currPoint: Point, point1: Point, point2: Point): boolean {
        let dxc = 0;
        let dyc = 0;

        if ( currPoint.x < point1.x ) {
            //dxc = point1.x - currPoint.x;
            dyc = point1.y - currPoint.y;
        } else {
            //dxc = currPoint.x - point1.x;
            dyc = currPoint.y - point1.y;
        }

        dxc = currPoint.x;

        let dxl = 0;
        let dyl = 0;

        if ( point2.x > point1.x ) {
            //dxl = point2.x - point1.x;
            dyl = point2.y - point1.y;
        } else {
            //dxl = point1.x - point2.x;
            dyl = point1.y - point2.y;
        }

        const cross = dxc * dyl - dyc * dxl;

        //console.log(cross, dxl, dyl, dxc, dyc);

        if (cross != 0) {
            return false;
        } else {
            return true;
        }
    }
}