/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Colors} from "../config/default";
import {Glass} from "../glass/main";
import {ConfigController} from "../core/configController";
import Rect = Konva.Rect;


export class Handle {
	rect: Rect | undefined;
	rect2: Rect | undefined;


	constructor(public glass: Glass) {
		//console.log('[Handle] loaded');
		if ( this.glass.window.isPreset ) return;
		this.create();
	}

	create() {
		if ( this.glass.rect === undefined ) return;
		const rect = this.glass.rect.getClientRect();
		const type = this.glass.options.frameType;

		//console.log('[Handle] create', type);

		if ( type === -1 ) return null;


		const glassType = ConfigController.frameTypes[type];
		if ( glassType === undefined ) return ////console.log('[Handle] error: no glassType found!');
		if ( glassType.handle === undefined ) return ////console.log('[Handle] no handle declared', glassType);

		const position = glassType.handle(rect, this.glass.options.frameOuterOffset);
		if ( position == null ) return;

		this.generate(position.x, position.y, glassType.handleDirection);
	}

	generate(x: number, y: number, direction = 0) {
		//console.log('[Handle] generate', x, y, direction);
		const opts = this.glass.options;

		let x2 = x;
		let y2 = y;
		let width = 0;
		let height = 0;
		let width2 = 0;
		let height2 = 0;

		if ( direction === 0 ) { // nach Unten
			x = x - opts.frameOuterOffset.top / 2 + 12;
			y = y - opts.frameOuterOffset.top / 2;
			width = 30;
			height = 70;
			x2 = x2 - opts.frameOuterOffset.top / 2 + 8 + 12;
			y2 = y2 - opts.frameOuterOffset.top / 2 + 30;
			width2 = 15;
			height2 = 120;
		} else if ( direction === 1 ) { // nach Rechts
			x = x - opts.frameOuterOffset.right / 2 + 12;
			y = y - opts.frameOuterOffset.right / 2 + 5;
			width = 70;
			height = 30;
			x2 = x2 - opts.frameOuterOffset.right / 2 + 40;
			y2 = y2 - opts.frameOuterOffset.right / 2 + 13;
			width2 = 120;
			height2 = 15;
		} else if ( direction === 2 ) { // nach Oben
			x = x - opts.frameOuterOffset.top / 2 + 12;
			y = y - opts.frameOuterOffset.top / 2;
			width = 30;
			height = 70;
			x2 = x2 - opts.frameOuterOffset.top / 2 + 8 + 12;
			y2 = y2 - opts.frameOuterOffset.top / 2 - 60;
			width2 = 15;
			height2 = 120;
		} else if ( direction === 3 ) { // weiß nicht mehr ...
			x = x - opts.frameOuterOffset.bottom / 2 + 12;
			y = y - opts.frameOuterOffset.bottom / 2 + 5;
			width = 70;
			height = 30;
			x2 = x2 - opts.frameOuterOffset.bottom / 2 - 40;
			y2 = y2 - opts.frameOuterOffset.bottom / 2 + 13;
			width2 = 120;
			height2 = 15;
		}


		this.rect = new Konva.Rect({
			x: x,
			y: y,
			width: width,
			height: height,
			fill: Colors.frameHandle,
			stroke: Colors.frameHandleStroke,
			strokeWidth: 1
		});
		this.rect2 = new Konva.Rect({
			x: x2,
			y: y2,
			width: width2,
			height: height2,
			fill: Colors.frameHandle,
			stroke: Colors.frameHandleStroke,
			strokeWidth: 1
		});

		this.glass.addGroup(this.rect);
		this.glass.addGroup(this.rect2);
	}

	destroy() {
		if ( this.rect != null ) this.rect.destroy();
		if ( this.rect2 != null ) this.rect2.destroy();

	}

	update() {
		this.destroy();
		this.create();
	}
}