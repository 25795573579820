/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, Dropdown, Menu, message} from "antd";
import {API} from "../../core/API";
import axios from "axios";
import {NavigateFunction, useNavigate} from "react-router-dom";

export function UserModule() {
    const nav = useNavigate();
    return <User nav={nav} />
}

interface iProps {
    nav: NavigateFunction;
}

interface iStates {
    logout: boolean;
    user: any;
}

export class User extends React.Component<iProps, iStates> {
    menu = (
        <Menu
            onClick={( menu: any) => {
                if ( menu.key === 'user_logout') return this.logout();
            }}
            items={[
                {
                    key: 'user_logout',
                    label: 'Abmelden',
                }
            ]}
        />
    );

    constructor(props: any) {
        super(props);
        this.state = {
            logout: false,
            user: undefined
        }
    }

    async componentDidMount() {
        const api = await axios.post(API.URL + '/users/check', {
            token: localStorage.getItem('token')
        });

        if ( api.data.errors ) return message.error(api.data.message ? api.data.message : 'Unbekannter Fehler');

        this.setState({user: api.data.data});
    }

    async logout() {
        if ( this.state.logout ) return message.error('Vorgang bereits im gange');
        this.setState({logout: true});

        const logout = await API.logout(localStorage.getItem("token"));
        if ( logout ) {
            localStorage.clear();
            message.success('Erfolgreich abgemeldet');
            this.props.nav("/login");
        }
    }

    render() {
        return <>
            { this.state.user !== undefined &&
                <Dropdown overlay={this.menu} placement="bottomRight" arrow>
                    <Button
                        shape={"round"}
                        icon={<i className={"fas fa-user"} style={{marginRight: 10}}></i>}
                    >{this.state.user.firstName} {this.state.user.lastName} </Button>
                </Dropdown>
            }
        </>;
    }
}