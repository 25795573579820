/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 13.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import $ from "jquery";
import {Rect, WindowMain} from "./main";
import {WindowOptions} from "./windowOptions";
import {AddController} from "./addController";
import {Directions} from "../interfaces/directions";

export class PlaceholderRect {
	rect: Konva.Rect | undefined;

	// Todo: vielleicht hier den typ angeben was es ist?
	constructor(public window: Rect, public name: string = "none", public direction: number = 0, public anchor: number = 0) {
		this.create();
	}

	create() {
		if ( this.window.rect === undefined ) return;

		let x: number = this.window.rect.x();
		let y: number = this.window.rect.y();
		let richtigeBreite: number = 45;
		let angleName: string = "none";
		if ( this.anchor === 0 ) angleName = "top";
		if ( this.anchor === 1 ) angleName = "middle";
		if ( this.anchor === 2 ) angleName = "bottom";

		const ID = `${this.window.id}_placeholder_${this.name}_${angleName}`;



		if ( this.name === "top" && this.anchor === 0 ) { // oben links
			x += 0;
			y -= richtigeBreite;
		} else if ( this.name === "top" && this.anchor === 1 ) { // oben mitte
			y -= richtigeBreite;
			x += ((this.window.rect.width()) / 2) - ( richtigeBreite / 2);
		} else if ( this.name === "top" && this.anchor === 2 ) { // oben rechts
			y -= richtigeBreite;
			x += this.window.rect.width() - richtigeBreite;
		}

		/**
		 * RECHTE SEITE
		 */
		else if ( this.name === "right" && this.anchor === 0 ) {
			x+= this.window.rect.width();
		} else if ( this.name === "right" && this.anchor === 1 ) {
			x+= this.window.rect.width();
			y+= this.window.rect.height() / 2 - ( richtigeBreite / 2);
		} else if ( this.name === "right" && this.anchor === 2 ) {
			x+= this.window.rect.width();
			y+= this.window.rect.height() - richtigeBreite;
		}

		/**
		 * LINKE SEITE
		 */
		else if ( this.name === "left" && this.anchor === 0 ) {
			x-= richtigeBreite;
		} else if ( this.name === "left" && this.anchor === 1 ) {
			x-= richtigeBreite;
			y+= this.window.rect.height() / 2 - ( richtigeBreite / 2);
		} else if ( this.name === "left" && this.anchor === 2 ) {
			x-= richtigeBreite;
			y+= this.window.rect.height() - richtigeBreite;
		}


		/**
		 * UNTEN
		 */
		if ( this.name === "bottom" && this.anchor === 0 ) { // oben links
			y += this.window.rect.height();
		} else if ( this.name === "bottom" && this.anchor === 1 ) { // oben mitte
			y += this.window.rect.height();
			x += ((this.window.rect.width()) / 2) - ( richtigeBreite / 2);
		} else if ( this.name === "bottom" && this.anchor === 2 ) { // oben rechts
			y += this.window.rect.height();
			x += this.window.rect.width() - richtigeBreite;
		}
		
		
		this.rect = new Konva.Rect({
			x: x,
			y: y,
			width: richtigeBreite,
			height: richtigeBreite,

			fill: '#f1f1f1',
			id: ID
		});

		if ( this.window.group === undefined ) return;
		this.window.group.add(this.rect);

		this.rect.on('mouseenter', this.onHover.bind(this));
		this.rect.on('mouseleave', this.onLeave.bind(this));
		this.rect.on('mouseup touchend', ( el ) => {
			if ( this.window.designer === undefined ) return;
			if ( this.window.designer.debug === undefined ) return;
			if ( this.window.designer.debug === null ) return;
			this.window.designer.debug.removeText(`id_${this.name}_${this.anchor}`);

			if ( this.window.designer.editor === undefined ) return;

			if ( this.window.designer.editor.onDrag ) {
				////console.log('clicked on placeholder!');
				const type = Number($(this.window.designer.editor.dragElement).attr("data-id"));
				const windowType = Number($(this.window.designer.editor.dragElement).attr("data-type"));

				let kopplung = false;
				if ( this.window.designer.editor.dragType === 2 ) kopplung = true;

				const wOption = new WindowOptions();
				wOption.splitCustom = false;
				wOption.splitType = type;
				wOption.anchor = this.anchor;

				let direction = 2;
				if ( this.name === "top") {
				} else if ( this.name === "right" ) {
					direction = 0;
				} else if ( this.name === "bottom" ) {
					direction = 1;
				}

				const nWindow = new AddController(this.window.designer);
				nWindow.options = wOption;
				nWindow.options.direction = direction;
				nWindow.options.isKopplung = kopplung;
				nWindow.parent = this.window;
				nWindow.apply();

				//console.log('addWindow from placeholder!');
				//this.window.designer.addWindow(this.window, direction, windowType, type, kopplung, wOption, undefined);

				this.window.designer.editor.dragElement.remove();
				this.window.designer.editor.dragElement = undefined;
				this.window.designer.editor.onDrag = false;
				this.window.designer.editor.updateWindows();
			}
		});
	}

	onHover() {
		if ( this.window.designer === undefined ) return;
		if ( this.window.designer.debug === undefined ) return;
		if ( this.window.designer.debug === null ) return;
		if ( this.window.designer.editor === undefined ) return;
		if ( this.rect === undefined ) return;

		if ( this.window.designer.debug.debugMode ) {
			const debug = this.window.designer.debug;
			debug.addText(`id_${this.name}_${this.anchor}`, `Placeholder: ${this.name} ${this.anchor}\nWindow id: ${this.window.id}`);
		}

		if ( !this.window.designer.editor.onDrag ) return;
		$(this.window.designer.editor.dragElement).addClass('ok');
		this.rect.fill("green");
	}

	onLeave() {
		if ( this.window.designer === undefined ) return;
		if ( this.window.designer.debug === undefined ) return;
		if ( this.window.designer.debug === null ) return;
		if ( this.window.designer.editor === undefined ) return;
		if ( this.rect === undefined ) return;

		this.window.designer.debug.removeText(`id_${this.name}_${this.anchor}`);
		if ( !this.window.designer.editor.onDrag ) return;
		$(this.window.designer.editor.dragElement).removeClass('ok');
		this.rect.fill("#f1f1f1");
	}

	resetColor() {
		if ( this.rect === undefined ) return;
		this.rect.fill("#f1f1f1");
	}

	destroy() {
		if ( this.rect != null ) this.rect.destroy();

	}
}

export class PlaceholderPositions {
	top: PlaceholderRect | undefined;
	middle: PlaceholderRect | undefined;
	bottom: PlaceholderRect | undefined;

	constructor(public window: Rect, public type: number = 0) {
		this.create();
	}

	create() {
		let name: string = "none";
		if ( this.type === 0 ) name = "top";
		if ( this.type === 1 ) name = "right";
		if ( this.type === 2 ) name = "bottom";
		if ( this.type === 3 ) name = "left";

		this.top = new PlaceholderRect(this.window, name, this.type, 0);
		this.middle = new PlaceholderRect(this.window, name, this.type, 1);
		this.bottom = new PlaceholderRect(this.window, name, this.type, 2);

		this.setVisible(false);
	}

	destroy() {
		if ( this.top !== undefined ) this.top.destroy();
		if ( this.middle !== undefined ) this.middle.destroy();
		if ( this.bottom !== undefined ) this.bottom.destroy();
	}

	setVisible( value: boolean = true ) {
		if ( this.top === undefined ) return;
		if ( this.middle === undefined ) return;
		if ( this.bottom === undefined ) return;
		if ( this.top.rect === undefined ) return;
		if ( this.middle.rect === undefined ) return;
		if ( this.bottom.rect === undefined ) return;

		this.top.rect.visible(value);
		this.middle.rect.visible(value);
		this.bottom.rect.visible(value);
	}
}


export class PlaceholderController {
	top: PlaceholderPositions | undefined;
	right: PlaceholderPositions | undefined;
	bottom: PlaceholderPositions | undefined;
	left: PlaceholderPositions | undefined;

	constructor(public window: Rect) {
		this.create();
	}

	create() {
		this.top = new PlaceholderPositions(this.window, Directions.TOP);
		this.right = new PlaceholderPositions(this.window, Directions.RIGHT);
		this.bottom = new PlaceholderPositions(this.window, Directions.BOTTOM);
		this.left = new PlaceholderPositions(this.window, Directions.LEFT);

	}

	highlight( element: Konva.Rect ) {
		if ( element === undefined ) return;

		element.fill("green");
	}

	normal( element: Konva.Rect ) {
		if ( element === undefined ) return;

		element.fill("#f1f1f1");
	}

    show() {
		if ( this.top !== undefined ) this.top.setVisible();
		if ( this.right !== undefined ) this.right.setVisible();
		if ( this.bottom !== undefined ) this.bottom.setVisible();
		if ( this.left !== undefined ) this.left.setVisible();
    }

    hide() {
		if ( this.top !== undefined ) this.top.setVisible(false);
		if ( this.right !== undefined ) this.right.setVisible(false);
		if ( this.bottom !== undefined ) this.bottom.setVisible(false);
		if ( this.left !== undefined ) this.left.setVisible(false);
    }

	destroy() {
		if ( this.top != null ) this.top.destroy();
		if ( this.right != null ) this.right.destroy();
		if ( this.bottom != null ) this.bottom.destroy();
		if ( this.left != null ) this.left.destroy();
	}

	update() {
		this.destroy();
		this.create();
	}
}
