/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Designer} from "../../designer";
import {Button, Col, Row, Space} from "antd";
import {Rect} from "../../window/main";


interface iProps {
    designer: Designer;
}
interface iStates {
    config: Rect[];
}

export class WindowsConfigure extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            config: this.props.designer.windows
        }
    }

    onAdd() {}
    remove() {}
    swap(fromIndex: number, toIndex: number) {
        console.log('swap windows:', fromIndex, toIndex);
        const config = this.state.config;

        const from = config[fromIndex];
        const to = config[toIndex];


        if ( from && to ) {
            config[toIndex] = from;
            config[fromIndex] = to;

            config[toIndex].x = to.x;
            config[toIndex].y = to.y;

            config[fromIndex].x = from.x;
            config[fromIndex].y = from.y;

            if ( config[toIndex - 1 ] ) {
                config[toIndex].parent = config[toIndex - 1 ];
                //config[toIndex].options.parentID = "";
            } else {
                config[toIndex].parent = undefined;
                config[toIndex].x = 50;
                config[toIndex].y = 50;
                config[toIndex].options.parentID = "";
            }

            if ( config[toIndex + 1] ) {
                config[toIndex + 1].parent = config[toIndex];
            }

            if ( config[fromIndex - 1] ) {
                config[fromIndex].parent = config[fromIndex - 1];
            } else {
                config[fromIndex].parent = undefined;
                config[fromIndex].x = 50;
                config[fromIndex].y = 50;
            }

            if ( config[fromIndex + 1]) {
                config[fromIndex + 1].parent = config[fromIndex];
            }

            this.update(config);
        }
    }

    onWidthChange() {}
    onHeightChange() {}
    onChange() {}

    update(data: any) {
        this.props.designer.windows = data;
        for ( const window of this.props.designer.windows ) {
            window.update();
        }

        if ( this.props.designer.sumMetric !== undefined ) {
            this.props.designer.sumMetric.update();
            this.props.designer.updateZoom();
        }

        this.setState({
            config: this.props.designer.windows
        });
    }

    render() {
        return <>

            <div style={{marginBottom: 20, marginTop: 10}}><strong>Fenster aufteilung:</strong></div>

            { this.state.config.map( (window, index) => {
                    return <div
                        style={{
                            position: 'relative',
                            border: '1px dashed black',
                            marginBottom: 20,
                            padding: 10
                        }}>
                        <div className="splitRowTitle">{window.options.id.replace('window-', '')}</div>
                        <Row justify={"space-between"}>
                            <Col span={10}>
                                <div>{window.parent?.id.replace('window-', '')}</div>
                                <div>{window.options.width}x{window.options.height}</div>
                                <div>x: {window.x} y: {window.y}</div>
                            </Col>
                            <Col>
                                <Space size={4}>
                                    <Button
                                        type={"default"}
                                        shape={"circle"}
                                        size={"small"}
                                        icon={<i className={"fas fa-arrow-up"}></i>}
                                        block
                                        onClick={(e) => this.swap(index, (index-1))}
                                    />
                                    <Button
                                        type={"default"}
                                        shape={"circle"}
                                        size={"small"}
                                        icon={<i className={"fas fa-arrow-down"}></i>}
                                        block
                                        onClick={(e) => this.swap(index, (index+1))}
                                    />
                                    <Button
                                        type={"default"}
                                        shape={"circle"}
                                        size={"small"}
                                        icon={<i className={"fas fa-times"}></i>}
                                        block
                                        //onClick={(e) => this.removeRow(index)}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </div>
                })
            }


            <Space>
                <Button
                    type="dashed"
                    icon={<i className={"fas fa-add"} style={{marginRight: 5}}></i>}
                    //onClick={this.onAddRow.bind(this)}
                >Neue Fenster</Button>
            </Space>
        </>;
    }
}