/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {Sprosse} from "./sprosse";
import {Glass} from "./main";


export class SprossenController {
	sprossen: Sprosse[] = new Array<Sprosse>();
	//sprossenConfig: Sprosse[] = new Array<Sprosse>();

	constructor(public glass: Glass) {
		////console.log('[SPROSSEN/constructor] ', this.sprossen);
		this.create();
	}

	create() {
		////console.log('[SPROSSEN/create] ', this.sprossen);
		for ( const sprosse of this.sprossen ) {
			if ( sprosse === undefined || sprosse === null ) continue;
			//this.sprossen.push(sprosse);
			sprosse.update();
		}
	}

	destroy() {
		////console.log('[SPROSSEN/destroy] ', this.sprossen);
		for ( const sprosse of this.sprossen ) sprosse.update();

		//this.sprossen = new Array<Sprosse>();

	}

	update() {
		////console.log('[SPROSSEN/update] ', this.sprossen);
		this.destroy();
		this.create();
	}
}