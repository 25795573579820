/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 9.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import {Project} from "../interfaces/project";
import {Button, message, Result, Spin, Typography} from "antd";
import axios from "axios";
import {API} from "../core/API";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import {Designer} from "../designer";
import {Versions} from "../interfaces/versions";
import {PDFDocumentProxy} from "pdfjs-dist/types/src/display/api";
const { Paragraph, Text } = Typography;

export function PageExport() {
	const { project, version } = useParams();
	const navigate = useNavigate();
	return <ExportController navigation={navigate}  projectID={project} versionID={version}/>;
}

interface IProps {
	projectID: string | undefined,
	versionID: string | undefined,
	navigation: any
}

interface IStates {
	project: Project | undefined;
	isLoading: boolean;
	isAPILoading: boolean;
	pdfUrl: string | undefined;
	error: string | undefined;
	pageNumbers: number;
	currentPage: number;
}

class ExportController extends React.Component<IProps, IStates> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			project: undefined,
			isLoading: false,
			isAPILoading: true,
			pdfUrl: "",
			error: undefined,
			pageNumbers: 0,
			currentPage: 1
		}
	}

	async componentDidMount() {
		try {
			const api =  await axios.post(
				API.URL + "/project", {
					token: localStorage.getItem("token"),
					projectID: this.props.projectID
				}
			);
			if ( api === undefined ) return message.error("Server nicht erreichbar!");
			if ( api.data.errors ) return message.error((api.data.message ? api.data.message : 'Unbekannter API Fehler'));

			this.setState({project: api.data.data}, async () => {
				if ( this.state.project === undefined ) return message.error('Project nicht zugeordnet!');

				for await ( const version of this.state.project.versions ) {
					await this.createImage(this.state.project, version);
				}


				try {
					const api = await axios.post(API.URL + "/project/export", {
						token: localStorage.getItem('token'),
						projectID: this.props.projectID
					});

					if ( api.data.result === false ) return message.error(api.data.message);
					if ( api.data.errors ) return message.error(api.data.message);
					if ( api.data.data.url === undefined || api.data.data.url.length === 0 )
						return message.error("Fehler beim anzeigen der PDF");

					setTimeout(() => {
						this.setState({
							pdfUrl: api.data.data.url,
							isAPILoading: false
						});
					}, 1500);
				} catch ( error ) {
					message.error('Project Export error');
				}

			});

		} catch ( error ) {
			message.error('Unbekannter Fehler');
		}
	}

	async createImage(project: Project, versionData: Versions): Promise<boolean> {
		return new Promise( async ( resolve, reject ) => {
			const html = document.createElement('div');
			html.id = "exportContainer_" + versionData.id;
			html.className = "exportContainer";
			document.body.appendChild(html);

			const designer = new Designer(
				"exportContainer_" + versionData.id,
				project,
				versionData.id,
				undefined,
				true
			);

			designer.on('ready', async () => {
				//console.log('create image for:', versionData.id);
				if (designer.stage === undefined ) return resolve(false);

				await axios.post(API.URL + '/image/', {
					token: localStorage.getItem('token'),
					projectID: versionData.projectID,
					versionID: versionData.id,
					windowID: "null",
					imageData: designer.stage.toDataURL({
						pixelRatio: 1,
					}),
				}, {
					//nUploadProgress: this.exportUploadProgress.bind(this)
				});

				console.log('created image:', versionData.id);
				html.remove();
				resolve(true);
			});
		});
	}

	setPDFError( error: Error ) {
		console.log(API.URL + '/' + this.state.pdfUrl + '?token=' + localStorage.getItem('token'));
		this.setState({
			isLoading: false,
			error: error.message
		});
	}

	setPDFSuccess( pdf: PDFDocumentProxy) {
		this.setState({
			isLoading: false,
			isAPILoading: false,
			pageNumbers: pdf.numPages,
			currentPage: 1
		});
	}

	render() {
		return <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
			{ this.state.isLoading && this.state.isAPILoading &&
				<Spin />
			}
			{ !this.state.isAPILoading && this.state.error === undefined &&
				<div className={"pdf_viewer"}>
					<Document
						options={{
							annotationMode: 0
						}}
						file={API.URL + '/' + this.state.pdfUrl + '?token=' + localStorage.getItem('token')}
						onSourceError={this.setPDFError.bind(this)}
						onLoadError={this.setPDFError.bind(this)}
						onLoadSuccess={this.setPDFSuccess.bind(this)}
					>
						<Page pageNumber={this.state.currentPage} renderAnnotationLayer={false} renderTextLayer={false} />
					</Document>
				</div>
			}
			{!this.state.isLoading && this.state.error &&
				<Result
					status="error"
					title="Übermittlung fehlgeschlagen"
					subTitle="Die PDF konnte nicht Angezeigt oder Bereitgestellt werden."
					extra={[
						<Button type="primary" key="console" onClick={() => { window.location.reload(); }}>
							Neuladen
						</Button>
					]}
				>
					<div className="desc">
						<Paragraph>
							<Text
								strong
								style={{
									fontSize: 16,
								}}
							>
								Der Inhalt hat folgende Fehlermeldung:
							</Text>
						</Paragraph>
						<Paragraph>
							<i className="fa-regular fa-circle-xmark"></i> {this.state.error}
						</Paragraph>
					</div>
				</Result>
			}

		</div>;
	}
}