/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 15.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import React from "react";
import {Avatar, Button, Col, Form, FormInstance, Input, Modal, Popover, Row, Select} from "antd";
import {AddController} from "../../window/addController";
import {Designer} from "../../designer";
import useForm from "antd/lib/form/hooks/useForm";
import {WindowOptions} from "../../window/windowOptions";
import {ConfigController} from "../../core/configController";
import {FrameOuterOffset} from "../../models/frameOuterOffset";

interface WindowCreatorProps {
    designer: Designer
}
export function WindowCreator(props: WindowCreatorProps) {
    const [form] = useForm();
    return <Component designer={props.designer} form={form}/>
}


interface iProps {
    designer: Designer;
    form: FormInstance;
}
interface iStates {
    width: number;
    height: number;
    preset: number;
    show: boolean;
}

class Component extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);
        this.state = {
            show: false,
            width: 0,
            height: 0,
            preset: -1
        }
    }

    getDefaultWindowInfo() {
        return (
            <div>
                <p>Um ein Standard Fenster hinzuzufügen</p>
            </div>
        );
    }

    onModalClose() {
        this.setState({
            show: false
        })
    }

    onModalSubmit() {
        this.props.form.submit();
    }

    onFormFinish(values: any) {
        console.log('form finished:', values);
        if ( this.props.designer === undefined ) return;


        const config = ConfigController.splitTypes.at(values.preset);
        if ( !config ) console.log('no config provided!');

        const nWindow = new AddController(this.props.designer);
        if ( this.state.height !== 0 && this.state.width !== 0 )
            nWindow.size = {
                width: this.state.width,
                height: this.state.height
            };
        if ( config ) {
            nWindow.options = new WindowOptions();
            nWindow.options.splitCustomConfig = config;
            //Fix frames
            for ( const row of nWindow.options.splitCustomConfig.reihen) {
                for ( const col of row.spalten ) {
                    col.frameOuterOffset = new FrameOuterOffset();
                    col.frameInnerOffset = new FrameOuterOffset(21);
                }
            }

            nWindow.size = {
                width: config.windowWidth,
                height: config.windowHeight
            };
        }

        nWindow.apply();

        if (this.props.designer.sumMetric ) this.props.designer.sumMetric.update();
        this.props.designer.updateZoom();


        this.setState({
            show: false
        });
    }

    render() {
        return <>
            <Popover content={this.getDefaultWindowInfo()} title={"Standard Fenster"}>
                <a
                    className="btn btn-sm btn-link mx-2"
                    onClick={() => {
                        //Todo: start here Modal for window information's.
                        this.setState({
                            show: !this.state.show
                        })
                    }}
                >
                    <i className="fa-solid fa-vector-square"></i>
                </a>
            </Popover>
            <Modal
                centered
                title="Standard Fenster Erstellen"
                visible={this.state.show}
                onCancel={this.onModalClose.bind(this)}
                footer={[
                    <Button onClick={this.onModalClose.bind(this)}>Abbrechen</Button>,
                    <Button type="primary" onClick={this.onModalSubmit.bind(this)}>Erstellen</Button>
                ]}
            >
                <Form
                    form={this.props.form}
                    onFinish={this.onFormFinish.bind(this)}
                >
                    <Row wrap={false}>
                        <Col style={{marginRight: 5}}>
                            <Form.Item name="width">
                                <Input
                                    addonBefore="Breite"
                                    suffix="mm"
                                    type="number"
                                    value={this.state.width}
                                    onChange={(val) => this.setState({width: Number(val.target.value)})}
                                />
                            </Form.Item>
                        </Col>
                        <Col style={{marginLeft: 5}}>
                            <Form.Item name="height">
                                <Input
                                    addonBefore="Höhe"
                                    suffix="mm"
                                    type="number"
                                    value={this.state.height}
                                    onChange={(val) => this.setState({height: Number(val.target.value)})}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="preset">
                        <Select
                            placeholder="Preset Auswählen"
                            style={{ width: '100%' }}
                        >
                            {
                                ConfigController.splitTypes.map((preset, index) => {
                                    return <Select.Option value={index} label={preset.label}>
                                        {preset.label}
                                    </Select.Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
}