/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 15.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {API} from "../core/API";

import {
	Button,
	Col,
	Divider, Drawer,
	Form, FormInstance,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Select,
	Space,
	Table,
	Tag,
	Tooltip
} from "antd";
import axios from "axios";
import {Project} from "../interfaces/project";
import {ColumnsType} from "antd/lib/table";
import {Kunde} from "../interfaces/kunde";
import {ClientForm} from "./component/forms/client";
import {ProjectCopy} from "./component/projectCopy";

export function PageProject() {
	const navigate = useNavigate();
	const [ form ] = Form.useForm();
	return <ProjectController navigation={navigate} key={"ProjectController"} clientForm={form}/>
}

interface IProps {
	navigation: any
	clientForm: FormInstance
}

interface IState {
	kunden: any[];
	projects: any[];
	filterProjects: any[];
	show: boolean;
	filter: string;
	logout: boolean;
	isDeleteSomeThing: boolean;
	selectedClient: Kunde | undefined;
}

export class ProjectController extends React.Component<IProps, IState> {
	filterInput: React.RefObject<any>;
	peakTimer: any = undefined;

	projectColumns: ColumnsType<Project> = [
		{
			title: 'Vorgang',
			dataIndex: 'name',
			key: 'table_name',
			render: (_, record) => (
				<Button type={"link"} onClick={() => this.openProject(record.id)}>{record.name}</Button>
			),
			filterSearch: true,
			filterMode: 'tree',
			onFilter: (value: any, record) => record.name.includes(value)
		},
		{
			title: 'Kunde',
			dataIndex: 'kunde',
			key: 'table_kunde',
			render: text => (
				<a>
					{text === undefined &&
						<Tag icon={<i className="fa-solid fa-circle-xmark"></i>} color="error">
							Kein Kunde, bitte berichtigen
						</Tag>
					}
					{ text !== undefined &&
						<a onClick={() => { this.onProjectClientClicked(text)}}><span>{text.Name} {text.Vorname}</span></a>
					}
				</a>
			)
		},
		{
			title: 'Positionen',
			dataIndex: 'versions',
			key: 'table_versions',
			render: (value, record, index) => (
				<Space key={"table_versions_space_" + record.id}>
					{
						record.versions.map(version => (
							<Tag color="error" key={'tag_' + record.id + '_' + version.id}>
								<a onClick={() => {this.openProjectVersion(record.id, version.id)}}>{version.name}</a>
								<Popconfirm
									title={`sind Sie sicher diese Version ${version.name} zu löschen?`}
									onConfirm={() => {this.deleteProjectVersion(record.id, version.id)}}
									okText="Ja"
									cancelText="Nein"
								>
									<a><i className={"fas fa-times"} style={{marginLeft: 10}}></i></a>
								</Popconfirm>
							</Tag>
						))
					}
				</Space>
			)
		},
		{
			title: '',
			key: 'table_action',
			render: (_, record) => (
				<Space size={"middle"} key={"spacer_" + record.id}>
					<Tooltip title="Projekt Einstellungen" key={"table_action_settings_"+record.id}>
						<Button
							type={"link"}
							icon={<i className={"fa-solid fa-cog"}></i>}
							onClick={(e) => this.openProjectSettings(record.id)} />
					</Tooltip>
					<Tooltip title="Kopieren" key={"table_action_copy_"+record.id}>
						<ProjectCopy
							projectID={record.id}
							key={"table_action_copy_comp_" + record.id}
							parent={this}
						/>
					</Tooltip>
					<Tooltip title="Designer" key={"table_action_designer_"+record.id}>
						<Button
							type={"link"}
							icon={<i className="fa-solid fa-eye"></i>}
							onClick={(e) => this.openProject(record.id)} />
					</Tooltip>
					<Tooltip title="Exportieren" key={"table_action_export_"+record.id}>
						<Button
							type={"link"}
							icon={<i className="fa-solid fa-file-pdf"></i>}
							onClick={(e) => this.openProjectExporter(record.id)} />
					</Tooltip>
					<Tooltip title="Löschen" key={"table_action_delete_"+record.id}>
						<Popconfirm
							title="sind Sie sicher, das Komplette Projekt zu löschen?"
							onConfirm={() => {this.deleteProject(record.id)}}
							okText="Ja"
							cancelText="Nein"
						>
							<Button
								type={"link"}
								danger
								icon={<i className="fa-solid fa-times"></i>} />
						</Popconfirm>
					</Tooltip>
				</Space>
			)}
	];

	constructor(props: any) {
		super(props);
		this.filterInput = React.createRef();

		this.state = {
			kunden: [],
			projects: [],
			filterProjects: [],
			show: false,
			filter: '',
			logout: false,
			isDeleteSomeThing: false,
			selectedClient: undefined
		}
	}

	async componentDidMount() {
		await this.getDatas();

		console.log(this.state);
	}

	async getDatas() {

		const projects = await API.getProjects();
		const kunden = await API.getKunden();


		this.setState({
			show: true,
			projects: projects,
			filterProjects: projects,
			kunden: kunden
		});
	}

	componentWillUnmount() {
		if ( this.peakTimer !== undefined ) clearTimeout(this.peakTimer);
	}

	onFilterInputChanged( value: string ) {
		this.setState( {filter: value.toLowerCase() });

		if ( value.length === 0 ) {
			this.setState( {
				filterProjects: this.state.projects
			});
		} else {
			let filterProjects = this.state.projects.filter( ( project: Project ) => {
				const searchedValue = project.name + ' ' + project.kunde.Name + ' ' + project.kunde.Vorname
				return searchedValue.toLowerCase().includes(value.toLowerCase());
			});

			this.setState( {
				filterProjects: filterProjects
			});
		}
		//this.setState( {filter: this.filterInput.current.value.toLowerCase() });
		//////console.log('input changed', this.filterInput.current.value.toLowerCase());
	}

	openProject(id: string) {
		const project: Project = this.state.projects.find( ( project ) => project.id === id);
		if ( project === undefined ) return;

		this.props.navigation(`/designer/${project.id}/${project.versionID}`);
	}

	openProjectVersion(projectID: string, versionID: string) {
		this.props.navigation(`/designer/${projectID}/${versionID}`);
	}

	openProjectSettings(id: string) {
		const project: Project = this.state.projects.find( ( project ) => project.id === id);
		if ( project === undefined ) return;

		this.props.navigation(`/settings/${project.id}/${project.versionID}`);
	}

	openProjectExporter(id: string) {
		const project: Project = this.state.projects.find( ( project ) => project.id === id);
		if ( project === undefined ) return;

		this.props.navigation(`/export/${project.id}/${project.versionID}`);
	}

	async deleteProjectVersion(id: string, versID: string) {
		try {
			//console.log('delete url:', API.URL + '/version/delete');
			const api = await axios.post(API.URL + '/version/delete', {
				token: localStorage.getItem('token'),
				projectID: id,
				versionID: versID
			});

			if ( api.data.errors ) return message.error(( api.data.message ? api.data.message : 'Unbekannter Fehler'));

			message.success(api.data.message);
			await this.getDatas();
		} catch ( error ) {
			message.error('Konnte Version nicht löschen.');
		}
	}

	async deleteProject(id: string) {
		try {
			const api = await axios.post(API.URL + '/project/delete', {
				token: localStorage.getItem('token'),
				projectID: id
			});
			if ( api.data.errors ) return message.error(( api.data.message ? api.data.message : 'Unbekannter Fehler'));
			message.success(api.data.message);
			await this.getDatas();
		} catch ( error ) {
			message.error('Konnte Version nicht löschen.');
		}
	}

	onProjectClientClicked( client: Kunde ) {
		this.setState({
			selectedClient: client
		});
	}

	closeClientEditor() {
		this.setState({
			selectedClient: undefined
		});
	}

	clientEditorDrawerSave() {
		this.props.clientForm.submit();
	}

	async onClientEditorForm( values: any ) {
		if ( this.state.selectedClient === undefined ) return;

		try {
			const api = await axios.post(API.URL + '/kunden/save', {
				token: localStorage.getItem('token'),
				id: this.state.selectedClient.Vorgangsnummer,
				...values
			});

			if ( api.data.errors ) return message.error(api.data.message);

			message.success('Änderungen gespeichert!');
			this.setState({
				selectedClient: undefined
			});

			await this.getDatas();

		} catch ( error ) {
			message.error(`${error}`);
		}
	}

	render() {
		return <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
			<div> { this.state && this.state.show &&
				<>
					<ProjectCreate />
					<Divider />
					<Space direction="vertical" size="middle" style={{width: '100%'}}>
						<Input.Search placeholder="Projekte Suchen" onSearch={this.onFilterInputChanged.bind(this)} style={{width: "100%" }} enterButton={true} />
						<Table size={"small"} dataSource={this.state.filterProjects} columns={this.projectColumns} />
					</Space>

					<Drawer
						visible={(this.state.selectedClient !== undefined)}
						title={`Kunde ${this.state.selectedClient?.Vorgangsnummer} Bearbeiten`}
						size={"large"}
						onClose={this.closeClientEditor.bind(this)}
						bodyStyle={{ paddingBottom: 80 }}
						extra={
							<Space>
								<Button onClick={this.closeClientEditor.bind(this)}>Abbrechen</Button>
								<Button onClick={this.clientEditorDrawerSave.bind(this)} type="primary">
									Speichern
								</Button>
							</Space>
						}

					>
						<ClientForm
							form={this.props.clientForm}
							finish={this.onClientEditorForm.bind(this)}
							initial={this.state.selectedClient}
						/>
					</Drawer>
				</>
			}
			</div>
		</div>;
	}
}

function ProjectCreate() {
	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const [kundenID, setKundenID] = useState(-2);
	const nav = useNavigate();

	const [ createProject ] = Form.useForm();

	const handleClose = () => setShow(false);
	const handleSubmit = async ( values : any ) => {
		////console.log('handle submit:');
		setLoader(true);

		try {
			const api = await axios.post(API.URL + "/project/create", {
				token: localStorage.getItem("token"),
				data: values
			});

			if ( api.data.errors ) {
				setLoader(false);
				return message.error(api.data.message);
			}

			//console.log(api.data);

			nav(`/designer/${api.data.data.id}/${api.data.data.versionID}`);

		} catch ( error ) {
			message.error("Server nicht erreichbar");
			//console.log(error);
			setLoader(false);
		}

		setLoader(false);
	}

	const handleShow = () => setShow(true);

	const onChange = ( value: any ) => {
		setKundenID(Number(value));
	};

	const submitForm = ( e: any ) => {
		createProject.submit();
	}

	return (
		<>
			<Button type="primary" shape="round" icon={<i className={"fa-solid fa-add"} style={{marginRight: 10}}/>} onClick={handleShow}>
				Projekt Erstellen
			</Button>

			<Modal
				title="Neues Projekt"
				visible={show}
				onOk={submitForm}
				onCancel={handleClose}
				confirmLoading={loader}
			>
				<Form form={createProject} layout="vertical" onFinish={handleSubmit}>
					<Form.Item
						label="Kunde"
						name="client"
						rules={[{ required: true, message: 'Bitte Kunde Auswählen' }]}
					>
						<Select
							showSearch={true}
							style={{ width: "100%" }}
							placeholder="Kunde Auswählen / Suchen"
							onChange={onChange}
							value={-2}
						>
							<Select.Option key="-1" value="-1" id="-1">Neuer Kunde</Select.Option>
							{ API.kundenTemp && API.kundenTemp.map( ( kunde ) =>
								<Select.Option key={kunde.Vorgangsnummer} value={kunde.Vorgangsnummer} id={kunde.Vorgangsnummer}>{kunde.Name} {kunde.Vorname}</Select.Option>
							)
							}
						</Select>
					</Form.Item>

					{ kundenID === -2 &&
						<div>Bitte Kunde Auswählen.</div>
					}

					{ kundenID !== -2 &&
						<>
							{ kundenID === -1 &&
								<>
									<Row justify="center">
										<Col span={11}>
											<Form.Item
												label="Vorname"
												name="firstname"
												rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={1}></Col>
										<Col span={11}>
											<Form.Item
												label="Nachname"
												name="lastname"
												rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>


									<Row justify="center">
										<Col span={11}>
											<Form.Item
												label="Straße"
												name="street"
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={1}></Col>
										<Col span={11}>
											<Form.Item
												label="Hausnummer"
												name="streetNumber"
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>

									<Row justify="center">
										<Col span={11}>
											<Form.Item
												label="PLZ"
												name="plz"
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={1}></Col>
										<Col span={11}>
											<Form.Item
												label="Ort"
												name="ort"
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>

									<Row justify="center">
										<Col span={11}>
											<Form.Item
												label="E-Mail"
												name="email"
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={1}></Col>
										<Col span={11}>
											<Form.Item
												label="Telefon"
												name="phone"
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								</>
							}

							<Form.Item
								label="Projektname"
								name="projectName"
								rules={[{ required: true, message: 'Bitte Projekt Name eingeben!' }]}
							>
								<Input />
							</Form.Item>

							<Row>
								<Col>
									<Form.Item
										label="Vorgangsnummer"
										name="version"
										rules={[{ required: true, message: 'Bitte Vorgangsnummer eingeben!' }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col>
									<Form.Item
										label="Versionsbezeichnung"
										name="versionName"
										rules={[{ required: true, message: 'Bitte Versionsbezeichnung eingeben!' }]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>

						</>
					}
				</Form>
			</Modal>
		</>
	);
}