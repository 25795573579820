/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

/**
 * fenster konfigurationen
 */
import {GlassOptions} from "../glass/glassOptions";
import {WindowOptions} from "../window/windowOptions";


export class WindowConfig {
	option: WindowOptions = new WindowOptions();
	glass: GlassConfig[] = [];
}

export class GlassConfig {
	option: GlassOptions | undefined;
	sprossen: SprossenConfig[] | undefined;
}

export class SprossenConfig {
	x: number | undefined;
	y: number | undefined;
	direction: number | undefined;
	thickness: number | undefined;
}