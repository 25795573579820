/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import {Project} from "../interfaces/project";
import {Button, Form, FormInstance, Input, message, PageHeader, Skeleton, Switch} from "antd";
import axios from "axios";
import {API} from "../core/API";
import {VersionSelect} from "./component/versionSelect";


export function PageSettings() {
	const { project, version } = useParams();
	const navigate = useNavigate();

	const [settingsForm] = Form.useForm();
	return <SettingsController navigation={navigate}  projectID={project} versionID={version} form={settingsForm}/>;
}

interface IProps {
	projectID: string | undefined,
	versionID: string | undefined,
	navigation: any
	form: FormInstance<any>;
}

interface IStates {
	loaded: boolean;
	project: Project | undefined;
	versionName: string | undefined;
}

class SettingsController extends React.Component<IProps, IStates> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			loaded: false,
			project: undefined,
			versionName: undefined
		}

	}

	componentDidMount() {
		this.getProject();
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IStates>, snapshot?: any) {
		if ( this.state.project === undefined ) return;

		const version = this.state.project.versions.find( ( ver ) => ver.id === this.props.versionID);
		if ( version === undefined ) return //console.log('cant find version');

		if ( this.state.versionName !== version.name) this.setState({versionName: version.name});
	}

	async getProject() {
		const api =  await axios.post(
			API.URL + "/project", {
				token: localStorage.getItem("token"),
				projectID: this.props.projectID
			}
		);
		if ( api === undefined ) return undefined;
		if ( api.data.errors ) {
			message.error('Projekt kann nicht geladen werden');
			return;
		}

		const project: Project = api.data.data;
		//console.log(project);
		const version = project.versions.find( ( ver ) => ver.id === this.props.versionID);
		if ( version === undefined ) return //console.log('cant find version');

		this.setState({
			project: project,
			versionName: version.name
		}, () => {
			this.setState({
				loaded: true,
			});
		});
	}

	onFormFinish( values: any ) {
		//console.log(values);
	}

	onFormFailed ( values: any ) {
		//console.log( values );
	}

	onSaveButton() {
		this.props.form.submit();
	}

	onVersionChange( value: any ) {
		//console.log('version change on settings!', value);
		this.props.navigation(`/settings/${this.props.projectID}/${value}`);
	}

	render() {
		return <>
			{ this.state.project !== undefined &&
				<PageHeader className="site-page-header"
							onBack={() => this.props.navigation("/")}
							title={"Einstellungen für: " + this.state.project.name}
							subTitle={this.state.versionName}
							extra={[
								<VersionSelect
									parent={this}
									designer={undefined}
									project={this.state.project}
									versionID={this.props.versionID}
									onChange={this.onVersionChange.bind(this)}
									canCreate={false}
								/>,
								<Button onClick={this.onSaveButton.bind(this)} type="primary" key={"save"} icon={<i className={"fa-solid fa-save"} style={{marginRight: 10}}/> }>Speichern</Button>
							]}
				/>
			}
			<div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
				<Skeleton active={!this.state.loaded} loading={!this.state.loaded} />

				{this.state.loaded &&
					<Form
						form={this.props.form}
						name="basic"
						layout="vertical"
						initialValues={{ remember: true }}
						onFinish={this.onFormFinish.bind(this)}
						onFinishFailed={this.onFormFailed.bind(this)}
						autoComplete="off"
					>

						<Form.Item valuePropName="checked">
							<Switch checkedChildren="Neubau" unCheckedChildren="Altbau"/>
						</Form.Item>
						<Form.Item
							label="Fenstersystem"
							name="windowSystem"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Fensterbanksystem"
							name="fensterBankSystem"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Dichtungsfarbe"
							name="Dichtungsfarbe"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Alufensterbank"
							name="Alufensterbank"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Montagekosten"
							name="Montagekosten"
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Fenstersystem"
							name="Fenstersystem"
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Insektenschutz"
							name="Insektenschutz"
						>
							<Input />
						</Form.Item>
					</Form>
				}
			</div>
		</>;
	}
}