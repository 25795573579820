/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 5.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/



export enum ArticleTypes {
    GLAS,
    GUSS,
    SPROSSE,
    RABATTE
}

export const articleTypes: string[] = [
    "Glas", "Guss", "Sprosse", "Rabatte"
]