/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 18.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, Col, Form, FormInstance, Input, message, Modal, Result, Row, Select, Table} from "antd";
import axios from "axios";
import {API} from "../../core/API";
import {useForm} from "antd/es/form/Form";
import {ConfigController} from "../../core/configController";


export function PagePriceTable() {
    const [ createForm ] = useForm();
    return <PriceTable form={createForm} />;
}

interface iProps {
    form: FormInstance;
}
interface iStates {
    currentRows: any[];
    currentCols: any[];
    typeData: any;
    typeID: string;
    create: boolean;
    creating: boolean;
}

class PriceTable extends React.Component<iProps, iStates> {

    types: string[] = [
        "1", "2", "3", "4", "5", "6", "7", "8"
    ];

    constructor(props: iProps) {
        super(props);

        this.state = {
            currentRows: [],
            currentCols: [],
            typeData: {},
            typeID: "1",
            create: false,
            creating: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        if ( this.state.typeID === "0") return message.error('please select an type');

        try {
            const api = await axios.post(API.URL + '/price/', {
                token: localStorage.getItem('token'),
                type: this.state.typeID
            });

            if ( api.data.errors ) {
                this.setState({
                    typeData: undefined,
                    currentRows: [],
                    currentCols: []
                });
                return message.error(api.data.message ? api.data.message : 'Unbekannte Fehlermeldung');
            }


            const rows: any[] = api.data.data.rows;
            const cols: any[] = api.data.data.cols;
            const rowsModified: any[] = [];
            const colsModified: any[] = [];

            for ( const col of cols ) {
                colsModified.push({
                    title: col.Field,
                    dataIndex: col.Field
                })
                console.log(col);
            }
            for ( const row of rows ) {
                const fields = Object.keys(row);

                const obj: any = {};
                obj.height = row.height;
                for ( const field of fields ) {
                    if ( field === 'height') continue;

                    obj[field] = row[field];
                }

                rowsModified.push(obj);
            }


            this.setState({
                typeData: api.data.data,
                currentRows: rowsModified,
                currentCols: colsModified
            });

        } catch ( error ) {
            message.error(`${error}`);
        }
    }

    onTypeChange( value: any ) {
        console.log(value);
        this.setState({
            typeID: value,
        }, async () => {
            this.getData();
        });
    }

    addCol() {
        const currentCols = this.state.currentCols;
        const currentRows = this.state.currentRows;

        currentCols.push({
            title: 55555,
            dataIndex: 55555
        });

        currentRows.push({
            "55555": 99999
        });
    }

    addRow() {}

    onTypeAdd() {
        this.setState({create: true, creating: false});
    }

    onModalOk() {
        this.props.form.submit();
    }

    onModalCancel() {
        this.setState({create: false});
    }

    onFormSubmit( values: any ) {
        console.log(values);
    }

    render() {
        return (
            <>
                <Row justify="end" align="middle">
                    <Col>
                        Typ:
                        <Select style={{width: 100, marginLeft: 10}} defaultValue={"1"} onChange={this.onTypeChange.bind(this)}>
                            {
                                ConfigController.splitTypes.map(( type, index ) => {
                                    return <Select.Option value={index}>{type.label}</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={this.onTypeAdd.bind(this)}
                            style={{marginLeft: 10}}
                        >
                            Hinzufügen
                        </Button>
                    </Col>
                </Row>
                { this.state.currentCols.length != 0 && this.state.currentRows.length != 0 &&
                    <Table columns={this.state.currentCols} dataSource={this.state.currentRows}/>
                }
                { this.state.typeData === undefined &&
                    <Result
                        status="404"
                        title="Keine Daten"
                    />
                }

                <Modal
                    visible={this.state.create}
                    title="Typ Hinzufügen"
                    onCancel={this.onModalCancel.bind(this)}
                    onOk={this.onModalOk.bind(this)}
                >
                    <Form
                        form={this.props.form}
                        onFinish={this.onFormSubmit.bind(this)}
                    >
                        <Form.Item label={"Typ Nummer"} name="type">
                            <Input type="number" />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}