/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 28.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, Col, Form, FormInstance, Input, Row, Select} from "antd";
import {GenderArray} from "../../../interfaces/gender";


interface iProps {
    finish: any;
    form?: FormInstance;
    initial?: any;
    drawButton?: boolean;
}

export class ClientForm extends React.Component<iProps, any> {

    render() {
        return <Form
            form={this.props.form}
            onFinish={( values: any ) => this.props.finish(values) }
            layout="vertical"
            initialValues={this.props.initial}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"Bezeichnung"} name="Bezeichnung">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Anrede"} name="Anrede">
                        <Select >
                            { GenderArray.map((gender, index) => {
                                return <Select.Option key={index} value={index}>{gender}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"Nachname"} name="Name" required>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Vorname"} name="Vorname" required>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"Straße"} name="street">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Hausnummer"} name="Hausnummer">
                        <Input type={"number"} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"PLZ"} name="PLZ">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Ort"} name="Ort">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"EMail"} name="EMail">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Telefon"} name="Telefon">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={"Mobil"} name="Mobil">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            { this.props.drawButton !== undefined && this.props.drawButton &&
                <Row gutter={16}>
                    <Button htmlType="submit" type={"primary"}>Speichern</Button>
                </Row>
            }
        </Form>;
    }

}