/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Project} from "../../interfaces/project";
import { TestDesigner } from '../../testDesigner';


export function PageTest() {
    const { project, version } = useParams();
    const navigate = useNavigate();
    const [ createForm ] = Form.useForm();

    return <TestController navigation={navigate} projectID={project} versionID={version} createModalForm={createForm}/>;
}

interface IProps {
    projectID: string | undefined,
    versionID: string | undefined,
    navigation: any;
    createModalForm: any;
}

interface IStates {
    project: undefined;
    createModal: boolean
}

class TestController extends React.Component<IProps, IStates> {

    testDesigner: TestDesigner | undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            project: undefined,
            createModal: false
        }
    }


    componentDidMount() {
        this.testDesigner = new TestDesigner();
    }

    onFormFinish( values: any ) {

    }

    onModalOk() {
        this.props.createModalForm.submit();
    }

    onModalCancel() {
        this.setState({createModal: false});
    }

    onModeChange( type: number = 0 ) {
        if ( this.testDesigner === undefined ) return;
        this.testDesigner.mode = type;
    }

    render() {
        return <>
            <div>
                <Space size="large">
                    <Button type="link" onClick={(e) => this.onModeChange(0)}>Grundriss</Button>
                    <Button type="link" onClick={(e) => this.onModeChange(1)}>Glass schnitte</Button>
                    <Button type="link" onClick={(e) => this.onModeChange(2)}>Ziehen</Button>
                </Space>
            </div>

            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div id={"container"} style={{width: "100%", height: 800, backgroundColor: 'white'}}></div>
            </div>

            <Modal
                visible={this.state.createModal}
                onOk={this.onModalOk.bind(this)}
                onCancel={this.onModalCancel.bind(this)}
            >
                <Form form={this.props.createModalForm}
                      onFinish={this.onFormFinish.bind(this)}
                >
                    <Form.Item>
                        <Input type={"number"} name={"width"} />
                    </Form.Item>
                    <Form.Item>
                        <Input type={"number"} name={"height"} />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}