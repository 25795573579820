/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {PriceManager} from "../../pages/priceManager";
import {Button, Col, Form, FormInstance, Input, message, Modal, Row, Select} from "antd";
import {useForm} from "antd/lib/form/Form";
import axios from "axios";
import {API} from "../../../core/API";


export function ModalSettingsTypes(props: any) {
    const [ createForm ] = useForm();
    return <SettingsTypes parent={props.parent} form={createForm} />;
}


interface iProps {
    parent: PriceManager;
    form: FormInstance
}

interface iStates {
    show: boolean;
    isCreating: boolean;
}

export class SettingsTypes extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            show: false,
            isCreating: false,
        }
    }

    componentDidMount() {
        //checking selections maybe here
    }

    show() {
        this.setState({show: true});
    }

    hide() {
        this.setState({show: false});
    }
    submitForm() {
        this.props.form.submit();
    }

    onFormFinish( values: any ) {
        if ( this.state.isCreating ) return;

        this.setState({isCreating: true}, async () => {
            const api = await axios.post(API.URL + '/settings/preis/types/add', {
                token: localStorage.getItem('token'),
                ...values
            });

            if( api.data.errors ) {
                this.setState({
                    isCreating: false
                });
                return message.error(api.data.message ? api.data.message : 'Unbekannter Fehler');
            }

            this.props.parent.getData();
            this.setState({
                show: false,
                isCreating: false
            });
        });
    }

    getSystemVerglasungData(verglasungID: string) : any {
        return this.props.parent.state.verglasungen.find((verglasung) => verglasung.id === verglasungID);
    }

    render() {
        return <>
            <Button
                type={"primary"}
                shape={"round"}
                onClick={this.show.bind(this)}
            >
                Hinzufügen
            </Button>

            <Modal
                title={"Verglasung Erstellen"}
                centered
                visible={this.state.show}
                confirmLoading={this.state.isCreating}
                onOk={this.submitForm.bind(this)}
                onCancel={this.hide.bind(this)}
            >
                <Form
                    layout={"vertical"}
                    form={this.props.form}
                    onFinish={this.onFormFinish.bind(this)}
                >
                    <Form.Item label={"Typ"} name={"type"}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item label={"System"} name={"system"}>
                        <Select>
                            {
                                this.props.parent.state.systeme.map( ( system ) => {
                                    const verglasung = this.getSystemVerglasungData(system.verglasungID);

                                    return <Select.Option value={system.id}>
                                        {system.name} {system.percent}
                                        { verglasung !== undefined &&
                                            verglasung.name
                                        }
                                    </Select.Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}