/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 7.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import React from "react";
import {MenuProps} from "antd";

type MenuItem = Required<MenuProps>['items'][number];

export function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

export const items: MenuItem[] = [
    getItem('Projekt', '/projects' , <i className="fa-solid fa-list" />),
    getItem('Kunden', '/clients' , <i className="fa-solid fa-users" />),
    getItem('Einstellungen', '/settings', <i className="fa-solid fa-cog"/>, [
        getItem('Artikel', '/settings/articles/' , <i className="fa-solid fa-money-bill" />, [
            getItem('Alle Artikel', '/settings/articles/types', <i className="fa-solid fa-money-bill" />),
            getItem('Kategorien', '/settings/articles/categories', <i className="fa-solid fa-money-bill" />),
        ]),
        getItem('Preistabelle', '/settings/price/', <i className="fa-solid fa-money-bill" />, [
            getItem('Preis zu Typen', '/settings/price/types' , <i className="fa-solid fa-money-bill" />),
            getItem('Preistabelle', '/settings/price/table' , <i className="fa-solid fa-money-bill" />),
        ])
    ])
]