/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 6.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React, {useEffect} from "react";
import {Breadcrumb, Col, Layout, Menu, MenuProps, message, Row} from 'antd';
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Location} from "history";
import {API} from "../core/API";
import {UserModule} from "./component/user";
import { items } from "./configs/menu";
const { Content, Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export function LayoutComponent() {
	const location = useLocation();
	const nav = useNavigate();
	const effect = useEffect;


	return <LayoutController location={location} navigation={nav} effect={effect} />
}

interface IProps {
	location: Location;
	navigation: any;
	effect: any;
	projectID?: string;
	versionID?: string;
}

interface IStates {
	collapsed: boolean;
	currentPath: string;
	logout: boolean;
}

class LayoutController extends React.Component<IProps, IStates> {

	peakTimer: any = undefined;

	getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
		return {
			key,
			icon,
			children,
			label,
		} as MenuItem;
	}

	items: MenuItem[] = [
		this.getItem('Projekt', '/projects' , <i className="fa-solid fa-list" />),
		this.getItem('Kunden', '/clients' , <i className="fa-solid fa-users" />),
		this.getItem('Einstellungen', '/settings', <i className="fa-solid fa-cog"/>, [
			this.getItem('Artikel', '/settings/articles' , <i className="fa-solid fa-money-bill" />),
			this.getItem('Preis zu Typen', '/settings/price' , <i className="fa-solid fa-money-bill" />),
			this.getItem('Preistabelle', '/settings/pricetable' , <i className="fa-solid fa-money-bill" />),
		])
	];

	constructor(props: IProps) {
		super(props);

		this.state = {
			collapsed: false,
			currentPath: '',
			logout: false
		}
	}

	async peakSessionChecker() {
		const state = await API.checkLoginState();
		if ( state === undefined ) {
			localStorage.clear();
			this.setState({
				logout: true
			});
		}

		this.peakTimer = setTimeout(this.peakSessionChecker.bind(this), 20000);
	}

	async componentDidMount() {
		await this.peakSessionChecker();
		if ( this.peakTimer === undefined ) this.peakTimer = setTimeout(this.peakSessionChecker.bind(this), 20000);
	}

	componentWillUnmount() {
		if ( this.peakTimer !== undefined ) clearTimeout(this.peakTimer);
	}

	onMenuClicked( e: any ) {
		if ( !e.key.includes("/user/") ) return this.props.navigation(e.key);
	}

	getMenu(path: string, menu: MenuItem[] = this.items): MenuItem | undefined {
		for ( const item of menu ) {
			if ( !item ) continue;
			if ( item.key === path) return item;
			// @ts-ignore
			if (item.children) return this.getMenu(path, item.children);
		}
		return undefined;
	}

	getBreadcrumb() {
		const cPath = this.props.location.pathname;
		let paths = cPath.split("/");


		return paths.map( ( path ) => {
			if ( path.length === 0 || path === "" ) return;
			const menu = this.getMenu(path);
			if ( menu ) {
				// @ts-ignore
				return <Breadcrumb.Item key={"bread_" + path}>{menu.label}</Breadcrumb.Item>
			}
		});
	}

	render() {
		return <>
			{ localStorage.getItem('token') === undefined || this.state.logout &&
				<Navigate to={"/login"} />
			}
			<Layout style={{ minHeight: '100vh'}}>
				<Layout.Header className={"site-page-header"} style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
					<div className="logo" />
					<Row justify={"space-between"} align={"middle"}>
						<Col flex={"auto"}>
							<Menu
								onClick={this.onMenuClicked.bind(this)} selectedKeys={[this.props.location.pathname]}
								mode="horizontal"
								items={items}
							/>
						</Col>
						<Col><UserModule /></Col>
					</Row>
				</Layout.Header>
				<Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
					<Breadcrumb style={{ margin: '16px 0' }}>
						<Breadcrumb.Item key={"bread_start"}>
							<a>Startseite</a>
						</Breadcrumb.Item>
						{this.getBreadcrumb()}
					</Breadcrumb>
					<Outlet />
				</Content>
				<Footer style={{ textAlign: 'center' }}>MiMann.net ©2022</Footer>
			</Layout>
		</>
	}
}