/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 15.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import { ConfigController } from "../core/configController";
import {GlassSplitType, Reihe, Spalte} from "../models/splitTypes";


ConfigController.splitTypes.push(new GlassSplitType("TYP 0", [
	new Reihe([
		new Spalte(0, 0),
	])
], 900, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 01", [
	new Reihe([
		new Spalte(0, 8),
	])
], 900, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 02", [
	new Reihe([
		new Spalte(0, 6),
		new Spalte(0, 7),
	])
], 1500, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 03", [
	new Reihe([
		new Spalte(0, 6),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	])
], 1500, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 04", [
	new Reihe([
		new Spalte(0, 5),
		new Spalte(30, 0, false, true),
		new Spalte(0, 6),
		new Spalte(0, 7),
	])
], 2250, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 05", [
	new Reihe([
		new Spalte(0, 8),
	])
], 900, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 06", [
	new Reihe([
		new Spalte(0, 6),
		new Spalte(0, 7),
	])
], 1800, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 07", [
	new Reihe([
		new Spalte(0, 6),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	])
], 1800, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 08", [
	new Reihe([
		new Spalte(0, 0),
	])
], 1000, 1500));
ConfigController.splitTypes.push(new GlassSplitType("TYP 09", [
	new Reihe([
		new Spalte(0, 0),
		new Spalte(20, 0, false, true),
		new Spalte(0, 0),
	])
], 2000, 1000));
ConfigController.splitTypes.push(new GlassSplitType("TYP 10", [
	new Reihe([
		new Spalte(0, 0),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 0),
	]),
], 1000, 1500));
ConfigController.splitTypes.push(new GlassSplitType("TYP 11", [
	new Reihe([
		new Spalte(0, 9),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 8),
	]),
], 900, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 11 U.", [
	new Reihe([
		new Spalte(0, 7),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 9),
	]),
], 900, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 12", [
	new Reihe([
		new Spalte(0, 0),
	]),
	new Reihe([
		new Spalte(0, 8),
	]),
], 900, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 13", [
	new Reihe([
		new Spalte(0, 7),
	]),
	new Reihe([
		new Spalte(0, 0),
	]),
], 900, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 14", [
	new Reihe([
		new Spalte(0, 9),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 6),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 15", [
	new Reihe([
		new Spalte(0, 9),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 5),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 16", [
	new Reihe([
		new Spalte(0, 0),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 6),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 17", [
	new Reihe([
		new Spalte(0, 5),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 0),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 18", [
	new Reihe([
		new Spalte(0, 1),
		new Spalte(0, 1),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 5),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 19", [
	new Reihe([
		new Spalte(0, 6),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 1),
		new Spalte(0, 1),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 20", [
	new Reihe([
		new Spalte(0, 5),
		new Spalte(20, 0, false, true),
		new Spalte(0, 3),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 6),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 21", [
	new Reihe([
		new Spalte(0, 9),
		new Spalte(20, 0, false, true),
		new Spalte(0, 9),
	]),
	new Reihe(undefined, 20, true),
	new Reihe([
		new Spalte(0, 6),
		new Spalte(20, 0, false, true),
		new Spalte(0, 7),
	]),
],1500, 1600));
ConfigController.splitTypes.push(new GlassSplitType("TYP 22", [
	new Reihe([
		new Spalte(0, 11),
	]),
],900, 1300));
ConfigController.splitTypes.push(new GlassSplitType("TYP 23", [
	new Reihe([
		new Spalte(0, 1),
		new Spalte(0, 12),
	]),
],2250, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 24", [
	new Reihe([
		new Spalte(0, 5),
	]),
],1100, 2150, {hasLowerRamp: true}));
ConfigController.splitTypes.push(new GlassSplitType("TYP 25", [
	new Reihe([
		new Spalte(0, 5),
	]),
],1100, 2150, {hasLowerRamp: true}));
ConfigController.splitTypes.push(new GlassSplitType("TYP 26", [
	new Reihe([
		new Spalte(0, 13),
		new Spalte(0, 2),
	]),
],2500, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 27", [
	new Reihe([
		new Spalte(0, 2),
		new Spalte(0, 14),
	]),
],2500, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 28", [
	new Reihe([
		new Spalte(0, 13),
		new Spalte(0, 14),
	]),
],2500, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 29", [
	new Reihe([
		new Spalte(0, 2),
		new Spalte(0, 14),
		new Spalte(0, 13),
		new Spalte(0, 2),
	]),
], 4000, 2150));
ConfigController.splitTypes.push(new GlassSplitType("TYP 30", [
	new Reihe([
		new Spalte(0, 2),
		new Spalte(0, 14),
		new Spalte(0, 2),
	]),
], 3250, 2150));
