/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 9.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import {FrameOuterOffset} from "./frameOuterOffset";
import {Glass} from "../glass/main";
import {Utils} from "../utils/utils";
import {WindowOptions, WindowOptionsConfig} from "../window/windowOptions";

export class Spalte {
	glass: Glass | undefined;

	constructor(
		public width: number = 0,
		public frameType: number = 0,
		public empty: boolean = false,
		public isTrenner: boolean = false,
		public glassType: number = 0,
		public frameOuterOffset = new FrameOuterOffset(74),
		public frameInnerOffset = new FrameOuterOffset(21),
		public id: string = Utils.generateID()
	) {}
}

export class Reihe {
	spalten: Spalte[] = new Array<Spalte>();
	height: number;
	isTrenner: boolean;

	constructor(spalten: Spalte[] = new Array<Spalte>(), height = 0, isTrenner: boolean = false) {
		this.spalten = spalten;
		this.height = height;
		this.isTrenner = isTrenner;
	}
}


export class GlassSplitType {

	constructor(
		public label = "----",
		public reihen = new Array<Reihe>(),
		public windowWidth = 0,
		public windowHeight = 0,
		public windowOptions: WindowOptionsConfig | undefined = undefined
	) {}
}
