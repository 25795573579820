/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import $ from "jquery";
import {Designer} from "../designer";

export class DebugText {
	constructor(
		public id: string,
		public message: string
	) {}
}

export class Debug {
	debugMode: boolean = false;
	mouseDot: Konva.Circle | undefined;
	text: Konva.Text | undefined;
	rect: Konva.Rect | undefined;
	layer: Konva.Layer | undefined;

	debugSwitch: JQuery<HTMLElement> | undefined;

	customTextes: DebugText[] = new Array<DebugText>();

	constructor(protected designer: Designer) {
		if ( this.designer.stage === undefined ) return;

		this.layer = new Konva.Layer();
		this.designer.stage.on("mousemove touchmove", this.onMove.bind(this));
		this.designer.stage.on("mouseenter", this.onEnter.bind(this));
		this.designer.stage.on("mouseleave", this.onLeave.bind(this));

		this.designer.stage.add(this.layer);
		this.debugSwitch = $('#debugModeSwitch');
		this.debugSwitch.on('change', this.onSwitchChange.bind(this));
	}

	onSwitchChange() {
		this.onLeave();
		if ( this.debugSwitch === undefined ) return;
		this.debugMode = this.debugSwitch.is(':checked');
	}

	onEnter() {
		if ( !this.debugMode ) return;
		this.mouseDot = new Konva.Circle({
			x: 0,
			y: 0,
			radius: 10,
			fill: 'red',
			listening: false
		});

		this.text = new Konva.Text({
			x: 0,
			y: 0,
			text: 'null',
			fill: 'black',
			fontSize: 25,
			listening: false
		});

		if ( this.layer === undefined ) return;
		this.layer.add(this.mouseDot);
		this.layer.add(this.text);
	}

	onLeave() {
		if ( !this.debugMode ) return;
		if ( this.mouseDot != null ) this.mouseDot.destroy();
		if ( this.text != null ) this.text.destroy();
	}

	onMove( element: any ) {
		if ( !this.debugMode ) return;
		if ( this.designer.stage === undefined ) return;

		//////console.log(element);
		const pos = this.designer.stage.getRelativePointerPosition();
		if ( this.mouseDot != null ) {
			this.mouseDot.x(pos.x);
			this.mouseDot.y(pos.y);
		}

		const realScaler = `${this.designer.currentScale}`.replace("0.", "1.");
		if ( this.text !== undefined ) {
			this.text.x(pos.x);
			this.text.y(pos.y + 30);
			this.text.text(`X: ${pos.x}\nY: ${pos.y}`);
			this.text.fontSize((30 * Number(realScaler)));
		}

		if ( element.target.className !== undefined ) {
			const type = element.target.className;
			//const name = element.;
			const xY = `x: ${element.target.x()} y: ${element.target.x()}`;
			const wH = `${element.target.width()}x${element.target.height()}`;

			if ( this.text !== undefined )
				this.text.text(this.text.text() + `\nType: ${type}\nPosition: ${xY} ${wH}`);
		}

		if (this.customTextes.length !== 0 ) {
			for ( const cText of this.customTextes ) {
				if ( this.text !== undefined )
					this.text.text(this.text.text() + "\n" + cText.message);
			}
		}
	}


	addText(id: string, message: string) {
		this.customTextes.push(
			new DebugText(id, message)
		)
	}

	removeText(id: string) {
		const index = this.customTextes.findIndex( ( el ) => el.id === id );
		if ( index !== -1 ) {
			delete this.customTextes[index];
			this.customTextes = this.customTextes.filter( ( el ) => el !== undefined);
		}
	}
}