/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import { message } from "antd";
import Konva from "konva";
import {Divider, Point, WindowObject } from "./testObject";

export class TestDesigner {
    stage: Konva.Stage | undefined;
    layer: Konva.Layer | undefined;
    konvaContainer: HTMLElement | null | undefined;

    mode: number = 0;

    modeObject: Konva.Rect | Konva.Line | Konva.Circle | undefined;
    lineStartPoints: number[] = [];

    group: Konva.Group = new Konva.Group();
    
    windows: WindowObject[] = [];
    selectedWindow: WindowObject | undefined;
    selectedPoint: Point | undefined;

    lineHoverCircle: Konva.Circle | undefined;

    debuggerText: Konva.Text | undefined;

    constructor() {
        this.startUp();
    }

    startUp() {
        this.konvaContainer = document.getElementById('container');
        if ( this.konvaContainer == null ) return

        
        this.stage = new Konva.Stage({
            container: 'container',
            width: this.konvaContainer.offsetWidth,
            height: this.konvaContainer.offsetHeight,
            draggable: false,
        });

        this.stage.on('mouseup', this.onMouseUp.bind(this));
        this.stage.on('mousemove', this.onMouseMove.bind(this));
        this.stage.on('mousedown', this.onMouseDown.bind(this));
        this.stage.on('mousemove', this.onStageMouseMove.bind(this));

        this.layer = new Konva.Layer();

        this.layer.add(this.group);
        this.stage.add(this.layer);

        /*this.debuggerText = new Konva.Text({
            x: 0,
            y: 0,
            text: `World: x[] y[] w[] h[]\nPointer: x[0] y[0]`,
            fill: 'black',
            fontSize: 18
        });
        this.group.add(this.debuggerText);*/
    }

    onStageMouseMove( element: any ) {
        if ( this.stage === undefined ) return;

        const pos = this.stage.getRelativePointerPosition();
        if ( pos === null ) return;

        if ( this.debuggerText != undefined ) {
            this.debuggerText.text(`
                World: x[] y[] w[] h[]\n
                Pointer: x[${pos.x}] y[${pos.y}]
            `);

            this.debuggerText.x(pos.x);
            this.debuggerText.y(pos.y);
        }

        if ( this.mode !== 0 ) return;

        if ( element.target instanceof Konva.Line ) {

            if ( this.lineHoverCircle !== undefined ) {
                this.lineHoverCircle.x(pos.x);
                this.lineHoverCircle.y(pos.y);
            } else {
                this.lineHoverCircle = new Konva.Circle({
                    x: pos.x,
                    y: pos.y,
                    radius: 20,
                    fill: 'red',
                    listening: false
                });

                //console.log('created circle: ', pos.x, pos.y);

                this.group.add(this.lineHoverCircle);
            }
        } else {
            if ( this.lineHoverCircle !== undefined ) {
                this.lineHoverCircle.destroy();
                this.lineHoverCircle = undefined;
            }
        }
    }

    onMouseUp() {
        if ( this.stage === undefined ) return;
        //console.log('{DEBUG} onMouseUp() fired', this.mode);

        if ( this.modeObject instanceof Konva.Rect ) {
            if ( this.modeObject.height() <= 100 || this.modeObject.width() <= 100) {
                this.deleteModeObject();
                return //console.log('zu klein');
            }

            let points: Point[] = [
                new Point(0, 0),
                new Point(0, this.modeObject.height()),
                new Point(this.modeObject.width(), this.modeObject.height()),
                new Point(this.modeObject.width(), 0),
                new Point(0, 0)
            ];

            const nWindow = new WindowObject(this, "window", this.modeObject.x(), this.modeObject.y());
            nWindow.pointsSet(points);
            nWindow.create();
            
            this.windows.push(nWindow);
        }

        if ( this.mode === 2 ) {
            this.selectedWindow = undefined;
            this.selectedPoint = undefined;
            if ( this.modeObject instanceof Konva.Line ) {
                this.modeObject = undefined;
            }

        } else if ( this.mode === 1 ) {
            const pos = this.stage.getRelativePointerPosition();
            if ( pos === null ) return;

            const window = this.getWindowBetween(this.lineStartPoints[0], this.lineStartPoints[1]);
            if ( window === undefined ) {
                this.deleteModeObject();
                return message.error('kein Fenster gefunden!');
            }
            if ( window.rect === undefined ) return this.deleteModeObject();

            let pointX: number = this.lineStartPoints[0];
            let pointY: number = this.lineStartPoints[1];

            let distanceX = pos.x - pointX;

            let divider: Divider | undefined = undefined;

            if ( distanceX >= 100 || distanceX <= -100 ) {
                //pointX = pos.x;
                // horizontal
                divider = new Divider(
                    new Point(pointX, pointY),
                    new Point(pos.x, 0),
                    false
                );

            } else {
                // vertikal
                //pointY = pos.y;
                divider = new Divider(
                    new Point(pointX, pointY),
                    new Point(0, pos.y)
                );
            }

            if ( divider !== undefined ) {
                //console.log('pushed divider:', divider);
                window.divider.push(divider);
            }

            this.deleteModeObject();

            window.update();

        }

        this.deleteModeObject();
    }

    deleteModeObject() {
        if ( this.modeObject !== undefined ) {
            this.modeObject.destroy();
            this.modeObject = undefined;
        }
    }

    update() {
        //console.log('update called');
    }

    onMouseMove() {
        if ( this.stage === undefined ) return;

        const pos = this.stage.getRelativePointerPosition();
        if ( pos === null ) return;

        //console.log('mouse move in mode:', this.mode);

        if ( this.mode === 0 ) {
            if ( this.modeObject === undefined ) return;
            if ( this.modeObject instanceof Konva.Rect ) {
                this.modeObject.width( pos.x - this.modeObject.x() );
                this.modeObject.height( pos.y - this.modeObject.y() );
                //console.log(this.modeObject.x(), pos.x, (this.modeObject.x() - pos.x), (this.modeObject.x() + pos.x));
            }
        } else if ( this.mode === 1 ) {
            if ( this.modeObject === undefined ) return;

            if ( this.modeObject instanceof Konva.Line ) {
                let pointX: number = this.lineStartPoints[0];
                let pointY: number = this.lineStartPoints[1];

                let distanceX = pos.x - pointX;

                if ( distanceX >= 100 || distanceX <= -100 ) {
                    pointX = pos.x;
                } else {
                    pointY = pos.y;
                }
                this.modeObject.points([...this.lineStartPoints, pointX, pointY]);

                for ( const window of this.windows ) {
                    window.update();
                }

            }
        } else if ( this.mode === 2 ) {
            if ( this.modeObject instanceof Konva.Line ) {
                if ( this.modeObject.id() === "divider" ) {

                    for ( const window of this.windows ) {
                        const divider = window.divider.find( ( divid ) => divid.object === this.modeObject);
                        if ( divider === undefined ) continue;

                        divider.point1.x = pos.x;

                        return window.update();
                    }
                }
            }


            if ( this.selectedWindow === undefined ) return;
            if ( this.selectedPoint === undefined ) return;

            this.selectedPoint.x = pos.x - this.selectedWindow.x;
            this.selectedPoint.y = pos.y - this.selectedWindow.y;

            const pointIndex = this.selectedWindow.points.indexOf(this.selectedPoint);
            if ( pointIndex === this.selectedWindow.points.length - 1 ) {
                this.selectedWindow.points[0].x = pos.x - this.selectedWindow.x;
                this.selectedWindow.points[0].y = pos.y - this.selectedWindow.y;
            }
            this.selectedWindow.update();

        }

    }

    onMouseDown( element: any) {
        if ( this.stage === undefined ) return;
        const pos = this.stage.getRelativePointerPosition();
        if ( pos === null ) return;

        //console.log('mouseDown', this.mode);

        if ( this.mode === 0 ) {
            this.modeObject = new Konva.Rect({
                x: pos.x,
                y: pos.y,
                width: 0,
                height: 0,
                stroke: 'black',
                strokeWidth: 5,
                dash: [10, 20]
            });

            this.group.add(this.modeObject);
        } else if ( this.mode === 1 ) {
            this.lineStartPoints = [pos.x, pos.y];
            this.modeObject = new Konva.Line({
                points: [pos.x, pos.y],
                stroke: 'red',
                strokeWidth: 5,
                dash: [10, 20]
            });
            this.group.add(this.modeObject);
        } else if ( this.mode === 2 ) {
            if ( element.target instanceof Konva.Circle ) {
                let realID = element.target.id() as string;
                const wID = realID.slice(0, realID.indexOf('_'));

                const window = this.getWindow(wID);
                if ( window === undefined ) return;

                const point = window.points.find(
                    ( point ) => point.circle !== undefined && point.circle.id() === realID
                );
                this.selectedWindow = window;
                this.selectedPoint = point;
            } else if ( element.target instanceof Konva.Line ) {
                const line = element.target as Konva.Line;
                if ( line.id() === "divider") {

                    //find the divider array object
                    for ( const window of this.windows ) {
                        const divider = window.divider.find( ( divid ) => divid.object === line);
                        if ( divider === undefined ) continue;

                        this.modeObject = line;

                    }
                }
            }
        }
    }

    getWindow(id: string): WindowObject | undefined {
        for ( const window of this.windows ) {
            if ( window.id.includes(id) ) return window;
        }
        return undefined;
    }

    getWindowBetween( x: number, y: number ): WindowObject | undefined {
        for ( const window of this.windows ) {
            const maxX = this.getMaxValueOfPoints(window.points);
            const maxY = this.getMaxValueOfPoints(window.points, true);

            //console.log('sX:', x, 'sY:', y, 'wX:', window.x, 'wY', window.y, 'max:', maxX, maxY);

            if ( window.x < x && window.y < y && maxX > x && maxY > y)
                return window;
        }

        return undefined;
    }

    getMaxValueOfPoints( points: Point[], useYAxis: boolean = false ): number {
        let maxi: number = -1;
        for ( const point of points ) {
            if ( !useYAxis ) {
                if ( point.x > maxi ) maxi = point.x;
            } else {
                if ( point.y > maxi ) maxi = point.y;
            }
        }

        return maxi;
    }

    startCollisionTest(start: Point, stop: Point) {

    }
}

