/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {FrameOuterOffset} from "../models/frameOuterOffset";


export class GlassOptions {
	id: string = "";
	x = 0;
	y = 0;
	width = 0;
	height = 0;
	glassType = 0;
	frameType = 0;
	empty = false;
	isTrenner = false;
	frameOuterOffset: FrameOuterOffset = {
		top: 74,
		left: 74,
		right: 74,
		bottom: 74
	}
	frameInnerOffset: FrameOuterOffset = {
		top: 21,
		left: 21,
		right: 21,
		bottom: 21
	};



}