/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 7.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PageLogin} from "./app/react/pages/login";
import {PageProject} from "./app/react/project";
import {DesignerPage} from "./app/react/designer/designer";
import {PageTest} from "./app/react/designer/test";
import {API} from './app/core/API';
import {PageExport} from "./app/react/export";
import {PageSettings} from "./app/react/settings";
import {LayoutComponent} from "./app/react/Layout";

import './assets/css/app.css';
import './index.css';
import './assets/fonts/inter/inter.css';
import {PageNotFound} from "./app/react/notfound";
import { ClientsPage } from './app/react/pages/clients';
import {ClientPage} from "./app/react/pages/client";
import {PagePrices} from "./app/react/pages/priceManager";
import moment from "moment";
import 'moment/locale/de';
import {PagePriceTable} from "./app/react/pages/priceTable";
import {LayoutSidebar} from "./app/react/LayoutSidebar";
import {ArticlesTypes} from "./app/react/pages/articles/types";
import {ArticleCategories} from "./app/react/pages/articles/categorie";

moment.locale('de');


document.addEventListener("loadstart", ( event: any ) => {
	console.log(event);
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

if ( process.env.NODE_ENV === "development" ) {
	//API.URL = "http://192.168.189.59:3030"; //wenn andere im Netzwerk können.
	API.URL = "http://localhost:3030"; //wenn man nur lokal testen möchte
} else if ( process.env.NODE_ENV === "production" ) {
	API.URL = "https://api.streif.mimann.eu";
}

root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/login" element={<PageLogin />} />
			<Route path="designer/:project/:version/:history" element={<DesignerPage />} />
			<Route path="designer/:project/:version" element={<DesignerPage />} />
			<Route path="test/" element={<PageTest />} />
			<Route path="/" element={<LayoutComponent />}>
				<Route path="/" element={<PageProject />} />
				<Route path="/projects" element={<PageProject />} />
				<Route path="/clients" element={<ClientsPage />} />
				<Route path="/client/:id" element={<ClientPage />} />
				<Route path="/export/:project/:version" element={<PageExport />} />
				<Route path="/project/:project/settings/:version" element={<PageSettings />} />
				<Route path="*" element={<PageNotFound />} />
			</Route>
			<Route path="/settings" element={<LayoutSidebar />}>
				<Route path="/settings/articles/types" element={<ArticlesTypes />} />
				<Route path="/settings/articles/categories" element={<ArticleCategories />} />
				<Route path="/settings/price/types" element={<PagePrices />} />
				<Route path="/settings/price/table" element={<PagePriceTable />} />
			</Route>
		</Routes>
	</BrowserRouter>
);
