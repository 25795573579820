/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 13.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/



export enum Directions {
    RIGHT,
    BOTTOM,
    TOP,
    LEFT,
}