/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 29.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import axios from 'axios';
import {Kunde} from "../interfaces/kunde";

export class API {
	static URL: string = "";

	static projectsTemp: any[];
	static kundenTemp: Kunde[];

	static defaultOptions: any = {
		headers: {
			'Content-Type': 'application/json'
		}
	}

	static async getKunden(): Promise<any> {
		const response =  await axios.post(
			API.URL + "/kunden", {
				token: localStorage.getItem("token")
			},
			this.defaultOptions
		);

		//console.log(response);

		if ( !response.data.errors ) {
			API.kundenTemp = response.data.data;
			return response.data.data;
		}
		return { result: false, message: 'server nicht erreichbar' };
	}

	static async getProjects(): Promise<any> {
		const response =  await axios.post(
			API.URL + "/projects", {
				token: localStorage.getItem("token")
			},
			this.defaultOptions
		);

		////console.log(response);

		if ( !response.data.errors ) {
			return response.data.data;
		}
		return { result: false, message: 'server nicht erreichbar' };
	}

	static async check(token: string) {
		return await axios.post(
			API.URL + "/users/check",
			{ token: token }
		);
	}

	static async checkLoginState(): Promise<any> {
		try {
			const token = localStorage.getItem('token');
			console.log('checkLoginState', token);
			if ( token !== null)  {
				const res = await this.check(token);
				if ( !res.data.errors ) {
					return res.data.data;
				}
				return undefined;
			}
		} catch ( error ) {
			return undefined;
		}
		return undefined;
	}

	static async logout(token: string | null): Promise<boolean> {
		if ( token == null ) return false;
		return await axios.post(
			API.URL + "/users/logout",
			{ token: token },
			this.defaultOptions
		)
	}
}