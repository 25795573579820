/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 5.12.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {
    Button,
    Drawer,
    Form,
    FormInstance,
    Input,
    List,
    message,
    Modal,
    Select,
    Space,
    Tabs,
    Tooltip,
    Tree
} from "antd";
import {Designer} from "../../designer";
import {Project} from "../../interfaces/project";
import {VersionHistory} from "../../interfaces/versionHistory";
import moment from "moment/moment";
import {DataNode} from "antd/lib/tree";
import {Versions} from "../../interfaces/versions";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {Kunde} from "../../interfaces/kunde";
import {API} from "../../core/API";
import axios from "axios";
import {DesignerController} from "../designer/designer";
import {Rect} from "../../window/main";


export function SecondaryMenu(props: any) {
    const nav = useNavigate();
    const [ projectInfos ] = Form.useForm();
    return <SecondaryMenuComponent
        parent={props.parent}
        designer={props.designer}
        nav={nav}
        project={props.project}
        versionID={props.versionID}
        formProjectInfo={projectInfos}
        key={"SecondaryMenuComponentTier2"}
    />
}



interface iProps {
    parent: DesignerController;
    designer: Designer;
    nav: NavigateFunction;
    project: Project;
    versionID: string;
    formProjectInfo: FormInstance
}
interface iStates {
    isOpen: boolean;
    clients: Kunde[];
    projectName: string;
}

class SecondaryMenuComponent extends React.Component<iProps, iStates> {
    state: iStates = {
        isOpen: false,
        clients: [],
        projectName: this.props.project.name
    }

    async componentDidMount() {
        await API.getKunden();
        this.setState({
            clients: API.kundenTemp
        });
    }

    toggleDrawer() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    getCurrentVersion (): Versions | undefined {
        if ( this.props.project === undefined ) return undefined;
        return this.props.project.versions.find( ( ver ) => ver.id === this.props.versionID );
    }

    getHistoriesHeader() {
        if ( this.props.project === undefined ) return <div>Project not found</div>;
        const version = this.getCurrentVersion();
        if ( version === undefined ) return <div>Version not found</div>;
        return <div>Version: {version.name} Verlauf</div>
    }

    getHistoriesData() {
        if ( this.props.project === undefined ) return [];

        const version = this.getCurrentVersion();
        if ( version === undefined ) return [];

        return version.histories.sort( (a, b) => {
            if ( a.created > b.created ) return -1;
            if ( a.created < b.created ) return 1;
            return 0;
        });
    }

    getWindowsLayers(): DataNode[] | undefined {
        if ( this.props.designer === undefined ) return undefined;
        const projectData = this.props.designer.windows;

        let treeData: DataNode[] = [];

        const firstWindow = projectData[0];
        if ( firstWindow === undefined ) return undefined;


        /*
        treeData.push({
            title: firstWindow.options.id,
            key: firstWindow.options.id,
            children: this.getWindowChildrens(firstWindow)
        });*/

        return treeData;
    }

    getWindowChildrens(window: Rect): DataNode[] | undefined {
        if ( this.props.designer === undefined ) return undefined;
        const windows = this.props.designer.windows;

        const data: DataNode[] = [];

        for (const aWindow of windows) {
            if ( aWindow === undefined ) continue;
            if ( aWindow.options === undefined ) continue;
            if ( aWindow.options.parentID === window.options.id ) data.push({
                title: aWindow.options.id,
                key: aWindow.options.id,
                children: (aWindow.options.parentID != undefined ? this.getWindowChildrens(aWindow) : undefined)
            });
        }

        return data;
    }

    onLayersDragged( values: any ) {
        this.showChildrens(values.node);
    }

    showChildrens ( node: any ) {
        for ( const children of node.children) {
            this.showChildrens(children);
        }
    }

    onLayersClick( values: any ) {
        if ( this.props.designer === undefined ) return;
        this.props.designer.highlightWindow(values.target.innerText);
    }

    onHistoryClick(historyID: string ) {
        //console.log('CLICKED ON HISTORY LINK', historyID);
        window.location.href = `/designer/${this.props.project.id}/${this.props.versionID}/${historyID}`;
        //this.props.navigation(`/designer/${this.props.projectID}/${this.props.versionID}/${historyID}`);
    }

    backToProjects() {
        this.props.nav("/");
    }

    editClient() {
        this.props.nav(`/client/${this.props.project.kunde.Vorgangsnummer}`);
    }

    onDrawerSave() {
        this.props.formProjectInfo.submit();
    }

    async onProjectInfosSave( values: any ) {
        const api = await axios.post(API.URL + '/project/info/save', {
            token: localStorage.getItem('token'),
            projectID: this.props.project.id,
            versionID: this.props.versionID,
            ...values
        });

        //console.log(api);
        if ( api.data.errors ) return message.error(api.data.message);


        this.props.parent.refreshProjectData(api.data.data);

        message.success('Aktuallisiert');
    }

    onProjectNameChange( value: any ) {
        //console.log(value);
        this.setState({projectName: value.target.value});
    }

    render() {
        return <>
            <Button key="SecondaryMenuCompButton" type="link" icon={<i className="fa-solid fa-bars"></i>} onClick={this.toggleDrawer.bind(this)} />
            <Drawer
                key={"SecondaryMenuCompDrawer"}
                footer={[
                    <Button type={"primary"} onClick={this.onDrawerSave.bind(this)}>Speichern</Button>
                ]}
                visible={this.state.isOpen}
                placement="left"
                title={
                    <Space size="small" style={{marginTop: 5}}>
                        <Tooltip title="Projektübersicht">
                            <Button
                                type={"link"}
                                icon={<i className="fas fa-home"></i>}
                                onClick={this.backToProjects.bind(this)} />
                        </Tooltip>
                        <span>{this.state.projectName}</span>
                    </Space>
                }
                bodyStyle={{
                    padding: 0,
                    paddingTop: 5
                }}
                onClose={this.toggleDrawer.bind(this)}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    size={"small"}
                    onFinish={this.onProjectInfosSave.bind(this)}
                    initialValues={{
                        projectName: this.props.project.name,
                        versionName: this.getCurrentVersion()?.name
                    }}
                    form={this.props.formProjectInfo}
                >
                    <Form.Item label={"Kunde"} name="clientID" key={"SecondaryMenu_ClientID"}>
                        <Input.Group compact>
                            <Select
                                size={"small"}
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                defaultValue={this.props.project.kunde.Vorgangsnummer}
                            >
                                { this.state.clients !== undefined && this.state.clients.length != 0 &&
                                    this.state.clients.map( ( client ) => {
                                        return <Select.Option value={client.Vorgangsnummer}>{client.Name} {client.Vorname}</Select.Option>
                                    })
                                }
                            </Select>
                            <Button type={"link"} icon={<i className={"fas fa-edit"}></i>} onClick={this.editClient.bind(this)}/>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item label="Vorgang: " name="projectName" key={"SecondaryMenu_ProjectName"}>
                        <Input value={this.state.projectName} onChange={this.onProjectNameChange.bind(this)}/>
                    </Form.Item>

                    <Form.Item label="Position: " name="versionName" key={"SecondaryMenu_VersionName"}>
                        <Input />
                    </Form.Item>
                </Form>

                <Tabs defaultActiveKey="1" style={{marginLeft: 10}}>
                    <Tabs.TabPane tab="Ebenen" key="___1">
                        <Tree
                            draggable
                            blockNode
                            showIcon
                            showLine
                            defaultExpandAll={true}
                            autoExpandParent={true}
                            onDrop={this.onLayersDragged.bind(this)}
                            onClick={this.onLayersClick.bind(this)}
                            treeData={this.getWindowsLayers()}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Verlauf" key="___2">
                        <List
                            dataSource={this.getHistoriesData()}
                            renderItem={ (item: VersionHistory) => {
                                return <List.Item key={item.id}>
                                    erstellt: {moment.unix(item.created).format("DD.MM.YYYY HH:mm:ss") }
                                    <Button type={"link"} onClick={( e) => {
                                        this.onHistoryClick(item.id);
                                    }}>Anwenden</Button>
                                </List.Item>
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Drawer>
        </>
    }

}