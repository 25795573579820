/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 28.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {Designer} from "../designer";
import {GlassSplitType} from "../models/splitTypes";
import {GlassFrameFlip, GlassFrameType} from "../models/frameTypes";
import {RALColors} from "../interfaces/RALColors";
import colorsJson from '../config/ralColors.json';

export class ConfigController {
	static splitTypes: GlassSplitType[] = new Array<GlassSplitType>();
	static frameTypes: GlassFrameType[] = new Array<GlassFrameType>();
	static frameFlips: GlassFrameFlip[] = new Array<GlassFrameFlip>();
	static colorsWAL: RALColors[] = new Array<RALColors>();

	static kopplung: number[] = [
		20, 40, 50, 100, 350, 600
	];

	static sprossen: number[] = [
		20, 40
	];

	constructor(private designer: Designer) {
		console.log('[ConfigController] Started');
		ConfigController.colorsWAL = [];

		for ( const walColor of colorsJson ) {
			const rr: any = walColor;
			const arr: RALColors = {
				RAL: rr['RAL'],
				RGB: rr['RGB'].split('-').map((val: string) => Number(val)),
				HEX: rr['HEX'],
				Label: rr['Label']
			}
			ConfigController.colorsWAL.push(arr);
		}
	}
}