/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 22.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

export class Default {
	static zoom = 1;
	static minZoom = 0.1;
	static zoomStep = 0.1;
}


export class Colors {
	static glassFillStart = "#bbebf7";
	static glassFillEnd = "#fff";

	static glassOrnament = "lightgray";

	static sprossenFill = "lightgray";
	static sprossenStroke = "black";

	static frameFill = "#fff";
	static frameStroke = "#000";

	static frameHandle = "#fff";
	static frameHandleStroke = "#9B9EA3";

	static metricFill = "#757575";
	static metricStroke = "#333";
	static metricText = "white";

	static trennerFillStart = "transparent";
	static trennerFillEnd = "transparent";
}


export const glassTypesList: string[] = [
	"Standard", "Ornament"
]