/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 9.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/



import {ConfigController} from "../core/configController";
import {GlassFrameFlip} from "../models/frameTypes";

ConfigController.frameFlips.push(
    new GlassFrameFlip(3, 5),
    new GlassFrameFlip(5, 3),
    new GlassFrameFlip(4, 6),
    new GlassFrameFlip(6, 4),
    new GlassFrameFlip(7, 8),
    new GlassFrameFlip(8, 7),
    new GlassFrameFlip(9, 10),
    new GlassFrameFlip(10, 9),
    new GlassFrameFlip(13, 14),
    new GlassFrameFlip(14, 13),
)