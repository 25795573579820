/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 27.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {
    Button,
    Col,
    Drawer,
    Dropdown,
    Form,
    FormInstance,
    Input,
    List,
    message,
    notification,
    Popover,
    Row,
    Space, Switch, Tabs
} from "antd";
import {DesignerController} from "../designer/designer";
import axios from "axios";
import {API} from "../../core/API";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {WindowsConfigure} from "./windowsConfigure";
import {Designer} from "../../designer";


export function SettingsModule(props: any) {
    const [ form ] = Form.useForm();
    const nav = useNavigate();
    return <Settings nav={nav} parent={props.parent} form={form} debug={props.debug} designer={props.designer} />
}

interface iProps {
    parent: DesignerController;
    designer: Designer;
    form: FormInstance;
    debug: string | undefined;
    nav: NavigateFunction;
}
interface iStates {
    isShowing: boolean;
}

class Settings extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);
        this.state = {
            isShowing: false
        }
    }

    onSubmit() {
        this.props.form.submit();
    }

    onClose() {
        this.setState({
            isShowing: false
        });
    }

    onShow() {
        this.setState({
            isShowing: true
        });
    }

    onFormFinish( values: any ) {
        this.setState({ isShowing: false });
    }

    async onLogout() {
        try {
            const api = await axios.post(API.URL + '/users/logout', {
                token: localStorage.getItem('token')
            });
            if ( api === undefined ) return message.error('API Fehler');
            if ( api.data.result === false ) return message.error((api.data.message ? api.data.message : 'Unbekannter Fehler'));

            localStorage.clear();

            message.success('Erfolgreich abgemeldet!');
            this.props.nav('/');
        } catch ( error ) {
            notification.error({
                message: 'Fehler beim Abmelden',
                description: 'API-Server nicht erreichbar!'
            });
        }

    }

    render() {
        return <>
            <Button onClick={this.onShow.bind(this)} type={"link"} icon={<i className="fa-solid fa-gear"></i>} />
            <Drawer
                title="Einstellungen"
                placement="right"
                width={500}
                onClose={this.onClose.bind(this)}
                visible={this.state.isShowing}
                bodyStyle={{
                    padding: 5
                }}
                extra={
                    <Space>
                        <Button onClick={this.onClose.bind(this)}>Schliessen</Button>
                        <Button type="primary" onClick={this.onSubmit.bind(this)}>Speichern</Button>
                    </Space>
                }
            >
                <Form
                    layout={"vertical"}
                    form={this.props.form}
                    onFinish={this.onFormFinish.bind(this)}
                >
                    <Tabs defaultActiveKey={"designer_settings_general"}>
                        <Tabs.TabPane tab="Fenster" key="designer_settings_windows" tabKey="designer_settings_windows">
                            <WindowsConfigure designer={this.props.designer} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Version" key={"designer_settings_general"} tabKey={"designer_settings_general"}>
                            <Form.Item valuePropName="checked">
                                <Switch checkedChildren="Neubau" unCheckedChildren="Altbau"/>
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fenstersystem"
                                        name="windowSystem"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fensterbanksystem"
                                        name="fensterBankSystem"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Dichtungsfarbe"
                                        name="Dichtungsfarbe"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Alufensterbank"
                                        name="Alufensterbank"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Montagekosten"
                                        name="Montagekosten"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fenstersystem"
                                        name="Fenstersystem"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Insektenschutz"
                                        name="Insektenschutz"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sonstiges" key={"designer_settings_stuff"} tabKey={"designer_settings_stuff"}>

                            <Popover placement="bottomLeft" title={"Debug Information"} content={this.props.debug} trigger="hover">
                                <a id="debugMode" className="d-none d-md-block mx-2">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="debugModeSwitch" />
                                        <label className="form-check-label" htmlFor="debugModeSwitch">Mausverfolgungs-Informationen</label>
                                    </div>
                                </a>
                            </Popover>

                            <Space direction={"vertical"}>
                                <Button
                                    type={"link"}
                                    icon={<i className="fa-solid fa-power-off" style={{marginRight: 20}}></i>}
                                    onClick={this.onLogout.bind(this)}
                                >
                                    Abmelden
                                </Button>
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
                <div>
                    {this.props.debug}
                </div>
            </Drawer>
        </>
    }
}