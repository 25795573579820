/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Colors} from "../config/default";
import {Glass} from "../glass/main";
import {ConfigController} from "../core/configController";
import Line = Konva.Line;



export class Lines {
	object: Line | undefined;

	constructor(public glass: Glass) {
		this.create();
	}


	create() {
		if ( this.glass.rect === undefined ) return;
		const rect = this.glass.rect.getClientRect();
		const opts = this.glass.options;

		this.object = new Konva.Line({
			x: rect.x,
			y: rect.y,
			points: this.generateLines((opts.frameType ? opts.frameType : 0)),
			stroke: Colors.frameHandleStroke
		});

		this.glass.addGroup(this.object);
	}

	generateLines(type = 0): number[] {
		if ( this.glass.rect === undefined ) return [0, 0];
		const rect = this.glass.rect.getClientRect();
		const opts = this.glass.options;

		const glassType = ConfigController.frameTypes[type];
		if ( glassType == null ) {
			////console.log('error: no glassType found!');
			return [0, 0];
		}
		if ( glassType.lines == null ) return [0, 0];

		////console.log('frameLines generateLines on', type, this.glass.window.id);
		////console.log(glassType.lines(rect, opts.frameOuterOffset, opts.frameInnerOffset));

		return glassType.lines(rect, opts.frameOuterOffset, opts.frameInnerOffset, this.glass.window.isPreset);
	}

	destroy() {
		if ( this.object != null ) this.object.destroy();
	}

	update() {
		this.destroy();
		this.create();
	}
}