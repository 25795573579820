/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Colors} from "../config/default";
import {Glass} from "../glass/main";
import Line = Konva.Line;


export class InnerFrame {
    frameInnerTop: Line | undefined;
    frameInnerLeft: Line | undefined;
    frameInnerBottom: Line | undefined;
    frameInnerRight: Line | undefined;


    constructor(public glass: Glass) {
        this.create();
    }

    create() {
		if ( this.glass.rect === undefined ) return;
        const rect = this.glass.rect.getClientRect();

        this.frameInnerTop = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generateFrameInnerPoints(0),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
        });
        this.frameInnerLeft = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generateFrameInnerPoints(1),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
        });
        this.frameInnerBottom = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generateFrameInnerPoints(2),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
        });
        this.frameInnerRight = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generateFrameInnerPoints(3),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
        });


        this.glass.addGroup(this.frameInnerTop);
        this.glass.addGroup(this.frameInnerLeft);
        this.glass.addGroup(this.frameInnerBottom);
        this.glass.addGroup(this.frameInnerRight);
    }

    generateFrameInnerPoints(type = 0) : number[] {
		if ( this.glass.rect === undefined ) return [0, 0];

        const rect = this.glass.rect.getClientRect();
        const opts = this.glass.options;
		//console.log('glass options innerframe:', opts);



        if ( type === 0 ) {
            return [
                opts.frameOuterOffset.left, opts.frameOuterOffset.top,
                opts.frameOuterOffset.left + opts.frameInnerOffset.left, opts.frameOuterOffset.top + opts.frameInnerOffset.top,
                rect.width - opts.frameOuterOffset.right - opts.frameInnerOffset.right, opts.frameOuterOffset.top + opts.frameInnerOffset.top,
                rect.width - opts.frameOuterOffset.right, opts.frameOuterOffset.top
            ];
        } else if ( type === 1 ) {
            return [
                opts.frameOuterOffset.left, opts.frameOuterOffset.left,
                opts.frameOuterOffset.left + opts.frameInnerOffset.left, opts.frameOuterOffset.left + opts.frameInnerOffset.left,
                opts.frameOuterOffset.left + opts.frameInnerOffset.left, rect.height - opts.frameOuterOffset.bottom - opts.frameInnerOffset.bottom,
                opts.frameOuterOffset.left, rect.height - opts.frameOuterOffset.bottom
            ];
        } else if ( type === 2 ) {
            return [
                opts.frameOuterOffset.left, rect.height - opts.frameOuterOffset.bottom,
                opts.frameOuterOffset.left + opts.frameInnerOffset.left, rect.height - opts.frameOuterOffset.bottom - opts.frameInnerOffset.bottom,
                rect.width - opts.frameOuterOffset.right - opts.frameInnerOffset.right, rect.height - opts.frameOuterOffset.bottom - opts.frameInnerOffset.bottom,
                rect.width - opts.frameOuterOffset.right, rect.height - opts.frameOuterOffset.bottom
            ];
        } else if ( type === 3 ) {
            return [
                rect.width - opts.frameOuterOffset.right, opts.frameOuterOffset.right,
                rect.width - opts.frameOuterOffset.right - opts.frameInnerOffset.right, opts.frameOuterOffset.right + opts.frameInnerOffset.right,
                rect.width - opts.frameOuterOffset.right - opts.frameInnerOffset.right, rect.height - opts.frameOuterOffset.bottom - opts.frameInnerOffset.bottom,
                rect.width - opts.frameOuterOffset.right, rect.height - opts.frameOuterOffset.bottom
            ]
        } else {
            return [0, 0, 0, 0];
        }
    }

    getFrameColor() {
		//return 'white';
        return ( this.glass.window === undefined ? Colors.frameFill : this.glass.window.options.frameColor);
    }

    destroy() {
        if ( this.frameInnerTop !== undefined ) this.frameInnerTop.destroy();
        if ( this.frameInnerLeft !== undefined ) this.frameInnerLeft.destroy();
        if ( this.frameInnerRight !== undefined ) this.frameInnerRight.destroy();
        if ( this.frameInnerBottom !== undefined ) this.frameInnerBottom.destroy();
    }

    update() {
        this.destroy();
        this.create();
    }

}