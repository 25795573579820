/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import $ from 'jquery';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {v4 as uuidv4} from 'uuid';
import {Modal} from "bootstrap";

let toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
});


export class Utils {
	static generateID(): string {
		return uuidv4();
	}

	static isElementShow( HTMLSelector: string ): boolean {
		return $(HTMLSelector).hasClass('show');
	}

	static toast(message: string, type: SweetAlertIcon = "error") {
		return toast.fire({
			icon: type,
			title: message
		});
	}

	static modal(title: string): Util_Modal {
		return new Util_Modal(title);
	}

	static notify(title: string, message: string, icon: SweetAlertIcon = "error", footer: string | undefined = undefined) {
		Swal.fire({
			icon: icon,
			title: title,
			text: message,
			footer: footer
		});
	}

	static prompt(title: string, message: string, icon: SweetAlertIcon = "success" ) {

	}

	static animatePageToShow() {
		const page = document.getElementById("page");
		if ( page == undefined ) return;
		let opacity = Number(page.style.opacity);
		opacity += .05;
		page.style.opacity = opacity.toString();
		if ( Number(opacity) > 1.0 ) return;

		//////console.log('pageShowing', opacity);
		setTimeout(Utils.animatePageToShow, 20);
	}

	static showPage() {
		const page = document.getElementById("page");
		if ( page == undefined ) return;

		page.style.opacity = "0.0";

		Utils.animatePageToShow();
	}

	static animateLoaderToHide() {
		const loader = document.getElementById("loader-container");
		if ( loader === null ) return;

		loader.style.display = "block";

		let opacity = Number(loader.style.opacity);
		opacity -= 0.05;
		loader.style.opacity = opacity.toString();

		if ( opacity <= 0 ) {
			loader.remove();
			return;
		}
		setTimeout(Utils.animateLoaderToHide, 20);
	}

	static hideLoader() {
		const loader = document.getElementById("loader-container");
		if ( loader === null ) return;
		loader.style.opacity = "1.0";

		Utils.animateLoaderToHide();
	}
}


export class Util_Modal {
	public message: string | undefined;
	public type: string = "error";

	modalId: string = "modalMessage";

	bsModal: Modal | undefined;

	constructor(
		public title: string
	) {}

	setMessage( message: string ): this {
		this.message = message;
		return this;
	}

	setType( type: string ): this {
		this.type = type;
		return this;
	}

	setTitle( title: string ): this {
		this.title = title;
		return this;
	}

	show() {
		$(document.body).append(`

		<div class="modal modal-alert position-fixed d-block py-5" tabindex="-1" role="dialog" id="${this.modalId}">
			<div class="modal-dialog">
				<div class="modal-content rounded-3 shadow">
					<div class="modal-body p-4 text-center">
						<h5 class="mb-0">${this.title}</h5>
						<p class="mb-0">${this.message}</p>
					</div>
					<div class="modal-footer flex-nowrap p-0 text-center">
						<button data-bs-dismiss="modal" type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-end"><strong>Schliessen</strong></button>
					</div>
				</div>
			</div>
		</div>`)


		this.bsModal = new Modal('#' +this.modalId);
		this.bsModal.show();
		const modalListener = document.getElementById(this.modalId);
		if ( modalListener === null ) return;
		modalListener.addEventListener('hidden.bs.modal', this.onClose.bind(this));
	}

	onClose( event: any ) {
		//////console.log(event);
		this.destroy();
	}

	destroy() {
		$('#' +this.modalId).remove();
	}
}
