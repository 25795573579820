/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 22.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Button, Checkbox, Form, FormInstance, Input, message, Modal} from "antd";
import {useForm} from "antd/es/form/Form";
import {NavigateFunction, useNavigate} from "react-router-dom";
import axios from "axios";
import {API} from "../../core/API";
import {Project} from "../../interfaces/project";
import {ProjectController} from "../project";

interface iPropsFunc {
    projectID: string;
    parent: ProjectController;
}

export function ProjectCopy(props: iPropsFunc) {
    const [ cForm ] = useForm();
    const navigation = useNavigate();
    return <ProjectCopyComponent
        projectID={props.projectID}
        form={cForm}
        nav={navigation}
        parent={props.parent}
    />
}


interface iProps extends iPropsFunc {
    form: FormInstance;
    nav: NavigateFunction;
}

interface iStates {
    show: boolean;
    isSaving: boolean;
}

class ProjectCopyComponent extends React.Component<iProps, iStates> {
    constructor(props: iProps) {
        super(props);

        this.state = {
            show: false,
            isSaving: false
        }
    }

    showModal() {
        this.setState({show: true});
    }

    onModalOk() {
        this.props.form.submit();
    }

    onModalCancel() {
        this.setState({show: false});
    }

    async onFormFinish( values: any ) {
        this.setState({isSaving: true});

        const api = await axios.post(API.URL + '/project/copy', {
            token: localStorage.getItem('token'),
            projectID: this.props.projectID,
            name: values.name
        });

        if ( api === undefined ) return message.error('API Fehler');
        if ( api.data.errors ) return message.error((api.data.message ? api.data.message : 'Unbekannter Fehler'));


        this.setState({show: false, isSaving: false});

        const data = api.data.data as Project;

        if ( values.openAfterSave ) {
            this.props.nav(`/designer/${data.id}/${data.versionID}`);
        } else {
            await this.props.parent.getDatas();
        }
    }

    render() {
        return <>
            <Button type={"link"} icon={<i className="fa-solid fa-copy"></i>} onClick={this.showModal.bind(this)} />
            <Modal
                visible={this.state.show}
                onOk={this.onModalOk.bind(this)}
                onCancel={this.onModalCancel.bind(this)}
            >
                <Form
                    layout={"vertical"}
                    form={this.props.form}
                    onFinish={this.onFormFinish.bind(this)}
                >
                    <Form.Item label="Öffnen nach Speichern" name="openAfterSave">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Neuer Name" name="name">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
}