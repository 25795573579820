/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 22.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {
    Button,
    Col, Divider,
    Drawer,
    Form,
    FormInstance,
    Input,
    message,
    Popconfirm,
    Row,
    Space,
    Table,
    Tag,
    Tooltip
} from "antd";
import {API} from "../../core/API";
import {Kunde} from "../../interfaces/kunde";
import {ColumnsType} from "antd/lib/table";
import {useNavigate} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {ClientForm} from "../component/forms/client";
import axios from "axios";


export function ClientsPage() {
    const nav = useNavigate();
    const [ createForm ] = useForm();
    return <Clients navigation={nav} clientCreateForm={createForm}/>
}


interface IProps {
    navigation: any;
    clientCreateForm: FormInstance;
}

interface IState {
    kunden: any[];
    projects: any[];
    filteredList: any[];
    show: boolean;
    filter: string;
    logout: boolean;
    isDeleteSomeThing: boolean;
    isCreatingClient: boolean;
}

class Clients extends React.Component<IProps, IState> {
    filterInput: React.RefObject<any>;

    tableColumns: ColumnsType<Kunde> = [
        { title: 'Nachname & Vorname', key: 'table_name',
            render: (_, record) => (
                <Button type={"link"} onClick={() => this.open(record.Vorgangsnummer)}>{record.Name} {record.Vorname}</Button>
            ),
            filterSearch: true,
            filterMode: 'tree',
            onFilter: (value: any, record) => record.Name.includes(value)
        },
        { title: 'Projekte', key: 'table_projects',
            render: (_, record) => (
                <Space key={"table_versions_space_" + record.Vorgangsnummer}>
                    {
                        record.projects.map(project => (
                            <Tag color="error" key={'tag_' + record.Vorgangsnummer + '_' + project.id}>
                                <a onClick={() => {this.openProjectVersion(project.id)}}>{project.name}</a>
                            </Tag>
                        ))
                    }
                </Space>
            )
        },
        { title: 'Aktionen', key: 'table_action',
            render: (_, record) => (
                <Space size={"middle"} key={"spacer_" + record.Vorgangsnummer}>
                    <Tooltip title="Löschen" key={"table_action_delete_"+record.Vorgangsnummer}>
                        <Popconfirm
                            title="sind Sie sicher, den Kunden und deren Projekte Löschen möchten?"
                            onConfirm={() => {this.delete(record.Vorgangsnummer)}}
                            okText="Ja"
                            cancelText="Nein"
                        >
                            <Button
                                type={"link"}
                                danger
                                icon={<i className="fa-solid fa-times"></i>} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )}
    ];

    constructor(props: IProps) {
        super(props);
        this.filterInput = React.createRef();
        this.state = {
            kunden: [],
            projects: [],
            filteredList: [],
            show: false,
            filter: '',
            logout: false,
            isDeleteSomeThing: false,
            isCreatingClient: false
        }
    }

    componentDidMount() {
        this.getDatas();
    }

    async getDatas() {
        const kunden = await API.getKunden();

        //console.log(kunden);

        this.setState({
            show: true,
            filteredList: kunden,
            kunden: kunden
        });
    }

    open(id: number) {
        this.props.navigation(`/client/${id}`);
    }

    openProjectVersion( id: string ) {
        message.info('Öffne Projekt: ' +  id);
    }

    async delete(id: number) {
        try {
            const api = await axios.post(API.URL + '/kunden/delete', {
                token: localStorage.getItem('token'),
                clientID: id
            });

            if ( api === undefined ) return message.error('API-Fehler');
            if ( api.data.errors ) return message.error((api.data.message ? api.data.message : 'Unbekannter fehler'));

            await this.getDatas();

            message.success('kunde wurde gelöscht!');
        } catch ( error ) {
            message.error('API-Fehler');
        }
    }

    onFilterInputChanged( value: string ) {
        this.setState( {filter: value.toLowerCase() });

        if ( value.length === 0 ) {
            this.setState( {
                filteredList: this.state.kunden
            });
        } else {
            let filteredList = this.state.kunden.filter( ( client: Kunde ) => {
                const searchedValue = client.Name + ' ' + client.Vorname;
                return searchedValue.toLowerCase().includes(value.toLowerCase());
            });

            this.setState( {
                filteredList: filteredList
            });
        }
    }

    onCreateClientStart() {
        this.setState({isCreatingClient: true});
    }
    async onCreateClientSubmit( values: any ) {
        //console.log(values);

        try {
            const api = await axios.post(API.URL+'/kunden/create', {
                token: localStorage.getItem('token'),
                ...values
            });

            if ( api === undefined ) return message.error('API Fehler');

            await this.getDatas();

        } catch ( error ) {
            message.error('API Fehler');
        }
    }
    onCreateClientCancel() {
        this.setState({isCreatingClient: false});
    }
    onCreateClientSave() {
        this.props.clientCreateForm.submit();
    }

    render() {
        return <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Button
                type="primary"
                shape="round"
                onClick={this.onCreateClientStart.bind(this)}
                icon={<i className={"fa-solid fa-add"} style={{marginRight: 10}} />}
            >
                Kunde erstellen
            </Button>

            <Divider />

            <Space direction="vertical" size="middle" style={{width: '100%'}}>
                <Input.Search placeholder="Projekte Suchen" onSearch={this.onFilterInputChanged.bind(this)} style={{width: "100%" }} enterButton={true} />
                <Table size={"small"} dataSource={this.state.filteredList} columns={this.tableColumns} />
            </Space>

            <Drawer
                visible={this.state.isCreatingClient}
                title={`Kunde Erstellen`}
                size={"large"}
                onClose={this.onCreateClientCancel.bind(this)}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={this.onCreateClientCancel.bind(this)}>Abbrechen</Button>
                        <Button onClick={this.onCreateClientSave.bind(this)} type="primary">
                            Speichern
                        </Button>
                    </Space>
                }
            >
                <ClientForm form={this.props.clientCreateForm} finish={this.onCreateClientSubmit.bind(this)} />
            </Drawer>
        </div>
    }
}
