/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 9.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

export class GlassFrameType {
	label = "";
	lines: any | undefined;
	handle: any | undefined;
	handleDirection = 0;
	constructor(label: string, lines: any | undefined, handle: any | undefined, handleDirection = 0) {
		this.label = label;
		this.lines = lines;
		this.handle = handle;
		this.handleDirection = handleDirection;
	}
}

export class GlassFrameFlip {
	constructor(
		public index: number,
		public flippedIndex: number,
	) {
	}
}