/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 28.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {FrameOuterOffset} from "../models/frameOuterOffset";
import {GlassSplitType, Reihe} from "../models/splitTypes";


export class WindowOptions {
	id: string = "";
	parentID: string = "";
	direction: number = 0;
	anchor: number = 0; // 0 = top, 1 = middle, 2 = bottom
	width: number = 0;
	height: number = 0;
	splitType: number = 0;
	splitCustom: boolean = false;
	splitCustomConfig: GlassSplitType | undefined;
	frameColor: string = "#FFFFFF";
	outerFrameOffset: FrameOuterOffset = new FrameOuterOffset(46);

	isKopplung: boolean = false;
	isConnectionProfile: boolean = false;
	hasLowerRamp: boolean = false; // hat niedrige Schwelle

	customWidthHeight: boolean = false;


}
export class WindowOptionsConfig {
	id?: string
	parentID?: string
	direction?: number
	anchor?: number
	width?: number
	height?: number
	splitType?: number
	splitCustom?: boolean
	splitCustomConfig?: GlassSplitType
	frameColor?: string
	outerFrameOffset?: FrameOuterOffset

	isKopplung?: boolean
	isConnectionProfile?: boolean
	hasLowerRamp?: boolean

	customWidthHeight?: boolean
}