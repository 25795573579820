/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 9.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {Designer} from "../designer";
import Konva from "konva";
import {WindowMain} from "../window/main";


export class SumMetric {

    rect: Konva.Rect | undefined;

    metrics: Konva.Rect[] = [];
    labels: Konva.Label[] = [];

    group: Konva.Group = new Konva.Group();

    realMaxWidth: number = 0;
    realMaxHeight: number = 0;

    constructor(
        public designer: Designer
    ) {}


    update() {
        if ( this.designer.stage == undefined ) return;
        if ( this.designer.layer == undefined ) return;

        this.designer.updateZoom(1);

        //console.log('[SumMetric] update called');

        for ( const mIndex in this.metrics ) {
            const metricObject = this.metrics[mIndex];
            if ( metricObject === undefined ) continue;

            metricObject.destroy();
            delete this.metrics[mIndex];
        }

        for ( const mIndex in this.labels ) {
            const metricObject = this.labels[mIndex];
            if ( metricObject === undefined ) continue;

            metricObject.destroy();
            delete this.labels[mIndex];
        }

        this.metrics = this.metrics.filter(
            ( metric ) => metric !== undefined
        );

        this.labels = this.labels.filter(
            ( label ) => label !== undefined
        );

        if ( this.designer.windows.length <= 0 ) return console.error('Keine Fenster Vorhanden!', this.designer.windows);
        //console.log('[SumMetric] update called 2', this.designer.windows);

        const testGroup: Konva.Group = new Konva.Group({
            opacity: 0.05
        });

        if ( this.rect != undefined ) this.rect.destroy();

        for ( const window of this.designer.windows ) {
            if ( window.rect === undefined ) continue;
            if ( window.group === undefined ) continue;

            testGroup.add(new Konva.Rect({
                x: window.rect.x() - window.options.outerFrameOffset.left,
                y: window.rect.y() - window.options.outerFrameOffset.top,
                width: window.rect.width() + window.options.outerFrameOffset.right + window.options.outerFrameOffset.left,
                height: window.rect.height() + window.options.outerFrameOffset.bottom + window.options.outerFrameOffset.top,
                fill: 'black'
            }));
        }

        this.designer.layer.add(testGroup);
        const info = testGroup.getClientRect();
        //console.log(info);
        testGroup.destroy();

        this.realMaxWidth = 0;
        this.realMaxHeight = 0;

        const masterWindow = this.designer.windows[0];
        if ( masterWindow.rect === undefined ) return;

        let mRightsWindow: number[] = [];

        this.createMetric(
            masterWindow.id,
            masterWindow.x - masterWindow.options.outerFrameOffset.left,
            info.y + info.height + 300,
            masterWindow.rect.width() + masterWindow.options.outerFrameOffset.left + masterWindow.options.outerFrameOffset.right,
            5,
            masterWindow.rect.width().toString(),
            false,
            'black'
        );

        this.createMetric(
            masterWindow.id,
            info.x + info.width + 300,
            masterWindow.y - masterWindow.options.outerFrameOffset.top,
            5,
            masterWindow.rect.height() + masterWindow.options.outerFrameOffset.top + masterWindow.options.outerFrameOffset.bottom,
            masterWindow.rect.height().toString(),
            true,
            'black'
        );

        this.realMaxWidth = masterWindow.rect.width();
        this.realMaxHeight = masterWindow.rect.height();

        mRightsWindow.push(masterWindow.rect.height());



        // get only right windows
        const rights = this.getWindowsRight(masterWindow) as WindowMain[];
        if ( rights !== undefined ) {
            if ( rights.length != 0 ) {
                let offsetX: number = 300;
                for ( const window of rights ) {
                    if ( window.rect === undefined ) continue;

                    //window.highlight();

                    this.realMaxWidth += window.rect.width();

                    this.createMetric(
                        window.id,
                        window.x - window.options.outerFrameOffset.left,
                        info.y + info.height + 300,
                        window.rect.width() + window.options.outerFrameOffset.left + window.options.outerFrameOffset.right,
                        5,
                        window.rect.width().toString(),
                        false,
                        'black'
                    );


                    const findBefore = mRightsWindow.find( ( num ) => window.rect && num === window.rect.height());
                    if ( findBefore != undefined ) continue;


                    offsetX -= 75;

                    this.createMetric(
                        window.id,
                        info.x + info.width + offsetX,
                        window.y - window.options.outerFrameOffset.top,
                        5,
                        window.rect.height() + window.options.outerFrameOffset.top + window.options.outerFrameOffset.bottom,
                        window.rect.height().toString(),
                        true,
                        'black'
                    );

                    mRightsWindow.push(window.rect.height());
                }
            } else {
                //console.log('[TESTING] no rights windows exists!');
            }
        }

        // get the top and then the rights
        const topWindows = this.getWindowTop(masterWindow);
        if ( topWindows !== undefined ) {
            if ( topWindows.length != 0 ) {
                let offsetY = 300;
                let offsetAdder = 75;
                for ( const tWindow of topWindows ) {
                    if ( tWindow.rect === undefined ) continue;

                    this.realMaxHeight += tWindow.rect.height();


                    //tWindow.highlight();
                    offsetY -= offsetAdder;

                    this.createMetric(
                        tWindow.id,
                        tWindow.x - tWindow.options.outerFrameOffset.left,
                        info.y + info.height + offsetY,
                        tWindow.rect.width() + tWindow.options.outerFrameOffset.left + tWindow.options.outerFrameOffset.right,
                        5,
                        tWindow.rect.width().toString(),
                        false,
                        'black'
                    );
                    this.createMetric(
                        tWindow.id,
                        info.x + info.width + 300,
                        tWindow.y - tWindow.options.outerFrameOffset.top,
                        5,
                        tWindow.rect.height() + tWindow.options.outerFrameOffset.top + tWindow.options.outerFrameOffset.bottom,
                        tWindow.rect.height().toString(),
                        true,
                        'black'
                    );

                    let topRightsHeight: number[] = [];
                    topRightsHeight.push(tWindow.rect.height());
                    let offsetX: number = 300;
                    let rowWidth = tWindow.rect.width();


                    const rights = this.getWindowsRight(tWindow) as WindowMain[];
                    if ( rights.length != 0 ) {
                        for ( const window of rights ) {
                            if ( window.rect === undefined ) continue;

                            rowWidth += window.rect.width();

                            this.realMaxWidth-= window.options.outerFrameOffset.left;
                            this.realMaxWidth-= window.options.outerFrameOffset.right;
                            this.realMaxHeight-= window.options.outerFrameOffset.bottom;
                            this.realMaxHeight-= window.options.outerFrameOffset.top;

                            //window.highlight();
                            offsetX -= 75;

                            this.createMetric(
                                window.id,
                                window.x - window.options.outerFrameOffset.left,
                                info.y + info.height + offsetY,
                                window.rect.width() + window.options.outerFrameOffset.left + window.options.outerFrameOffset.right,
                                5,
                                window.rect.width().toString()
                            );


                            if ( topRightsHeight.find(( el ) => window.rect != undefined && el === window.rect.height()) )
                                continue;

                            topRightsHeight.push(window.rect.height());

                            this.createMetric(
                                window.id,
                                info.x + info.width + offsetX,
                                window.y - window.options.outerFrameOffset.top,
                                5,
                                window.rect.height() + window.options.outerFrameOffset.top + window.options.outerFrameOffset.bottom,
                                window.rect.height().toString(),
                                true
                            );
                        }
                    }
                    if ( rowWidth > this.realMaxWidth ) this.realMaxWidth = rowWidth;
                }
            }
        }

        if ( this.designer.isExportContainer ) {
            this.createMetric(
                'maximum',
                0,
                info.y + info.height + 400,
                info.width + 5,
                5,
                this.realMaxWidth.toString()
            )
            this.createMetric(
                'maximum',
                info.x + info.width + 400,
                info.y,
                5,
                info.height,
                this.realMaxHeight.toString(),
                true
            )
        } else {
            if ( this.designer.windows.length !== 1 ) {

                this.createMetric(
                    'maximum',
                    0,
                    info.y + info.height + 400,
                    info.width + 5,
                    5,
                    this.realMaxWidth.toString()
                )
                this.createMetric(
                    'maximum',
                    info.x + info.width + 400,
                    info.y,
                    5,
                    info.height,
                    this.realMaxHeight.toString(),
                    true
                )
            }
        }
    }

    createMetric(id: string, x: number, y: number, width: number, height: number, text: string, isRight: boolean = false, color: string = "black") {
        if ( this.designer === undefined ) return;
        if ( this.designer.isExportContainer && id !== "maximum") return;
        if ( this.designer.layer === undefined ) return;


        const rect = new Konva.Rect({
            id: id,
            x: x,
            y: y,
            width: width,
            height: height,
            fill: color
        });


        const label = new Konva.Label({
            id: id,
            x: x + (!isRight ? (width / 2) - (text.length * 23) : -25) - 20,
            y: y + (isRight ? (height / 2) + (text.length * 23) : -30) - 20,
            rotation: (isRight ? -90 : 0)
        });

        label.add(new Konva.Tag({
            fill: 'black'
        }));

        label.add(
            new Konva.Text({
                text: `${text} mm`,
                fontSize: ( this.designer.isExportContainer ? 150 : 80),
                align: 'center',
                padding: 10,
                fill: 'white'
            })
        );

        let rOffX = 0;
        let rOffY = 0;
        let lOffX = 0;
        let lOffY = 0;
        let aWidth = 0;
        let aHeight = 0;

        if ( !isRight ) {
            rOffX = width - 4;
            rOffY = 30 / 2;

            lOffX = 0;
            lOffY = -14;

            aWidth = 5;
            aHeight = 30;
        } else {
            rOffX = -14;
            rOffY = 0;

            lOffX = +14;
            lOffY = height - 5;

            aWidth = 30;
            aHeight = 5;
        }

        const arrowRight = new Konva.Rect({
            x: x + rOffX,
            y: y - rOffY,
            width: aWidth,
            height: aHeight,
            fill: color
        });

        const arrowLeft = new Konva.Rect({
            x: x - lOffX,
            y: y + lOffY,
            width: aWidth,
            height: aHeight,
            fill: color
        });



        this.metrics.push(arrowLeft);
        this.metrics.push(arrowRight);
        this.metrics.push(rect);
        this.labels.push(label);

        this.group.add(rect);
        this.group.add(arrowRight);
        this.group.add(arrowLeft);
        this.group.add(label);
        this.designer.layer.add(this.group);
        //this.metrics[id] = rect;
        //this.metrics[]

    }


    getWindowTop(targetWindow: WindowMain): WindowMain[] | undefined {
        if ( targetWindow === undefined ) return undefined;
        let windows: WindowMain[] = [];

        for ( const window of this.designer.windows ) {
            if ( window.parent === undefined ) continue;
            if ( window.parent.id === targetWindow.id && window.options.direction === 2) {
                windows.push(window);
                break;
            }
        }

        if ( windows.length !== 0 ) {
            windows.push(...this.getWindowTop(windows[0]) as WindowMain[] );
        }

        return windows;
    }

    getWindowBottom(targetWindow: WindowMain): WindowMain[] | undefined {
        if ( targetWindow === undefined ) return undefined;
        let windows: WindowMain[] = [];

        for ( const window of this.designer.windows ) {
            if ( window.parent === undefined ) continue;
            if ( window.parent.id === targetWindow.id && window.options.direction === 1) {
                windows.push(window);
                break;
            }
        }

        if ( windows.length !== 0 ) {
            windows.push(...this.getWindowTop(windows[0]) as WindowMain[] );
        }

        return windows;
    }

    getWindowsRight(targetWindow: WindowMain): WindowMain[] | undefined {
        if ( targetWindow === undefined ) return undefined;
        let windows: WindowMain[] = [];

        for ( const window of this.designer.windows ) {
            if ( window.parent === undefined ) continue;
            if ( window.parent.id === targetWindow.id && window.options.direction === 0) {
                windows.push(window);
                break;
            }
        }

        if ( windows.length !== 0 ) {
            windows.push(...this.getWindowsRight(windows[0]) as WindowMain[]);
        }

        return windows;
    }
}