/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 26.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import {DragDrop} from "./dragDrop";
import $ from 'jquery';
import {ConfigController} from "../core/configController";
import {PresetController} from "./presetController";
import {WindowOptions} from "../window/windowOptions";

interface Containers {
    top: JQuery<HTMLElement>;
    left: JQuery<HTMLElement>;
    right: JQuery<HTMLElement>;
    bottom: JQuery<HTMLElement>;
}

export class ContainerController {

    typeIndex: number = 0;

    addIndexer: number = 0;
    containers: Containers = {
        top: $('#container-top'),
        left: $('#container-left'),
        right: $('#container-right'),
        bottom: $('#container-bottom')
    }

    constructor(private dragDrop: DragDrop) {

        this.dragDrop.designer.onReact('onTabChanged', (index: number) => {
            this.typeIndex = index;
            this.clear();
            this.start();
        });

        this.start();
    }

    start() {

        if ( this.typeIndex === 0 ) {
            for ( let index in ConfigController.splitTypes ) {
                const glassSplit = ConfigController.splitTypes[index];
                const isRendered = this.addItem(`
                <div class="draggable-item" data-id="${index}" data-type="${this.typeIndex}">
                    <div class="title">${glassSplit.label}</div>
                    <div class="preview-container" id="preview_${index}"></div>
                </div>
            `);
                if ( isRendered ) {
                    new PresetController(glassSplit, `preview_${index}`, Number(index));
                }
            }
        } else if ( this.typeIndex === 1 ) {
            for ( let index in ConfigController.frameTypes ) {
                const glassSplit = ConfigController.frameTypes[index];
                const isRendered = this.addItem(`
                    <div class="draggable-item" data-id="${index}" data-type="${this.typeIndex}">
                        <div class="title">${glassSplit.label}</div>
                        <div class="preview-container" id="preview_${index}"></div>
                    </div>
                `);
                if ( isRendered ) {
                    new PresetController(glassSplit, `preview_${index}`, Number(index));
                }
            }
        } else if ( this.typeIndex === 2 ) {
            for ( let index in ConfigController.kopplung ) {
                const width = ConfigController.kopplung[index];
                const isRendered = this.addItem(`
                    <div class="draggable-item" data-id="${index}" data-type="${this.typeIndex}">
                        <div class="title">${width} mm</div>
                        <div class="preview-container" id="preview_${index}"></div>
                    </div>
                `);
                if ( isRendered ) {
                    const options = new WindowOptions();
                    options.isKopplung = true;
                    new PresetController(options, `preview_${index}`, Number(index));
                }
            }
        }
    }

    addItem(html: string): boolean {
        for ( const key of Object.keys(this.containers) ) {
            let useHeight: boolean = false;
            if ( key === 'left') useHeight = true;
            if ( key === 'right') useHeight = true;

            // @ts-ignore
            if ( this.hasSize(this.containers[key], useHeight)) {
                // @ts-ignore
                this.containers[key].append(`<div class="ant-col designerDropItem">${html}</div>`);
                this.addIndexer++;
                return true;
            }
        }
        return false;
    }

    hasSize(container: JQuery<HTMLElement>, useHeight: boolean = false): boolean {
        if ( container === undefined ) return false;

        if ( !useHeight ) {
            const maxWidth = container.width();
            if ( maxWidth === undefined ) return false;

            let currentWidth = 200;

            for ( const child of container.children('div')) {
                //console.log('max:', maxWidth, 'current:', currentWidth, 'child:', child.offsetWidth);
                currentWidth += child.offsetWidth;
                if ( currentWidth > maxWidth ) return false;
            }
        } else {
            const max = container.parent().height();
            if ( max === undefined ) return false;

            let current = 100;
            for ( const child of container.children('div')) {
                current += child.clientHeight;
                if ( current > max ) return false;
            }
        }
        return true;
    }

    clear() {
        for ( const key of Object.keys(this.containers) ) {
            // @ts-ignore
            this.containers[key].html("");
        }

    }

}