/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 8.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import {Designer} from "../designer";
import {WindowOptions} from "./windowOptions";
import {GlassConfig} from "../core/data";
import {Rect} from "./main";
import {GlassSplitType, Reihe, Spalte} from "../models/splitTypes";
import {ConfigController} from "../core/configController";
import {Default} from "../config/default";


export class AddController {
    private _width: number = 1000;
    private _height: number = 1000;

    _metrics: boolean = true;
    _isPreset: boolean = false;

    private _parent: Rect | undefined;
    private _windowOptions: WindowOptions | undefined = undefined;
    private _glassConfig: GlassConfig[] = new Array<GlassConfig>();

    constructor(
        protected designer: Designer | undefined,
        public type: number = 0
    ) {}

    get parent(): Rect | undefined { return this._parent };
    set parent(value: Rect | undefined) {
        this._parent = value;
    }

    get metrics(): boolean { return this._metrics };
    set metrics(value: boolean) {
        this._metrics = value;
    }

    get isPreset(): boolean { return this._isPreset };
    set isPreset(value: boolean) {
        this._isPreset = value;
    }

    get width(): number { return this._width };
    get height(): number { return this._height };
    get size(): {width: number, height: number} {
        return {
            width: this._width,
            height: this._height
        }
    }
    set size(size: {width: number, height: number}) {
        this._width = size.width;
        this._height = size.height;
    }

    get glassConfig(): GlassConfig[] { return this._glassConfig; }
    set glassConfig(value: GlassConfig[]) {
        this._glassConfig = value;
    }

    get options(): WindowOptions | undefined { return this._windowOptions; }
    set options(value: WindowOptions | undefined ) {
        this._windowOptions = value;
    }

    apply() {
        //console.log('controllerConfig:', this.options);
        if ( this.designer ) {
            if ( this.designer.stage === undefined ) return;

            this.designer.stage.absolutePosition({x: 0, y: 0});
            this.designer.updateZoom(Default.zoom);

            if (this.parent === undefined) {
                this.parent = this.designer.windows[this.designer.windows.length - 1];
            }
        }


        if (this.options === undefined) {
            this.options = new WindowOptions();
            this.options.width = this.width;
            this.options.height = this.height;
            //this.options.direction = this.options.direction;
            this.options.splitCustom = true;
            this.options.splitCustomConfig = new GlassSplitType('default', [new Reihe(
                [new Spalte()]
            )]);
        }


        if ( this.options.isKopplung ) {
            this.options.width = ConfigController.kopplung[this.options.splitType];
            this.options.height = ConfigController.kopplung[this.options.splitType];
        } else {
            if ( this.options.width === 0 || this.options.height === 0 ) {
                this.options.width = this.size.width;
                this.options.height = this.size.height;
            }
        }


        const object = new Rect(this.designer);
        object.parent = this.parent;
        object.options = this.options;
        object.isPreset = this.isPreset;

        if ( this.glassConfig !== undefined) object.glassConfig = this.glassConfig;
        object.create();


        if ( this.designer !== undefined ) {
            this.designer.windows.push(object);
        }

        if ( this.designer ) {
            if ( this.designer.sumMetric ) {
                this.designer.sumMetric.update();
            }
        }
        return object;
    }

    reset() {
        this._windowOptions = new WindowOptions();
        this._glassConfig = new Array<GlassConfig>();

        this._width = 0;
        this._height = 0;

        this._parent = undefined;
    }
}