/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Designer} from "../designer";
import {Colors} from "../config/default";
import {Rect} from "../window/main";
import Line = Konva.Line;


export class WindowFrame {
	moveElement: any = null;

	frameTop: Line | undefined = undefined;
	frameLeft: Line | undefined = undefined;
	frameBottom: Line | undefined = undefined;
	frameRight: Line | undefined = undefined;

	constructor(public window: Rect, public designer: Designer) {
		this.create();
	}


	create() {
		if ( this.window.options.hasLowerRamp ) {
			this.window.options.outerFrameOffset.bottom = 5;
		} else {
			this.window.options.outerFrameOffset.left = this.window.options.outerFrameOffset.top;
			this.window.options.outerFrameOffset.right = this.window.options.outerFrameOffset.top;
			this.window.options.outerFrameOffset.bottom = this.window.options.outerFrameOffset.top;
		}

		if ( this.window.rect === undefined ) return;

		const rect = this.window.rect.getClientRect();


		this.frameTop = new Konva.Line({
			x: rect.x,
			y: rect.y,
			points: this.generatePoints(0),
			stroke: Colors.frameStroke,
			fill: this.getFrameColor(),
			closed: true,
			id: this.window.id + "-top"
		});

		this.frameLeft = new Konva.Line({
			x: rect.x,
			y: rect.y,
			points: this.generatePoints(1),
			stroke: Colors.frameStroke,
			fill: this.getFrameColor(),
			closed: true,
			id: this.window.id + "-left"
		});

		this.frameBottom = new Konva.Line({
			x: rect.x,
			y: rect.y,
			points: this.generatePoints(2),
			stroke: Colors.frameStroke,
			fill: this.getFrameColor(),
			closed: true,
			id: this.window.id + "-bottom"
		});

		this.frameRight = new Konva.Line({
			x: rect.x,
			y: rect.y,
			points: this.generatePoints(3),
			stroke: Colors.frameStroke,
			fill: this.getFrameColor(),
			closed: true,
			id: this.window.id + "-right"
		});

		//this.frameRight.on('mousedown', this.onMouseDown.bind(this));
		//this.frameBottom.on('mousedown', this.onMouseDown.bind(this));


		this.window.addGroup(this.frameTop);
		this.window.addGroup(this.frameLeft);
		this.window.addGroup(this.frameBottom);
		this.window.addGroup(this.frameRight);

		//$(document).on('mouseup', this.onMouseUp.bind(this));
		//$(document).on('mousemove', this.onMove.bind(this));

	}

	generatePoints(type = 0) {
		if ( this.window.rect === undefined ) return;
		const rect = this.window.rect.getClientRect();
		const opt = this.window.options;

		if ( type === 0 ) {
			return [
				0, 0,
				- opt.outerFrameOffset.top, - opt.outerFrameOffset.top,
				(rect.width + opt.outerFrameOffset.top), - opt.outerFrameOffset.top,
				rect.width, 0
			];
		} else if ( type === 1 ) {
			return [
				0, 0,
				- opt.outerFrameOffset.left, - opt.outerFrameOffset.left,
				- opt.outerFrameOffset.left, (rect.height + opt.outerFrameOffset.bottom),
				0, rect.height
			];
		} else if ( type === 2 ) {
			return [
				0, rect.height,
				(- opt.outerFrameOffset.left), rect.height + opt.outerFrameOffset.bottom,
				(rect.width + opt.outerFrameOffset.right), (rect.height + opt.outerFrameOffset.bottom),
				rect.width, rect.height
			];
		} else if ( type === 3 ) {
			return [
				rect.width, 0,
				rect.width + opt.outerFrameOffset.right, - opt.outerFrameOffset.right,
				rect.width + opt.outerFrameOffset.right, rect.height + opt.outerFrameOffset.bottom,
				rect.width, rect.height
			]
		} else {
			return [0, 0, 0, 0];
		}
	}

	getFrameColor() {
		return ( this.window === undefined ? Colors.frameFill : this.window.options.frameColor);
	}

	destroy() {
		if ( this.frameLeft !== undefined ) this.frameLeft.destroy();
		if ( this.frameRight !== undefined ) this.frameRight.destroy();
		if ( this.frameBottom !== undefined ) this.frameBottom.destroy();
		if ( this.frameTop !== undefined ) this.frameTop.destroy();
	}

	delete() {
		this.destroy();
	}

	update() {
		this.destroy();
		this.create();
	}

	fillAll(color: string = "red") {
		if ( this.frameLeft === undefined ) return;
		if ( this.frameRight === undefined ) return;
		if ( this.frameTop === undefined ) return;
		if ( this.frameBottom === undefined ) return;

		this.frameLeft.fill(color);
		this.frameRight.fill(color);
		this.frameTop.fill(color);
		this.frameBottom.fill(color);
	}

	onMouseDown(  ) {
		if ( this.window.designer !== undefined && this.window.designer.editor !== undefined )
			if ( this.window.designer.editor.clickModus !== 1 ) return;
	}

	onMove(  ) {
		if ( this.moveElement === null ) return;
		if ( this.window.designer === undefined ) return;
		if ( this.window.designer.stage === undefined ) return;

		//Todo: get mouse position in stage
		const pos = this.window.designer.stage.getRelativePointerPosition();
		////console.log(pos);
	}

	onMouseUp(  ) {
		if ( this.designer === undefined ) return;
		if ( this.designer.editor === undefined ) return;
		if ( this.designer.editor.clickModus !== 1 ) return;

		////console.log('mouse up while clickMode = 1')

		if ( this.moveElement != null ) {
			this.moveElement = null;
		}
	}
}