/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 26.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/
import React from "react";
import {Designer} from "../../../designer";
import {Menu, Space} from "antd";

interface iProps {
    designer: Designer
}
interface iStates {
    index: number;
}
export class ToolbarTabs extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            index: 0
        }
    }

    componentDidMount() {
        if ( !this.props.designer ) return console.log('ToolbarTabs cant access into Designer!');

        this.props.designer.on('onTabChanged', (newIndex: number) => {
            this.setState({
                index: newIndex
            });
        });
    }

    onMenuChanged(item: any) {
        if ( !this.props.designer ) return console.log('ToolbarTabs cant access into Designer!');
        this.setState({
            index: Number(item.key)
        });
        this.props.designer.emitReact('onTabChanged', Number(item.key));
    }

    render() {
        return <Menu
            mode="horizontal"
            style={{width: '100%'}}
            defaultSelectedKeys={[this.state.index.toString()]}
            onClick={this.onMenuChanged.bind(this)}
        >
            <Menu.Item key="0">
                Presets
            </Menu.Item>
            <Menu.Item key="1">
                Beschläge
            </Menu.Item>
            <Menu.Item key="2">
                Aufdopplungen
            </Menu.Item>
            <Menu.Item key="3">
                Sprossen
            </Menu.Item>
        </Menu>;


    }
}