/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 10.11.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React from "react";
import {Designer} from "../../designer";
import {Button, Col, Divider, Input, Row, Select, Space, Switch, Tooltip} from "antd";
import {Reihe, Spalte} from "../../models/splitTypes";
import {Rect} from "../../window/main";
import {WindowOptions} from "../../window/windowOptions";
import {ConfigController} from "../../core/configController";
import {Glass} from "../../glass/main";
import {Sprosse} from "../../glass/sprosse";
import {glassTypesList} from "../../config/default";

interface iProps {
    designer: Designer;
    splitConfig: WindowOptions;
}

interface iStates {
    splitConfig: WindowOptions | undefined;


}


export class SplitConfigure extends React.Component<iProps, iStates> {

    constructor(props: iProps) {
        super(props);

        this.state = {
            splitConfig: undefined
        }

    }


    componentDidMount() {
        this.props.designer.on('onSelected', (selected: Rect | Glass | Sprosse | undefined) => {
            if ( selected === undefined ) {
                return this.setState({splitConfig: undefined});
            }

            if ( selected instanceof Glass ) {
                selected.window.highlightFrame();
                this.setState({
                    splitConfig: selected.window.options
                });
            } else if ( selected instanceof Rect ) {
                selected.highlightFrame();
                this.setState({
                    splitConfig: selected.options
                });
            } else if ( selected instanceof Sprosse ) {
                console.log('sprosse selected!');
                this.setState({
                    splitConfig: selected.glass.window.options
                });
            } else {
                this.setState({
                    splitConfig: undefined
                });
            }
        })
        if ( this.props.designer.editor !== undefined ) {
            if ( this.props.designer.editor.selected !== undefined ) {
                if ( this.props.designer.editor.selected instanceof Rect ) {
                    this.setState({
                        splitConfig: this.props.designer.editor.selected.options
                    });
                }
            }
        }
        this.setState({splitConfig: this.props.splitConfig});
    }

    getColWidthError(currentValue: number, min?: number, isTrenner: boolean = false) {
        if (currentValue < 0 ) return 'error';
        if ( min !== undefined ) {
            if ( currentValue != 0 && !isTrenner ) {
                if ( currentValue < min ) return 'error';
            }
        }
        return undefined;
    }

    getRectSplit(): JSX.Element {
        if ( this.state.splitConfig === undefined ) return <div></div>;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return <div></div>;
        if ( this.state.splitConfig.splitCustomConfig.reihen.length === 0 ) return <div>Keine Reihen Verfügbar.</div>;

        return <>
                {
                    this.state.splitConfig.splitCustomConfig.reihen.map( ( row, index ) => {
                        return <div style={{
                            position: 'relative',
                            border: '1px dashed black',
                            marginBottom: 20,
                            padding: 10
                        }}>
                            <div className="splitRowTitle">Reihe:</div>
                            <Row justify={"space-between"}>
                                <Col span={10}>
                                    <Input
                                        addonAfter={"mm"}
                                        addonBefore={"Höhe:"}
                                        type="number"
                                        value={row.height}
                                        status={this.getColWidthError(row.height)}
                                        defaultValue={row.height}
                                        onChange={(el) => this.onRowHeight(index, Number(el.target.value))}
                                    />
                                </Col>
                                <Col>
                                    <Space size={4}>
                                        <Button
                                            type={"default"}
                                            shape={"circle"}
                                            size={"small"}
                                            icon={<i className={"fas fa-arrow-up"}></i>}
                                            block
                                            onClick={(e) => this.swapRow(index, (index-1))}
                                        />
                                        <Button
                                            type={"default"}
                                            shape={"circle"}
                                            size={"small"}
                                            icon={<i className={"fas fa-arrow-down"}></i>}
                                            block
                                            onClick={(e) => this.swapRow(index, (index+1))}
                                        />
                                        <Button
                                            type={"default"}
                                            shape={"circle"}
                                            size={"small"}
                                            icon={<i className={"fas fa-times"}></i>}
                                            block
                                            onClick={(e) => this.removeRow(index)}
                                        />
                                    </Space>
                                </Col>
                            </Row>
                            <Divider dashed style={{marginTop: 15, marginBottom: 10}} />
                            <Row justify={"space-between"}
                                style={{
                                    width: "100%",
                                    marginTop: 10,
                                    marginBottom: 0,
                                }}
                            >
                                <Col span={5}>
                                    <Row justify={"start"} align={"middle"}
                                         style={{
                                             width: "100%",
                                         }}
                                    >

                                        <strong>Glässer</strong>
                                        <Button
                                            type="link"
                                            size={"small"}
                                            shape={"round"}
                                            icon={<i className={"fas fa-add"}></i>}
                                            onClick={(e) => this.addGlass(index)}
                                        />
                                        <Button
                                            type="link"
                                            size={"small"}
                                            shape={"round"}
                                            icon={<i className={"fas fa-rotate"}></i>}
                                            onClick={(e) => this.flipMounting(index)}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            {
                                row.spalten.map( (column, colIndex) => {
                                    return <Row justify={"space-between"} style={{
                                        position: 'relative',
                                        width: "100%",
                                        marginBottom: 5,
                                        padding: 5
                                    }}>
                                        <Col span={6}>
                                            <Input
                                                type="number"
                                                defaultValue={column.width}
                                                value={column.width}
                                                status={this.getColWidthError(
                                                    column.width,
                                                    (column.frameOuterOffset.left + column.frameOuterOffset.right),
                                                    column.isTrenner
                                                )}
                                                addonAfter={"mm"}
                                                onChange={(element) => this.onColumnWidth(index, colIndex, Number(element.target.value))}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                disabled={column.isTrenner}
                                                style={{width: '100%'}}
                                                defaultValue={column.frameType}
                                                value={column.frameType}
                                                onChange={(value, option) => this.onGlassFrameChange(index, colIndex, value)}
                                            >
                                                {
                                                    ConfigController.frameTypes.map((frame, frameIndex) => {
                                                        return <Select.Option value={frameIndex} key={frame.label} >
                                                            {frame.label}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Tooltip title={"Stulp"}>
                                                <Switch
                                                    size="small"
                                                    checked={column.isTrenner}
                                                    defaultChecked={column.isTrenner}
                                                    style={{marginTop: 3}}
                                                    onChange={() => {this.onGlassTrennerChange(index, colIndex, !column.isTrenner)}}
                                                />
                                            </Tooltip>
                                        </Col>
                                        <Col span={4}>
                                            <Select
                                                disabled={column.isTrenner}
                                                style={{width: '100%'}}
                                                defaultValue={glassTypesList[column.glassType]}
                                                value={glassTypesList[column.glassType]}
                                                onChange={(value, option) => this.onGlassTypeChange(index, colIndex, Number(value))}
                                            >
                                                {
                                                    glassTypesList.map((glassType, glassTypeIndex) => {
                                                        return <Select.Option value={glassTypeIndex} key={glassType} >
                                                            {glassType}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Space size={2}>
                                                <Tooltip title={"Nach Oben Bewegen"}>
                                                    <Button
                                                        type={"default"}
                                                        shape={"circle"}
                                                        size={"small"}
                                                        style={{color: 'green'}}
                                                        icon={<i className={"fas fa-arrow-up"}></i>}
                                                        onClick={() => this.swapColumn(index, colIndex, (colIndex - 1) )}
                                                    />
                                                </Tooltip>
                                                <Tooltip title={"Nach Unten Bewegen"}>
                                                    <Button
                                                        type={"default"}
                                                        shape={"circle"}
                                                        size={"small"}
                                                        style={{color: 'green'}}
                                                        icon={<i className={"fas fa-arrow-down"}></i>}
                                                        onClick={() => this.swapColumn(index, colIndex, (colIndex + 1))}
                                                    />
                                                </Tooltip>
                                                <Tooltip title={"Löschen"}>
                                                    <Button
                                                        type={"default"}
                                                        shape={"circle"}
                                                        size={"small"}
                                                        style={{color: 'red'}}
                                                        icon={<i className={"fas fa-times"}></i>}
                                                        onClick={() => this.removeGlass(index, colIndex)}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </Col>
                                    </Row>
                                })
                            }
                        </div>
                    })
                }
            </>;
    }

    onAddRow() {
        if ( this.state.splitConfig === undefined ) return;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return;

        this.state.splitConfig.splitCustomConfig.reihen.push(
            new Reihe([new Spalte()])
        );

        this.update(this.state.splitConfig);
    }

    removeGlass(rowIndex: number, glassIndex: number) {
        if ( this.state.splitConfig === undefined ) return;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return;

        delete this.state.splitConfig.splitCustomConfig.reihen[rowIndex].spalten[glassIndex];
        this.state.splitConfig.splitCustomConfig.reihen[rowIndex].spalten = this.state.splitConfig.splitCustomConfig.reihen[rowIndex].spalten.filter( (column) => column !== undefined);

        this.update(this.state.splitConfig);
    }

    removeRow(rowIndex: number) {
        if ( this.state.splitConfig === undefined ) return;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return;

        delete this.state.splitConfig.splitCustomConfig.reihen[rowIndex];
        this.state.splitConfig.splitCustomConfig.reihen = this.state.splitConfig.splitCustomConfig.reihen.filter( (row) => row !== undefined);

        this.update(this.state.splitConfig);
    }

    swapColumn(rowIndex: number, colIndex: number, toIndex: number) {
        if ( this.state.splitConfig === undefined ) return;

        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;


        const toColumn = data.splitCustomConfig.reihen[rowIndex].spalten[toIndex];
        const fromColumn = data.splitCustomConfig.reihen[rowIndex].spalten[colIndex];
        if (toColumn && fromColumn ) {
            data.splitCustomConfig.reihen[rowIndex].spalten[toIndex] = fromColumn;
            data.splitCustomConfig.reihen[rowIndex].spalten[colIndex] = toColumn;
            this.update(data);
        }

    }

    swapRow(rowIndex: number, toIndex: number) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        const toRow = data.splitCustomConfig.reihen[toIndex];
        const fromRow = data.splitCustomConfig.reihen[rowIndex];
        if (toRow && fromRow ) {
            data.splitCustomConfig.reihen[toIndex] = fromRow;
            data.splitCustomConfig.reihen[rowIndex] = toRow;
            this.update(data);
        }
    }

    flipMounting(rowIndex: number) {
        console.log('flipMounting called');
        if ( this.state.splitConfig === undefined ) return;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return;

        console.log(ConfigController.frameFlips);

        const row = this.state.splitConfig.splitCustomConfig.reihen[rowIndex];
        for ( const col of row.spalten ) {
            const flipModel = ConfigController.frameFlips.find( flip => flip.index === col.frameType);
            if ( !flipModel ) {
                console.log('cant find flipModel for', col.frameType);
                continue;
            }

            console.log('flip:', col.frameType, 'to', flipModel.flippedIndex);
            col.frameType = flipModel.flippedIndex;
        }

        this.update(this.state.splitConfig);
    }

    addGlass(rowIndex: number = 0) {
        if ( this.state.splitConfig === undefined ) return;
        if ( this.state.splitConfig.splitCustomConfig === undefined ) return;


        this.state.splitConfig.splitCustomConfig.reihen[rowIndex].spalten.push(
            new Spalte()
        )

        this.update(this.state.splitConfig);

    }

    onGlassFrameChange(rowIndex: number, glassIndex: number, newValue: number) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameType = newValue;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameInnerOffset.top = 20;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameInnerOffset.left = 20;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameInnerOffset.right = 20;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameInnerOffset.bottom = 20;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameOuterOffset.top = 74;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameOuterOffset.left = 74;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameOuterOffset.right = 74;
        data.splitCustomConfig.reihen[rowIndex].spalten[glassIndex].frameOuterOffset.bottom = 74;
        this.update(data);
    }

    onColumnWidth(rowIndex: number, colIndex: number, value: number) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        data.splitCustomConfig.reihen[rowIndex].spalten[colIndex].width = value;
        this.update(data);
    }

    onRowHeight(rowIndex: number, value: number) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        data.splitCustomConfig.reihen[rowIndex].height = value;
        this.update(data);
    }

    onGlassTrennerChange(rowIndex: number, colIndex: number, newValue: boolean ) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        data.splitCustomConfig.reihen[rowIndex].spalten[colIndex].isTrenner = newValue;
        this.update(data);
    }

    onGlassTypeChange(rowIndex: number, colIndex: number, newValue: number ) {
        const data = this.state.splitConfig;
        if ( data === undefined ) return;
        if ( data.splitCustomConfig === undefined ) return;

        data.splitCustomConfig.reihen[rowIndex].spalten[colIndex].glassType = newValue;
        this.update(data);
    }

    onSave() {
        if ( this.state.splitConfig === undefined ) return;

        const window = this.props.designer.getWindow(this.state.splitConfig.id);
        if ( window === undefined ) return;
        window.options = this.state.splitConfig;
        window.update();
    }

    update(data: WindowOptions) {
        this.setState({
            splitConfig: data
        }, () => {
            if ( this.state.splitConfig === undefined ) return;

            const window = this.props.designer.getWindow(this.state.splitConfig.id);
            if ( window === undefined ) return console.log('[SplitConfigure] [ERROR] cant find window:', this.state.splitConfig.id);
            data.outerFrameOffset.top = 46;
            data.outerFrameOffset.left = 46;
            data.outerFrameOffset.right = 46;
            data.outerFrameOffset.bottom = 46;
            window.options = data;
            window.update();

            this.props.designer.updateZoom()
        });
    }

    render() {
        return <>
            <div style={{marginBottom: 20, marginTop: 10}}><strong>Glass aufteilung:</strong></div>

            { this.state.splitConfig !== undefined &&
                <div>{this.getRectSplit()}</div>
            }

            <Space>
                <Button
                    type="dashed"
                    icon={<i className={"fas fa-add"} style={{marginRight: 5}}></i>}
                    onClick={this.onAddRow.bind(this)}
                >Reihe Hinzufügen</Button>
            </Space>
        </>;
    }
}
