/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Colors} from "../config/default";
import {Glass} from "../glass/main";
import Line = Konva.Line;



export class OuterFrame {
    frameTop: Line | undefined = undefined;
    frameLeft: Line | undefined = undefined;
    frameBottom: Line | undefined = undefined;
    frameRight: Line | undefined = undefined;

    constructor(public glass: Glass) {
		this.create();
    }

    create() {
		if ( this.glass.rect === undefined ) return;
        const rect = this.glass.rect.getClientRect();

        this.frameTop = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generatePoints(0),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
			id: this.glass.id + "-top"
        });

        this.frameLeft = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generatePoints(1),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
			id: this.glass.id + "-left"
        });

        this.frameBottom = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generatePoints(2),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
			id: this.glass.id + "-bottom"
        });

        this.frameRight = new Konva.Line({
            x: rect.x,
            y: rect.y,
            points: this.generatePoints(3),
            stroke: Colors.frameStroke,
            fill: this.getFrameColor(),
            closed: true,
			id: this.glass.id + "-right"
        });

        this.glass.addGroup(this.frameTop);
        this.glass.addGroup(this.frameLeft);
        this.glass.addGroup(this.frameBottom);
        this.glass.addGroup(this.frameRight);

    }

    generatePoints(type = 0) {
		if ( this.glass.rect === undefined ) return;
        const rect = this.glass.rect.getClientRect();
        const opts = this.glass.options;

		//console.log('[OuterFrame] generatePoints', opts.frameOuterOffset);

        
        if ( type === 0 ) {
            return [
                0, 0,
                opts.frameOuterOffset.left, opts.frameOuterOffset.top,
                rect.width - opts.frameOuterOffset.right, opts.frameOuterOffset.top,
                rect.width, 0
            ];
        } else if ( type === 1 ) {
            return [
                0, 0,
                opts.frameOuterOffset.left, opts.frameOuterOffset.left,
                opts.frameOuterOffset.left, (rect.height - opts.frameOuterOffset.bottom),
                0, rect.height
            ];
        } else if ( type === 2 ) {
            return [
                0, rect.height,
                opts.frameOuterOffset.left, rect.height - opts.frameOuterOffset.bottom,
                rect.width - opts.frameOuterOffset.right, rect.height - opts.frameOuterOffset.bottom,
                rect.width, rect.height
            ];
        } else if ( type === 3 ) {
            return [
                rect.width, 0,
                rect.width - opts.frameOuterOffset.right, opts.frameOuterOffset.right,
                rect.width - opts.frameOuterOffset.right, rect.height - opts.frameOuterOffset.bottom,
                rect.width, rect.height
            ]
        } else {
            return [0, 0, 0, 0];
        }
    }


	getFrameColor() {
		//return 'white';
		return ( this.glass.window === undefined ? Colors.frameFill : this.glass.window.options.frameColor);
	}

    destroy() {
		if ( this.frameLeft !== undefined ) this.frameLeft.destroy();
		if ( this.frameRight !== undefined ) this.frameRight.destroy();
		if ( this.frameBottom !== undefined ) this.frameBottom.destroy();
		if ( this.frameTop !== undefined ) this.frameTop.destroy();
    }

	delete() { this.destroy(); }

    update() {
        this.destroy();
        this.create();
    }

}