/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 12.10.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import {ConfigController} from "../core/configController";
import {GlassFrameType} from "../models/frameTypes";

/*0*/ConfigController.frameTypes.push(new GlassFrameType("Reparaturglas", (rect: any) => {
	return [
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 - 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 + 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2) + 20, rect.height / 2,
		(rect.width / 2) - 20, rect.height / 2,
	];
}, undefined));
ConfigController.frameTypes.push(new GlassFrameType("Festverglasung ohne Rahmen", (rect: any) => {
	return [
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 - 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 + 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2) + 20, rect.height / 2,
		(rect.width / 2) - 20, rect.height / 2,
	];
}, undefined));
/*2*/ConfigController.frameTypes.push(new GlassFrameType("Festverglasung", (rect: any) => {
	return [
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 - 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2), (rect.height / 2 + 20),
		(rect.width / 2), rect.height / 2,
		(rect.width / 2) + 20, rect.height / 2,
		(rect.width / 2) - 20, rect.height / 2,
	];
}, undefined));
ConfigController.frameTypes.push(new GlassFrameType("Rechts Öffnen", ( rect: any, offset: any ) => {
	return [
		rect.width - offset.right, (offset.right),
		offset.right, rect.height / 2,
		rect.width - offset.right, rect.height - offset.right
	]
}, ( rect: any, offset: any ) => {
	return {
		x: rect.x + offset.right / 2,
		y: rect.y + rect.height / 2
	}
}));
/*4*/ConfigController.frameTypes.push(new GlassFrameType("Rechts Öffnen Ohne Griff", ( rect: any, offset: any ) => {
	return [
		rect.width - offset.right, (offset.right),
		offset.right, rect.height / 2,
		rect.width - offset.right, rect.height - offset.right
	]
}, undefined));
ConfigController.frameTypes.push(new GlassFrameType("Links Öffnen", (rect: any, offset: any) => {
	return [
		offset.right, offset.right,
		rect.width - offset.right, rect.height/2,
		offset.right, rect.height - offset.right
	];
}, ( rect: any, offset: any ) => {
	//////console.log('[CONFIG] frameType', rect, offset);
	return {
		x: rect.x + rect.width - offset.right / 2,
		y: rect.y + rect.height / 2
	};
}));
/*6*/ConfigController.frameTypes.push(new GlassFrameType("Links Öffnen Ohne Griff", (rect: any, offset: any) => {
	return [
		offset.right, offset.right,
		rect.width - offset.right, rect.height/2,
		offset.right, rect.height - offset.right
	];
}, undefined));
ConfigController.frameTypes.push(new GlassFrameType("Dreh-Kipp Links", (rect: any, offset: any) => {
	return [
		rect.width - offset.left, (offset.left),
		offset.left, rect.height / 2,
		rect.width - offset.left, rect.height - offset.left,
		(rect.width / 2), (offset.left),
		offset.left, rect.height - offset.left
	];
}, (rect: any, offset: any) => {
	return {
		x: rect.x + offset.left / 2,
		y: rect.y + rect.height / 2
	}
}));
/*8*/ConfigController.frameTypes.push(new GlassFrameType("Dreh-Kipp Rechts", (rect: any, offset: any) => {
	return [
		offset.right, offset.right,
		rect.width - offset.right, rect.height / 2,
		offset.right, rect.height - offset.right,
		(rect.width / 2), (offset.right),
		rect.width - offset.right, rect.height - offset.right
	];
}, ( rect: any, offset: any ) => {
	return {
		x: rect.x + rect.width - offset.right / 2,
		y: rect.y + rect.height / 2
	}
}));
ConfigController.frameTypes.push(new GlassFrameType("Kipp", (rect: any, offset: any) => {
	return [
		offset.top, rect.height - offset.top,
		(rect.width / 2), (offset.top),
		rect.width - offset.top, rect.height - offset.top
	];
}, (rect: any, offset: any) => {
	return {
		x: rect.x + rect.width / 2,
		y: rect.y + offset.top/2 + 5
	}
}, 1));
/*10*/ConfigController.frameTypes.push(new GlassFrameType("Klapp", (rect: any, offset: any) => {
	return [
		offset.bottom, offset.bottom,
		(rect.width / 2), rect.height - offset.bottom,
		rect.width - offset.bottom, offset.bottom
	];
}, (rect: any, offset: any) => {
	return {
		x: rect.x + rect.width / 2,
		y: rect.y + rect.height - offset.bottom / 2 + 5
	}
}, 3));
ConfigController.frameTypes.push(new GlassFrameType("Typ 22 Glass", (rect: any, offset: any, innerOffset: any) => {
	return [
		offset.left + innerOffset.left, (rect.height / 2),
		(rect.width / 2), offset.left + innerOffset.left,
		rect.width - offset.left - innerOffset.left, (rect.height / 2),
		(rect.width / 2), rect.height - offset.left - innerOffset.left,
		offset.left + innerOffset.left, (rect.height / 2),
	];
}, undefined));
/*12*/ConfigController.frameTypes.push(new GlassFrameType("Flügel", (rect: any, offset: any, innerOffset: any, isPreset: boolean) => {
	////console.log('DEBUG FrameType 12:');
	////console.log(rect, offset, innerOffset);

	const arrowOffset = (!isPreset ? 13 : 5);
	const arrowOffset2 = (!isPreset ? 20 : 8);
	const arrowOffset3 = (!isPreset ? 35 : 10);
	return [
		rect.width - offset.left - innerOffset.left, rect.height - offset.left - innerOffset.left,
		rect.width / 2, offset.left + innerOffset.left,
		offset.left + innerOffset.left, rect.height - offset.left - innerOffset.left,
		/*ARROW*/
		rect.width / 4 + offset.left - innerOffset.left - arrowOffset, rect.height / 2,
		rect.width / 2.5, rect.height / 2,
		rect.width / 4 + offset.left - innerOffset.left - arrowOffset, rect.height / 2,
		rect.width/5, rect.height/2,
		rect.width/5, rect.height/2 + arrowOffset2,
		rect.width/5 - arrowOffset3, rect.height/2,
		rect.width/5, rect.height/2 - arrowOffset2,
		rect.width/5, rect.height/2,
	];
}, ( rect: any, offset: any ) => {
	return {
		x: rect.x + offset.left / 2,
		y: rect.y + rect.height / 2
	}
}));
ConfigController.frameTypes.push(new GlassFrameType("Schieben nach Rechts", (rect: any, offset: any, innerOffset: any, isPreset: boolean) => {

	const arrowOffset = (!isPreset ? 20 : 5);
	const arrowOffset2 = (!isPreset ? 50 : 10);
	const arrowOffset3 = (!isPreset ? 100 : 15);
	return [
		/*ARROW*/
		rect.width / 2 - arrowOffset2, rect.height / 2 + arrowOffset,
		rect.width / 2 - arrowOffset2, rect.height / 2,
		rect.width / 2 + arrowOffset2, rect.height / 2,
		rect.width / 2 + arrowOffset2, rect.height / 2 - arrowOffset,
		rect.width / 2 + arrowOffset2, rect.height / 2 + arrowOffset,
		rect.width / 2 + arrowOffset3, rect.height / 2,
		rect.width / 2 + arrowOffset2, rect.height / 2 - arrowOffset,
	];
}, ( rect: any, offset: any ) => {
	return {
		x: rect.x + offset.left / 2,
		y: rect.y + rect.height / 2
	}
}, 2));
/*14*/ConfigController.frameTypes.push(new GlassFrameType("Schieben nach Links", (rect: any, offset: any, innerOffset: any, isPreset: boolean) => {

	const arrowOffset = (!isPreset ? 20 : 5);
	const arrowOffset2 = (!isPreset ? 50 : 10);
	const arrowOffset3 = (!isPreset ? 100 : 15);

	return [
		/*ARROW*/
		rect.width / 2 + arrowOffset2, rect.height / 2 + arrowOffset,
		rect.width / 2 + arrowOffset2, rect.height / 2,
		rect.width / 2 - arrowOffset2, rect.height / 2,
		rect.width / 2 - arrowOffset2, rect.height / 2 - arrowOffset,
		rect.width / 2 - arrowOffset3, rect.height / 2,
		rect.width / 2 - arrowOffset2, rect.height / 2 + arrowOffset,
		rect.width / 2 - arrowOffset2, rect.height / 2,
	];
}, ( rect: any, offset: any ) => {
	//////console.log('Schieben nach Links config:',  rect, offset);
	return {
		x: rect.x + rect.width - offset.right / 2,
		y: rect.y + rect.height / 2
	}
}, 2));



