/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 28.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import Konva from "konva";
import {Designer} from "../designer";


export class MetricController {
    metrics: Konva.Rect[] = [];

    constructor(public designer: Designer) {}

    test() {
        if ( this.designer.stage === undefined ) return;
        if ( this.designer.layer === undefined ) return;


        let currentY = this.designer.layer.y();
        let currentX = 0;
        //let vertikal: boolean = true;
        while (true) {
            const object = this.designer.stage.getIntersection(
                {x: currentX, y: currentY }
            );

            //console.log('check metric:', currentY);

            if ( object !== null ) {
                if ( object.id() ) {
                    const window = this.designer.getWindow(object.id());
                    if ( window !== undefined ) {
                        //console.log('found window:', object.id(), window);
                        break;
                    } else {
                        currentY+=1;
                    }
                } else {
                    currentY+=1;
                }
            } else {
                currentY+=1;
            }

            if ( currentY >= 5000) break;
        }
    }

    create() {}


    update() {
        //console.log('[METRIC CONTROLLER] update called');

        for ( const mIndex in this.metrics ) {
            const metricObject = this.metrics[mIndex];
            if ( metricObject === undefined ) continue;

            metricObject.destroy();
            delete this.metrics[mIndex];
        }

        this.metrics = this.metrics.filter(
            ( metric ) => metric !== undefined
        );

        this.create();
    }
}

/*
export class Metric {

	lineBottom: Line | undefined;
	lineRight: Line | undefined;
	labelBottom: Line | undefined;
	labelRight: Line | undefined;

	window: WindowMain | undefined;

	offset: number = 60;
	glassOffset: number = 60;

	offsetAdd: number = 120;

	fontSize: number = 60;

	metricHolder: any[] = [];

	hideBottom: boolean = false;
	hideRight: boolean = false;

	labelCenterFactor: number = 0.65;
	labelCenterFactorY: number = 1.5;

	startOffset: number = 0;

	constructor(window: WindowMain) {
		this.window = window;
		this.create();

		////console.log('[MetricController] created');
	}

	create() {
		if ( this.window === undefined ) return;

		this.startOffset = this.window.options.outerFrameOffset.left + this.offsetAdd;

		this.hideRight = false;
		this.hideBottom = false;

		const glassSplitType = ( !this.window.options.splitCustom ? ConfigController.splitTypes[this.window.options.splitType] : this.window.options.splitCustomConfig);
		if ( glassSplitType === undefined ) return;

		let glassIndex = 0;
		let rowIndex = 0;
		let beforeRow = null;
		let beforeGlass = null;
		let glassAllHeight = [];

		for ( const row of glassSplitType.reihen ) {
			let rowOffset = 120;
			let index = 0;
			let rowHasTrenner = false;
			let trennerWidth = 0;

			for ( const column of row.spalten ) {
				if ( column.glass.options.isTrenner ) {
					rowHasTrenner = true;
					trennerWidth = column.glass.options.width;
					break;
				}
			}

			let isRowFirst = false;
			let isRowLast = false;

			if ( Number(rowIndex) === 0 ) isRowFirst = true;
			if ( Number(rowIndex) === (glassSplitType.reihen.length - 1) ) isRowLast = true;

			let glassAllWidth = [];


			for ( const column of row.spalten ) {
				const glass =  this.window.glass[glassIndex];
				const glassOPT = glass.options;
				const nextGlass =  row.spalten[(index+1)];
				let heightOffset = 0;
				let glassY = glass.rect.y();

				let isFirst = false;
				let isLast = false;

				if ( Number(index) === 0 ) isFirst = true;
				if ( Number(index) === (row.spalten.length - 1) ) isLast = true;

				if ( this.window.rect.height() === glass.rect.height() ) this.hideRight = true;
				if ( this.window.rect.width() === glass.rect.width() ) this.hideBottom = true;

				let glassWidthText = glass.rect.width();
				let glassHeightText = glass.rect.height();
				let glassX = glass.rect.x();
				let glassWidth = glass.rect.width();
				let glassHeight = glass.rect.height();
				let disableRight = false;
				let disableLeft = false;

				let createMetric = true;
				let createMetricRight = true;
				let createMetricBottom = true;

				/*if ( glassOPT.frameType === 4 && glassOPT.frameType === 5 ) {
					disableRight = true;
				}

                if ( beforeObject != null ) {
					const beforeGlassOPT = beforeObject.options;

                    if ( glassOPT.frameType === 5 && beforeGlassOPT.isTrenner ) {
                        glassWidthText += (beforeObject.rect.width() / 2) * 2;
                    }

                    if ( glassOPT.frameType === 5 && beforeGlassOPT.frameType === 4 ) {
                        if ( !rowHasTrenner ) glassWidthText -= 10;
                        if ( rowHasTrenner ) glassWidthText += (trennerWidth / 2) + 5;
                    }
					if ( glassOPT.frameType === 5 && beforeGlassOPT.frameType === 4 ) {
						glassWidth += 10;
						glassX += 10;
					}

                    if ( glass.frameType === 12 && beforeGlassOPT.frameType === 0 ) {
                        glassWidthText -= beforeGlassOPT.frameSize + beforeObject.windowInnerFrame.innerOffset;
                        //disableLeft = true;
                    }


					if ( beforeObject.isTrenner ) {
						glassWidth += (beforeObject.rect.width() / 2) / 2;
						glassX -= beforeObject.rect.width() / 2;
					}

					if ( beforeObject.rect.height() === glass.rect.height() ) {
						createMetricRight = false;
					}
                }

                if ( nextGlass != null ) {
                    if ( nextGlass.isTrenner ) {
                        glassWidth+= (nextGlass.rect.width() / 2);
                    }
                    if ( glass.isTrenner ) {
                        createMetric = false;
                    }


                    if ( glass.frameType === 0 && nextGlass.frameType === 12 ) {
                        disableRight = true;
                    }
                }

				// Todo: schreibe die Bedingungen neu

				if ( isLast ) {
					glassWidth += this.window.options.outerFrameOffset.left;
				}

				if ( isFirst ) {
					glassWidth += this.window.options.outerFrameOffset.left;
					glassX -= this.window.options.outerFrameOffset.left;
				}

				if ( isRowFirst ) {
					glassY -= this.window.options.outerFrameOffset.left;
					glassHeight += this.window.options.outerFrameOffset.left;
				}

				if ( isRowLast ) {
					glassHeight += this.window.options.outerFrameOffset.left;
				}

				// Besondere Bedingungen:
				if ( nextGlass != null ) { // wenn ein nächstes Glass existiert.
					////console.log('nextGlass', glassOPT.frameType, nextGlass.glass.options.frameType);

					if ( glassOPT.frameType === 6 && nextGlass.glass.options.frameType === 7 ) {
						glassWidth -= 10;
					}

					if ( nextGlass.glass.options.isTrenner ) {
						glassWidth += (nextGlass.glass.options.width / 2);
					}

					/*if ( nextGlass.glass.options.frameType !== 13 && glassOPT.frameType === 13 ) {
						glassWidth -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
					if ( nextGlass.glass.options.frameType === 14 && glassOPT.frameType !== 14 ) {
						glassWidth -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
					if ( nextGlass.glass.options.frameType === 14 && glassOPT.frameType === 13 ) {
						glassWidth -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
				}

				if ( beforeGlass != null ) { // wenn ein vorheriges Glass existiert.
					////console.log('beforeObject', beforeGlass.options.frameType, glassOPT.frameType);

					if ( beforeGlass.rect.height() === glass.rect.height() ) {
						createMetricRight = false;
					}
					if ( beforeGlass.options.frameType === 4 && glassOPT.frameType === 5 ) {
						glassWidthText -= 10;
					}

					if ( beforeGlass.options.isTrenner && nextGlass == null ) {
						glassX -= ( beforeGlass.options.width / 2);
						glassWidthText += ( beforeGlass.options.width );
						glassWidth += ( beforeGlass.options.width / 2);
					}
					if ( beforeGlass.options.isTrenner && nextGlass != null && nextGlass.glass.options.frameType === 5 ) {
						glassX -= ( beforeGlass.options.width / 2);
						glassWidth += ( beforeGlass.options.width / 2);
					}
					if ( beforeGlass.options.frameType === 6 && glassOPT.frameType === 7 ) {
						if ( rowHasTrenner ) glassWidthText += trennerWidth;
						if ( !rowHasTrenner ) glassWidthText -= 10;
					}

					/*if ( beforeGlass.options.frameType === 13 && glassOPT.frameType !== 13) {
						//glassX += glassOPT.frameOuterOffset;
						glassWidthText -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
					if ( beforeGlass.options.frameType === 13 && glassOPT.frameType === 14 ) {
						//glassX += glassOPT.frameOuterOffset;
						glassWidthText -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
					if ( beforeGlass.options.frameType !== 14 && glassOPT.frameType === 14 ) {
						glassWidthText -= glassOPT.frameOuterOffset.left + glassOPT.frameInnerOffset;
					}
				}

				if ( glass.options.isTrenner ) {
					createMetric = false;
				}

				//Todo: Prüfe ob mehrere anzeigen in einer ROW gleich sind
				// und prüfe ob mehrere anzeigen in columns gleich sind.
				// dann bitte einfach die metrics bottom / right auf false setzen.
				/*if ( glassAllWidth.includes(glassWidthText) ) {
					createMetricBottom = false;
				}

				if ( glassAllHeight.includes(glassHeightText) ) {
					createMetricRight = false;
				}


				if ( beforeRow != null ) {
					let beforeGlassIndex = 0;
					for ( const beforeRowColumn of beforeRow.spalten ) {
						let beforeRowGlass =  this.window.glass[beforeGlassIndex];
						if ( beforeRowGlass.rect.width() === glass.rect.width()) {
							createMetricBottom = false;
							break;
						}

						beforeGlassIndex ++;
					}
				}




				if ( createMetric ) {
					if ( createMetricRight ) {
						this.metricHolder.push(new MetricLine(this.window, glass, this,
							this.window.rect.x() + this.window.rect.width() + rowOffset,
							glassY,
							glassHeight,
							rowIndex, index,
							0,
							glassHeightText.toString(),
							90
						));
					}

					if ( createMetricBottom ) {
						// Bottom
						this.metricHolder.push(new MetricLine(this.window, glass, this,
							glassX,
							this.window.rect.y() + this.window.rect.height() + this.startOffset,
							glassWidth,
							rowIndex, index,
							1,
							glassWidthText.toString(),
							0,
							disableLeft,
							disableRight
						));
					}
					rowOffset+= this.offsetAdd;
				}

				glassAllWidth.push(glassWidthText);
				glassAllHeight.push(glassHeightText);
				beforeGlass = glass;
				glassIndex++
				index++;
			}

			beforeRow = row;
			beforeGlass = null;
			this.startOffset += this.offsetAdd;
			rowIndex+=1;
		}


		this.generateRight();
		this.generateBottom();
	}

	update() {
		this.destroy();
		for ( const rectMetric of this.metricHolder ) rectMetric.destroy();
		this.create();
	}

	createLabelAt(x, y, text = "---", rotation = 0) {
		let textStr = `${text} mm`;

		let offsetX = ( rotation !== 0 ? (this.fontSize * this.labelCenterFactor) : -(this.fontSize / this.labelCenterFactorY));
		let offsetY = ( rotation === 0 ? (this.fontSize * this.labelCenterFactor) : (this.fontSize / this.labelCenterFactorY));


		let label = new Konva.Label({
			x: x + offsetX,
			y: y - offsetY,
			rotation: rotation
		});

		label.add(new Konva.Tag({
			fill: Colors.metricFill,
			stroke: Colors.metricStroke,
			strokeWidth: 1,
			shadowColor: Colors.metricStroke,
			shadowBlur: 5,
			shadowOpacity: 0.2,
			lineJoin: 'round',
			cornerRadius: 2
		}));

		label.add(new Konva.Text({
			text: textStr,
			fontSize: this.fontSize,
			lineHeight: 1.2,
			padding: 4,
			fill: Colors.metricText
		}));

		return label;
	}

	generateBottom() {
		if ( this.hideBottom ) return;

		const text = `${this.window.rect.width()}`;

		this.lineBottom = new Konva.Line({
			x: this.window.rect.x() - this.window.options.outerFrameOffset.left,
			y: this.window.rect.y() + this.window.rect.height() + (this.startOffset + this.offset),
			points: this.generateLine(1),
			strokeWidth: 3,
			stroke: Colors.metricStroke,
			fill: Colors.metricFill
		});

		this.window.addGroup(this.lineBottom);

		this.labelBottom = this.createLabelAt(
			this.window.rect.x() + this.window.rect.width() / 2 - ( text.length * 2),
			this.window.rect.y() + this.window.rect.height() + (this.startOffset + this.offset),
			text
		);

		this.window.addGroup(this.labelBottom);
	}

	generateRight() {
		if ( this.hideRight ) return;

		if ( this.window.parent != null ) {
			if ( this.window.parent.metric != null ) this.window.parent.metric.lineRight.destroy();
		}

		const text = `${this.window.rect.height()}`;

		this.lineRight = new Konva.Line({
			x: this.window.rect.x() + this.window.rect.width() + (this.startOffset + this.offset),
			y: this.window.rect.y() + this.window.options.outerFrameOffset.left,
			points: this.generateLine(),
			strokeWidth: 3,
			stroke: Colors.metricStroke,
			fill: Colors.metricStroke
		});

		this.labelRight = this.createLabelAt(
			this.window.rect.x() + this.window.rect.width() + (this.startOffset + this.offset),
			this.window.rect.y() + (this.window.rect.height() / 2) - (text.length * 2),
			text, 90
		);

		this.window.addGroup(this.lineRight);
		this.window.addGroup(this.labelRight);
	}

	generateLine(type = 0) {
		if ( type === 0 ) { // right
			const height = (this.window.rect.height() + (this.window.options.outerFrameOffset.left * 2));
			return [
				-20, 0,
				0, 0,
				0, height,
				-20, height,
			];
		} else if ( type === 1 ) { // bottom
			const width = ( this.window.rect.width() + (this.window.options.outerFrameOffset.left * 2));
			return [
				0, -20,
				0, 0,
				width, 0,
				width, -20,
			];
		}
	}

	destroy() {
		if ( this.lineBottom != null ) this.lineBottom.destroy();
		if ( this.labelBottom != null ) this.labelBottom.destroy();

		if ( this.lineRight != null ) this.lineRight.destroy();
		if ( this.labelRight != null ) this.labelRight.destroy();
	}

	add(object) {
		this.window.addGroup(object);
	}
}
**/