/*******************************************************************************
 * Author: Florian Schmidt <florian.schmidt@mimann.net>
 * Last Modified: 21.9.2022
 *
 *
 * Copyright: MiMann.net
 ******************************************************************************/

import React, {Component} from "react";
import {Utils} from "../../utils/utils";
import {API} from "../../core/API";
import {Button, Col, Form, Input, message, Row} from 'antd';


import {useNavigate} from "react-router-dom";
import axios from "axios";

export function PageLogin() {
	const navigate = useNavigate();
	return <LoginPageComponent navigation={navigate} />
}

interface MyState {
	password: string;
	show: boolean;
	tryLogin: boolean;
}

interface Props {
	navigation: any;
}

class LoginPageComponent extends Component<Props, MyState> {

	constructor(props: Props) {
		super(props);
		this.state = {
			password: '',
			show: false,
			tryLogin: false
		}
	}

	async componentDidMount() {
		const state = await API.checkLoginState();
		if ( state ) return this.props.navigation("/");
	};

	async handleSubmit( values: any ) {
		if ( this.state.tryLogin ) return;
		this.setState({ tryLogin: true });

		if ( values.password.length === 0 ) return message.error('Passwort Eingeben!');

		try {
			const api = await axios.post(API.URL + '/users/login', {
				...values
			});

			if ( api.data.errors ) {
				this.setState({ tryLogin: false });
				return message.error(api.data.message ? api.data.message : 'Unbekannter Fehler!');
			}

			localStorage.setItem("token", api.data.data.token.token);
			this.props.navigation("/projects");
		} catch ( error ) {
			message.error(error as string);
			this.setState({ tryLogin: false });
		}
	}

	render() {
		return <div className="container-center w-centered">
			<h2>Fenster-Designer</h2>
			<div className="sign-up">
				<div className="text-semibold">Melden Sie sich mit Ihrem Konto</div>
				<div className="text-semibold">Bequem und Sicher an.</div>
			</div>

			<Form
				onFinish={this.handleSubmit.bind(this)}
				layout="vertical"
				initialValues={{
					email: 'test@streif-fensterbau.de'
				}}
			>
				<Form.Item name="email" rules={[{ required: true, message: 'Bitte Passwort eingeben' }]}>
					<Input type={"email"} className={"input-login"} autoComplete={"email"} />
				</Form.Item>
				<Form.Item name="password" rules={[{ required: true, message: 'Bitte Passwort eingeben' }]}>
					<Input.Password className={"input-login"} autoComplete={"password"} />
				</Form.Item>
				<Form.Item>
					<Button className="btn-login" htmlType="submit" type="primary" block={true} loading={this.state.tryLogin}>
						Anmelden
					</Button>
				</Form.Item>
			</Form>
		</div>;
	}
}